var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disable-search": _vm.disableSearch,
      "entity-type": _vm.entityType,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.computedPlaceholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.entityType,
      "entity-type": _vm.entityType,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.entityType,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "disabled": _vm.isFilterLocked('itemDrawingMasterSearchScope'),
      "display": _vm.SearchItemDrawingItemMasterScopeDisplay,
      "enum": _vm.SearchItemDrawingItemMasterScope,
      "label": "Search Scope",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.searchScope,
      callback: function callback($$v) {
        _vm.$set(_vm.searchFilters, "searchScope", $$v);
      },
      expression: "searchFilters.searchScope"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.ITEM_DRAWING,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.entityType,
      "headers": _vm.tableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.defaultHardwareTablePagination,
      "selectable-rows": "",
      "show-count": "",
      "title": "Hardware results"
    },
    on: {
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };