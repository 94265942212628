import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { RiskHardwareTypeDisplay, RiskReportabilityDisplay } from '~/nasa_ui/types/enums/risks';
import { MitigationTeamDisplay, RiskCategoryDisplay, RiskClosureTypeDisplay } from '~/nasa_ui/types/models/risk';
import { getRiskColor } from '~/nasa_ui/utils/getRiskColor';
import { addDateDisplays, addUserDisplays } from '../displayMappers';
import { markdownToHtml } from '../markdownToHtml';
import { sortObjectNumericallyBy } from '../sortObjectNumericallyBy';
import { transformContactResponses, transformOrganizationResponses } from '../transformEntityToTableFormat';
export const transformRisks = (risks) => {
    return risks
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map((risk) => {
        const _item = { ...risk };
        // Current
        _item._currentRiskColor = getRiskColor(risk.likelihood || 1, risk.consequence || 1);
        _item._currentCriticality =
            _item._currentRiskColor === 'red' ? 'HIGH' : _item._currentRiskColor === 'yellow' ? 'MODERATE' : 'LOW';
        // Initial
        _item._initialRiskColor = getRiskColor(risk.initialLikelihood || 1, risk.initialConsequence || 1);
        _item._initialCriticality =
            _item._initialRiskColor === 'red' ? 'HIGH' : _item._initialRiskColor === 'yellow' ? 'MODERATE' : 'LOW';
        _item._category = risk.category ? RiskCategoryDisplay.get(risk.category) : DEFAULT_DASH;
        _item._cause = markdownToHtml(risk.cause);
        _item._closureType = risk.closureType ? RiskClosureTypeDisplay.get(risk.closureType) : DEFAULT_DASH;
        _item._effect = markdownToHtml(risk.effect);
        _item._hardwareType = risk.hardwareType
            ? RiskHardwareTypeDisplay.get(risk.hardwareType)
            : DEFAULT_DASH;
        _item._mostRecentOrbNoteComment = markdownToHtml(risk.mostRecentOrbNoteComment?.comment);
        _item._organization = risk.organization ? transformOrganizationResponses([risk.organization])[0] : null;
        _item._owner = risk.ownerContact ? transformContactResponses([risk.ownerContact])[0] : null;
        _item._title = risk.title || DEFAULT_DASH;
        _item._projectedConsequence = _item.finalMitigationProjectedConsequence || _item.consequence;
        _item._projectedLikelihood = _item.finalMitigationProjectedLikelihood || _item.likelihood;
        _item._projectedRiskScore = _item.finalMitigationProjectedScore || _item.score;
        _item._projectedRiskColor = getRiskColor(_item._projectedLikelihood || 1, _item._projectedConsequence || 1);
        _item._projectedCriticality =
            _item._projectedRiskColor === 'red' ? 'HIGH' : _item._projectedRiskColor === 'yellow' ? 'MODERATE' : 'LOW';
        _item._reportability = RiskReportabilityDisplay.get(_item.showOnReports);
        _item._riskMitigations = risk.riskMitigations ? transformRiskMitigations(risk.riskMitigations) : [];
        // status is used to show if the risk is open or the closure type
        _item._status = risk.isClosed ? RiskClosureTypeDisplay.get(risk.closureType) : 'Open';
        // Get the highest sequence mitigation step
        const highestSequencedMitigationStep = risk.riskMitigations
            ? risk.riskMitigations.sort(sortObjectNumericallyBy('sequence'))[risk.riskMitigations.length - 1]
            : null;
        if (highestSequencedMitigationStep) {
            const lastMitigationStepProjectedCloseDates = addDateDisplays({
                lastMitigationStepProjectedCloseDate: highestSequencedMitigationStep?.projectedCloseDate
            });
            Object.keys(lastMitigationStepProjectedCloseDates).forEach((key) => {
                _item[key] = lastMitigationStepProjectedCloseDates[key];
            });
        }
        return _item;
    });
};
export const transformRiskMitigations = (mits) => {
    return mits
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map((mit) => {
        const _item = { ...mit };
        _item._description = mit.description ? markdownToHtml(mit.description) : DEFAULT_DASH;
        _item._exitCriteria = mit.exitCriteria ? markdownToHtml(mit.exitCriteria) : DEFAULT_DASH;
        _item._impact = mit.impact ? markdownToHtml(mit.impact) : DEFAULT_DASH;
        _item._projectedRiskScore = (mit.projectedLikelihood || 1) * (mit.projectedConsequence || 1);
        _item._sequence = mit.sequence;
        _item._status = mit.isCompleted ? 'Completed' : 'In work';
        _item._team = mit.team ? MitigationTeamDisplay.get(mit.team) : DEFAULT_DASH;
        return _item;
    });
};
export const transformRiskMitigationResponses = (items) => {
    return transformRiskMitigations(items);
};
export const transformRiskResponses = (items) => {
    return transformRisks(items);
};
export const transformRiskResponseWithRefs = (items) => {
    return transformRisks(items);
};
export const transformRiskReportResponses = (items) => {
    return transformRisks(items);
};
