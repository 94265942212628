var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-button', _vm._g(_vm._b({
    attrs: {
      "color": _vm.computedColor,
      "disabled": _vm.computedDisabled,
      "flat": _vm.$attrs.flat,
      "href": _vm.href,
      "icon": _vm.icon,
      "loading": _vm.showLoader,
      "loading-text": _vm.loadingText,
      "small": _vm.small,
      "text-color": _vm.computedTextColor
    },
    on: {
      "c-click": _vm.onClick
    }
  }, 'c-button', _vm.$attrs, false), _vm.$listeners), [_vm.$slots.icon ? _c('div', {
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  }, [_vm._t("icon")], 2) : _vm._e(), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };