var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.to ? _c('router-link', _vm._g(_vm._b({
    staticClass: "mini_actions_v2_button",
    class: {
      disabled: _vm.disabled
    },
    style: {
      width: _vm.computedIconSize
    },
    attrs: {
      "disabled": _vm.disabled,
      "href": _vm.to,
      "flat": ""
    }
  }, 'router-link', _vm.propsWithoutIcon, false), _vm.$listeners), [_vm._t("default", function () {
    return [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.icon,
        "icon-color": _vm.iconColor,
        "icon-size": _vm.computedIconSize,
        "flip-horizontal": _vm.iconFlipHorizontal,
        "flip-vertical": _vm.iconFlipVertical
      }
    }), _vm.label ? _c('span', {
      staticClass: "sr-only"
    }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()];
  })], 2) : _vm.href ? _c('a', _vm._g(_vm._b({
    staticClass: "mini_actions_v2_button",
    class: {
      disabled: _vm.disabled
    },
    style: {
      width: _vm.computedIconSize
    },
    attrs: {
      "disabled": _vm.disabled
    }
  }, 'a', _vm.$props, false), _vm.$listeners), [_vm._t("default", function () {
    return [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.icon,
        "icon-color": _vm.iconColor,
        "icon-size": _vm.computedIconSize,
        "flip-horizontal": _vm.iconFlipHorizontal,
        "flip-vertical": _vm.iconFlipVertical
      }
    }), _vm.label ? _c('span', {
      staticClass: "sr-only"
    }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()];
  })], 2) : _c('button', _vm._g(_vm._b({
    staticClass: "mini_actions_v2_button",
    class: {
      disabled: _vm.disabled
    },
    style: {
      width: _vm.computedIconSize
    },
    attrs: {
      "disabled": _vm.disabled,
      "type": "button"
    }
  }, 'button', _vm.$props, false), _vm.$listeners), [_vm._t("default", function () {
    return [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.icon,
        "icon-color": _vm.iconColor,
        "icon-size": _vm.computedIconSize,
        "flip-horizontal": _vm.iconFlipHorizontal,
        "flip-vertical": _vm.iconFlipVertical
      }
    }), _vm.label ? _c('span', {
      staticClass: "sr-only"
    }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };