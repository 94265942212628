var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
let ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange = class ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange extends Vue {
    isFetching = false;
    changeSet;
    get changeLog() {
        return this.changeSet?.changeLogs?.nodes[0] || null;
    }
    get hasLotNumber() {
        return this.payload && 'lotNumber' in this.payload;
    }
    get hasSerialNumber() {
        return this.payload && 'serialNumber' in this.payload;
    }
    get lotNumber() {
        if (!this.payload?.lotNumber) {
            return null;
        }
        return this.payload.lotNumber;
    }
    get lotNumberOld() {
        return this.changeLog?.changeDetails.changedFrom.lotNumber
            ? this.changeLog?.changeDetails.changedFrom.lotNumber
            : DEFAULT_DASH;
    }
    get serialNumber() {
        if (!this.payload?.serialNumber) {
            return null;
        }
        return this.payload.serialNumber;
    }
    get serialNumberOld() {
        return this.changeLog?.changeDetails.changedFrom.serialNumber
            ? this.changeLog?.changeDetails.changedFrom.serialNumber
            : DEFAULT_DASH;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange);
export default ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange;
