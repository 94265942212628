var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--wrapper"
  }, [_vm.hardwareData && !_vm.isLoading ? _c('div', [_c('div', {
    staticClass: "ahd--grid-container item_drawing_display_grid"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._drawingNumberDisplay, _vm.drawingNumberHref),
      "label": "Drawing num:"
    }
  }), !_vm.showAllSizes ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._itemDrawingSizes,
      "label": "Sizes:"
    }
  }) : _vm._e(), _vm.showAllSizes ? _c('div', {
    staticClass: "ahd--grid-item all_drawing_sizes"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Sizes:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm._itemDrawingSizes) + " ")])]) : _vm._e(), _vm.sizes.length > 4 ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }), _c('strong', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showAllSizes = !_vm.showAllSizes;
      }
    }
  }, [!_vm.showAllSizes ? _c('span', [_vm._v("Show " + _vm._s(_vm.moreSizesCount) + " More Sizes")]) : _c('span', [_vm._v("Show Fewer Sizes")])])])]) : _vm._e(), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref('View Changelogs', _vm._changelogHref),
      "label": "History:"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-two"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._eeeIdentifier,
      "tooltipText": _vm.hardwareData.eeeIdentifier === true ? 'Has identifier' : 'No identifier',
      "label": "EEE Identifier:",
      "tooltipLabel": "EEE Identifier"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._pointOfContact,
      "label": "PoC:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._meanTimeBetweenFailure,
      "tooltipText": _vm._meanTimeBetweenFailure,
      "label": "MTBF:",
      "tooltipLabel": "Mean time between Failure"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._meanTimeToRepair,
      "tooltipText": _vm._meanTimeToRepair,
      "label": "MTTR:",
      "tooltipLabel": "Mean time to repair"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-five"
  }, [_vm._initializationStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._initializationStatus ? 'Initialized' : 'Not initialized',
      "label": "Initialization:",
      "tooltipLabel": "Initialization status"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function fn() {
        return [_c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._initializationStatus,
            callback: function callback($$v) {
              _vm._initializationStatus = $$v;
            },
            expression: "_initializationStatus"
          }
        })];
      },
      proxy: true
    }], null, false, 2967205490)
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ahd--column-six"
  }, [_c('AHDQuantityColumn', {
    attrs: {
      "available-quantity": _vm._availableQuantity,
      "available-quantity-link": _vm.quantityHref,
      "installed-quantity": _vm._installedQuantity,
      "installed-quantity-link": _vm.quantityHref,
      "inventory-quantity": _vm._inventoryQuantity,
      "inventory-quantity-link": _vm.inventoryQuantityHref
    }
  })], 1)]), _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Description:")]), _c('a', {
    staticClass: "font-weight-bold",
    attrs: {
      "href": _vm.drawingNumberHref,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm._description))])]), _c('div', {
    staticClass: "ahd--button-wrapper"
  }, [_vm.shouldShowExternalLinksButton ? _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "title": "View Links"
          }
        }, on), [_c('i', {
          staticClass: "pt-2",
          class: _vm.isViewLinksMenuOpen ? 'fa-rotate-90 fal fa-angle-left ' : 'fa-rotate-90 fal fa-angle-right ',
          staticStyle: {
            "font-size": "16px"
          }
        }), _c('c-icon-fa', {
          staticClass: "pr-2 pl-2",
          attrs: {
            "icon": _vm.$icons['link']
          }
        }), _vm._v(" View Links ")], 1)];
      }
    }], null, false, 4186072215),
    model: {
      value: _vm.isViewLinksMenuOpen,
      callback: function callback($$v) {
        _vm.isViewLinksMenuOpen = $$v;
      },
      expression: "isViewLinksMenuOpen"
    }
  }, [_c('v-list', {
    attrs: {
      "dark": ""
    }
  }, _vm._l(_vm.linksFormatted, function (link, index) {
    return _c('v-list-tile', {
      key: index,
      attrs: {
        "href": link.url,
        "title": link.url
      }
    }, [_c('v-list-tile-title', [_c('c-icon-fa', {
      staticClass: "pr-2",
      attrs: {
        "icon-size": "16px",
        "icon": _vm.$icons.link
      }
    }), _c('span', [_vm._v(_vm._s(link.name))])], 1)], 1);
  }), 1)], 1) : _vm._e()], 1), _vm.isArchived ? _c('div', {
    staticClass: "ahd--solo-row mt-2"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('strong', {
    staticClass: "error--text lighten-2"
  }, [_vm._v("Archived")])])], 1) : _vm._e()]) : _vm._e(), _vm.isLoading ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.ITEM_DRAWING
    }
  }) : _vm._e(), !_vm.isLoading && !_vm.hardwareData ? _c('Alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" Could not load data for this piece of Hardware. ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };