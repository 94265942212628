var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.showModalHardwareListEditTemplate && _vm.localEntity ? _c('ModalHardwareListTemplateEdit', {
    attrs: {
      "hardware-list-template-id": _vm.localEntity.id
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.showModalHardwareListEditTemplate = false;
      },
      "refresh-self": _vm.onRefreshSelf
    }
  }) : _vm._e(), _vm.showModalCreateHardwareListTemplate && _vm.localEntity ? _c('ModalHardwareListTemplateItemCreate', {
    key: _vm.localEntity.id,
    attrs: {
      "parent-hardware-list-template-id": _vm.localEntity.id,
      "transformed-hardware-items": _vm.transformedHardwareItems
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.showModalCreateHardwareListTemplate = false;
      },
      "refresh-self": _vm.onRefreshSelf
    }
  }) : _vm._e(), _vm.hasComputedTitleOrSlot ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.computedTitle))];
  })], 2) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini isRoot",
    class: {
      hasSlots: _vm.hasSlots,
      showingSlots: _vm.shouldShowSlotContainer,
      hideChips: _vm.$vuetify.breakpoint.smAndDown,
      'no-entity-icon': !_vm.showEntityTypeIcon
    }
  }, [_c('div', {
    staticClass: "mini_chips"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.root,
      "icon-color": "var(--v-hardware_list_templates-base)"
    }
  })], 1), _vm.showEntityTypeIcon ? _c('c-icon-fa', {
    attrs: {
      "icon": _vm.iconClass,
      "icon-color": "var(--v-hardware_list_templates-darken2)"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])]), _c('div', {
    staticClass: "mini_chips hidden-sm-and-down"
  }, [_c('v-chip', {
    staticClass: "mini_chip",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Type:")]), _c('span', [_c('strong', [_vm._v(_vm._s(_vm.subTypeDisplay))])])]), _c('v-chip', {
    staticClass: "mini_chip mini_chip__container_hack_for_vuetify_v_chip_hover_state",
    attrs: {
      "disabled": !_vm.hasSlots && !_vm.showSlots
    },
    on: {
      "click": _vm.onClickOfShowSlots
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Items:")]), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.mySlots.length))])])])], 1), _c('div', {
    staticClass: "mini_actions_v2"
  }, [_vm.hasSlots && _vm.showSlots ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
          class: {
            muted: !_vm.hasSlots && _vm.showSlots
          },
          attrs: {
            "icon-color": "var(--v-hardware_list_templates-base)",
            "icon": _vm.chevronIcon
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onClickOfShowSlots.apply(null, arguments);
            }
          }
        })], 1)];
      }
    }], null, false, 535270683)
  }, [_c('span', [_vm._v("Show/Hide Slots")])]) : _vm._e(), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
          attrs: {
            "icon-color": "var(--v-hardware_list_templates-base)",
            "icon": _vm.$icons['eye']
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onClickOfShowDetail.apply(null, arguments);
            }
          }
        })], 1)];
      }
    }], null, false, 528471887)
  }, [_c('span', [_vm._v("Show/Hide Detail")])]), _vm.allowEdit ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionEdit', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onClickOfEdit.apply(null, arguments);
            }
          }
        })], 1)];
      }
    }], null, false, 3773657290)
  }, [_c('span', [_vm._v("Edit Hardware List Template")])]) : _vm._e(), _vm.allowEdit ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionCreate', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onClickOfAddItemToList.apply(null, arguments);
            }
          }
        })], 1)];
      }
    }], null, false, 669647107)
  }, [_c('span', [_vm._v("Add Item to List")])]) : _vm._e(), _vm.inlineClear ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
          attrs: {
            "icon": _vm.$icons.close
          },
          on: {
            "click": _vm.onClickOfInlineClear
          }
        })], 1)];
      }
    }], null, false, 222040566)
  }, [_c('span', [_vm._v("Clear Hardware List Template")])]) : _vm._e()], 1)], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "center": "",
      "type": "linear"
    }
  }) : _vm._e()], 1), _c('v-fade-transition', [_vm.showDetail ? _c('AppFormWrapper', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "top": "0",
      "right": "1",
      "left": "1",
      "bottom": "1"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_vm.shouldShowManageHLTButton ? _c('ButtonLink', {
    staticClass: "ml-0",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "href": _vm.manageLink,
      "dark": "",
      "icon": _vm.$icons.part_tool
    }
  }, [_vm._v(" Manage Hardware List Template ")]) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Name"
    }
  }, [_vm._v(_vm._s(_vm.name))])], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Type"
    }
  }, [_vm._v(_vm._s(_vm.subTypeDisplay))])], 1)], 1)], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowSlotContainer ? _c('div', {
    staticClass: "template_mini_slot_container"
  }, _vm._l(_vm.mySlots, function (childHardwareListTemplateSlot) {
    return _c('MiniHardwareListTemplateSlot', {
      key: childHardwareListTemplateSlot.id,
      staticClass: "ml-4 hardware_list_template_mini_slot",
      attrs: {
        "allow-edit": _vm.allowEdit,
        "id": childHardwareListTemplateSlot.id
      }
    });
  }), 1) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };