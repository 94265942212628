var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('AppTableActions', {
    attrs: {
      "headers": _vm.computedTableHeaders,
      "items": _vm.items,
      "loading": _vm.loading,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "userDefinedPagination": {
        rowsPerPage: 50
      },
      "color": "movement",
      "icon": "fad fa-shipping-fast fa-flip-horizontal",
      "item-key": "index",
      "show-search-input": "",
      "sticky": "",
      "title": "Inventory due back"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ButtonMiniAction', {
          attrs: {
            "icon": _vm.$icons.movement,
            "to": _vm.buildLink(item),
            "tooltip": "Return Hardware from ".concat(item.currentLocationOrganizationName),
            "icon-color": "var(--v-movement-base)",
            "icon-flip-horizontal": "",
            "tooltip-position": "left"
          }
        }), _c('ButtonMiniActionEdit', {
          on: {
            "click": function click($event) {
              return _vm.updateDueBackDate(item);
            }
          }
        })];
      }
    }])
  }), _vm.shouldShowDueBackModal ? _c('ModalInventoryDueBack', {
    attrs: {
      "is-archived": _vm.isArchived,
      "id": _vm.selectedRowId,
      "date": _vm.expectedReturnDate,
      "org-code": _vm.expectedReturnOrganizationCode
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.shouldShowDueBackModal = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };