var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ChangeSetType, Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { LimitedLifeCycleField, LimitedLifeCycleFieldDisplay, LimitedLifeIntervalField, LimitedLifeIntervalFieldDisplay } from '~/nasa_ui/types/enums/hardware';
let ChangeSetPayloadDisplayLimitedLifeChange = class ChangeSetPayloadDisplayLimitedLifeChange extends Vue {
    isFetching = false;
    changeSet;
    get description() {
        return this.payload?.limitedLife.description || null;
    }
    get isItemMasterChange() {
        return this.changeSet?.subType === ChangeSetType.ITEM_MASTER_LIMITED_LIFE_CHANGE;
    }
    get limitedLifeCodes() {
        if (!this.payload?.limitedLife.code) {
            return DEFAULT_DASH;
        }
        return this.payload.limitedLife.code.join(', ');
    }
    get limitedLifeCycles() {
        if (!this.payload?.limitedLife) {
            return [];
        }
        // build up a shape we can loop through to display values
        return Object.values(LimitedLifeCycleField).map((field) => {
            const heading = LimitedLifeCycleFieldDisplay.get(field);
            const value = this.payload?.limitedLife[field]?.value || null;
            const service = this.payload?.limitedLife[field]?.service || null;
            const valueLabel = this.isItemMasterChange ? 'Default' : 'Item Instance quota';
            const serviceLabel = this.isItemMasterChange ? 'Service Default' : 'Item Instance service quota';
            return {
                heading,
                value,
                valueLabel,
                service,
                serviceLabel
            };
        });
    }
    get limitedLifeIntervals() {
        if (!this.payload?.limitedLife) {
            return [];
        }
        // build up a shape we can loop through to display values
        return Object.values(LimitedLifeIntervalField).map((field) => {
            const fieldStr = field || ''.toLowerCase();
            const heading = field ? LimitedLifeIntervalFieldDisplay.get(field) : '';
            let value = null;
            let service = null;
            const valueLabel = this.isItemMasterChange ? 'Default' : 'Item Instance quota';
            const serviceLabel = this.isItemMasterChange ? 'Service default' : 'Item Instance service quota';
            const limitedLifeValue = this.payload?.limitedLife[fieldStr]?.value;
            if (limitedLifeValue) {
                const entry = Object.entries(limitedLifeValue)[0];
                value = `${entry[1]} ${entry[0]}`;
            }
            const limitedLifeService = this.payload?.limitedLife[fieldStr]?.service;
            if (limitedLifeService) {
                const entry = Object.entries(limitedLifeService)[0];
                service = `${entry[1]} ${entry[0]}`;
            }
            return {
                heading,
                value,
                valueLabel,
                service,
                serviceLabel
            };
        });
    }
    get doLimitedLifeIntervalsContainValues() {
        return this.limitedLifeIntervals.some((limitedLifeInterval) => limitedLifeInterval.value);
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayLimitedLifeChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayLimitedLifeChange = __decorate([
    Component
], ChangeSetPayloadDisplayLimitedLifeChange);
export default ChangeSetPayloadDisplayLimitedLifeChange;
