var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
let ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange = class ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange extends Vue {
    isFetching = false;
    changeSet;
    get projectCode() {
        return this.changeSet?.payload?.projectCode || null;
    }
    get projectCodeOld() {
        return this.changeSet?.changeLogs?.nodes
            ? this.changeSet.changeLogs.nodes[0]?.changeDetails.changedFrom.projectCode
            : DEFAULT_DASH;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange);
export default ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange;
