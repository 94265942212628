var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { CosmicLimitedLifeQuotaFragment as CosmicLimitedLifeQuotaFragmentOverride } from '~/nasa_ui/DAL/limitedLife';
import { LimitedLifeCycleField, LimitedLifeCycleFieldDisplay, LimitedLifeIntervalField, LimitedLifeTypeDisplay, LimitedLifeTypeToPropertyName } from '~/nasa_ui/types/enums/hardware';
import { reduceIntervalToLargestMeasurement } from '~/nasa_ui/utils/dates/intervals';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
let ChangeSetDisplayItemMasterLimitedLifeChange = class ChangeSetDisplayItemMasterLimitedLifeChange extends Vue {
    isFetching = false;
    currentLimitedLife = null;
    LimitedLifeCycleField = LimitedLifeCycleField;
    LimitedLifeIntervalField = LimitedLifeIntervalField;
    payloadLimitedLife = null;
    selectedEntity = null;
    id;
    get cycleFields() {
        return Object.values(LimitedLifeCycleField).map((val) => LimitedLifeTypeToPropertyName.get(val));
    }
    get intervalFields() {
        return Object.values(LimitedLifeIntervalField).map((value) => LimitedLifeTypeToPropertyName.get(value));
    }
    get modifiedValues() {
        try {
            if (!this.currentLimitedLife || !this.payloadLimitedLife) {
                return null;
            }
            const currentLimitedLife = this.normalizeLimitedLifeObject(this.currentLimitedLife);
            const payloadLimitedLife = this.normalizeLimitedLifeObject(this.payloadLimitedLife);
            const modifiedLimitedLife = {};
            // build up modified life cycle values
            Object.values(LimitedLifeCycleField).forEach((value) => {
                const key = LimitedLifeTypeToPropertyName.get(value) || '';
                const currentLl = currentLimitedLife[key];
                const payloadLl = payloadLimitedLife[key];
                const modifiedLl = {};
                /* This is to shut up TS. I’m 90% sure that `normalizeLimitedLifeObject()` prevents
                these from being null */
                if (!currentLl || !payloadLl) {
                    return;
                }
                if (currentLl === payloadLl) {
                    return;
                }
                if (currentLl.value !== payloadLl.value) {
                    modifiedLl.value = payloadLl.value ? payloadLl.value : null;
                }
                if (currentLl.service !== payloadLl.service) {
                    modifiedLl.service = payloadLl.service ? payloadLl.service : null;
                }
                modifiedLimitedLife[key] = modifiedLl;
            });
            // build up modified life interval values
            Object.values(LimitedLifeIntervalField).forEach((value) => {
                const key = LimitedLifeTypeToPropertyName.get(value) || '';
                const currentLl = currentLimitedLife[key];
                const payloadLl = payloadLimitedLife[key];
                const modifiedLl = {};
                /* This is to shut up TS. I’m 90% sure that `normalizeLimitedLifeObject()` prevents
                these from being null */
                if (!currentLl || !payloadLl) {
                    return;
                }
                if (currentLl === payloadLl) {
                    return;
                }
                if (!this.areIntervalsEqual(currentLl.value, payloadLl.value)) {
                    modifiedLl.value = payloadLl.value;
                }
                if (!this.areIntervalsEqual(currentLl.service, payloadLl.service)) {
                    modifiedLl.service = payloadLl.service;
                }
                modifiedLimitedLife[key] = modifiedLl;
            });
            if (payloadLimitedLife.code &&
                JSON.stringify(currentLimitedLife.code) !== JSON.stringify(payloadLimitedLife.code)) {
                modifiedLimitedLife.code = [...payloadLimitedLife.code];
            }
            if (currentLimitedLife.description !== payloadLimitedLife.description) {
                modifiedLimitedLife.description = payloadLimitedLife.description;
            }
            return modifiedLimitedLife;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    get previousValues() {
        if (!this.modifiedValues) {
            return null;
        }
        const previousValues = {};
        // build up previous values for fields that have changed
        Object.keys(this.modifiedValues).forEach((key) => {
            if (!this.currentLimitedLife || !this.modifiedValues) {
                return;
            }
            if (key === 'code') {
                previousValues[key] = this.currentLimitedLife[key];
                return;
            }
            if (key === 'description') {
                previousValues[key] = this.currentLimitedLife[key];
                return;
            }
            if ('value' in this.modifiedValues[key]) {
                previousValues[key] = { value: this.currentLimitedLife[key].value };
            }
            if ('service' in this.modifiedValues[key]) {
                previousValues[key] = previousValues[key] ? previousValues[key] : { service: null };
                previousValues[key].service = this.currentLimitedLife[key].service;
            }
        });
        return previousValues;
    }
    areIntervalsEqual(a, b) {
        if (a === null && b === null) {
            return true;
        }
        if ((!a && b) || (a && !b)) {
            return false;
        }
        const partialA = { ...a };
        const partialB = { ...b };
        for (const unit in partialA) {
            if (unit in partialB && partialA[unit] === partialB[unit]) {
                delete partialA[unit];
                delete partialB[unit];
            }
            if (unit in partialA && !(unit in partialB)) {
                delete partialA[unit];
            }
            if (!(unit in partialA) && unit in partialB) {
                delete partialB[unit];
            }
        }
        return JSON.stringify(partialA) === JSON.stringify(partialB);
    }
    capitalizeWord(word) {
        return word.substring(0, 1).toUpperCase() + word.substring(1);
    }
    codeDisplay(code) {
        if (!code || code.length === 0) {
            return null;
        }
        return code.join(', ');
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicLimitedLifeQuotaFragmentOverride}

          query SelectChangeSetForItemMasterLimitedLifeChange($id: Int!) {
            changeSetById(id: $id) {
              id
              itemMaster {
                limitedLife {
                  ...CosmicLimitedLifeQuota
                }
                nodeId
              }
              nodeId
              payload
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isFetching = false;
        }
    }
    getLabelForCurrentValue(field, key) {
        if (!this.previousValues) {
            return null;
        }
        if (!(field in this.previousValues)) {
            return;
        }
        let displayName = '';
        if (LimitedLifeTypeDisplay.has(field)) {
            displayName = LimitedLifeTypeDisplay.get(field) || '';
        }
        else if (LimitedLifeCycleFieldDisplay.has(field)) {
            displayName = LimitedLifeCycleFieldDisplay.get(field) || '';
        }
        else {
            displayName = this.capitalizeWord(field);
        }
        key = this.capitalizeWord(key);
        return `${displayName} ${key}`;
    }
    getValue(values, field, key) {
        if (!values || !values[field]) {
            return null;
        }
        let value = values[field]; // Object is possibly null/undefined !? idk how but...
        if (!value) {
            return null;
        }
        if (field === 'code' && Array.isArray(value)) {
            return value.join(', ');
        }
        value = value[key];
        if (value === null) {
            return 'Empty';
        }
        if (typeof value === 'object') {
            const reducedInterval = reduceIntervalToLargestMeasurement(value);
            const entry = Object.entries(reducedInterval).find(([unit, num]) => num !== null);
            if (entry) {
                const [unit, num] = entry;
                return `${num} ${unit}`;
            }
        }
        return value;
    }
    normalizeLimitedLifeObject(limitedLife) {
        Object.values(LimitedLifeIntervalField).forEach((value) => {
            const key = LimitedLifeTypeToPropertyName.get(value) || '';
            if (isNullOrUndefined(limitedLife[key])) {
                limitedLife[key] = {
                    value: null,
                    service: null
                };
            }
        });
        Object.values(LimitedLifeCycleField).forEach((value) => {
            const key = LimitedLifeTypeToPropertyName.get(value) || '';
            if (isNullOrUndefined(limitedLife[key])) {
                limitedLife[key] = {
                    value: null,
                    service: null
                };
            }
        });
        return limitedLife;
    }
    async syncId(id) {
        if (!id) {
            return;
        }
        await this.fetchEntity(id);
        if (!this.selectedEntity) {
            return;
        }
        this.payloadLimitedLife = this.selectedEntity.payload.limitedLife;
        this.currentLimitedLife = this.selectedEntity.itemMaster?.limitedLife || null;
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemMasterLimitedLifeChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ChangeSetDisplayItemMasterLimitedLifeChange.prototype, "syncId", null);
ChangeSetDisplayItemMasterLimitedLifeChange = __decorate([
    Component
], ChangeSetDisplayItemMasterLimitedLifeChange);
export default ChangeSetDisplayItemMasterLimitedLifeChange;
