import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { AssemblyTemplatePartTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { buildIcon, transformItemMasters } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniHardwareListTemplateSlot = class MiniHardwareListTemplateSlot extends BaseMini {
    AssemblyTemplatePartTableHeaders = AssemblyTemplatePartTableHeaders;
    entityType = EntityType.HARDWARE_LIST_TEMPLATE; // we dont have a color for HLTs
    localEntity = null;
    showDetail = false;
    showModalCreateHardwareListTemplate = false;
    showModalHardwareListEditTemplateSlot = false;
    BASE_FETCH = gql `
    query HardwareListTemplateSlotForMini($id: UUID!) {
      hardwareListTemplateSlotById(id: $id) {
        id
        maxQuantity
        minQuantity
        name
        nodeId
        sequence

        hardwareListTemplateParts {
          nodes {
            id
            nodeId
            drawingNumber
            asBuiltNumber
            side

            itemMaster {
              nodeId
              itemDrawingDescription
            }

            itemDrawing {
              nodeId
              description
            }
          }
        }
      }
    }
  `;
    allowEdit;
    allowInstall;
    get hasParts() {
        return this.myHardwareListTemplateParts.length > 0;
    }
    get hardwareListTemplatePartDescription() {
        return this.localEntity?.hardwareListTemplateParts?.nodes[0]?.itemDrawing?.description || null;
    }
    get hardwareListTemplatePartsTitle() {
        if (this.myHardwareListTemplateParts.length === 0) {
            return;
        }
        const listItems = this.myHardwareListTemplateParts
            .map((atp) => {
            return `<li>${atp?.drawingNumber}</li>`;
        })
            .join(' ');
        return `Hardware List Template Parts selected: <ul>${listItems}</ul>`;
    }
    get hardwareListTemplatePartTableItems() {
        return transformItemMasters(this.myHardwareListTemplateParts).map((mappedItem) => {
            return Object.assign({}, mappedItem, {
                _entityTypeIcon: buildIcon(mappedItem.asBuiltNumber ? EntityType.ITEM_MASTER : EntityType.ITEM_DRAWING)
            });
        });
    }
    get iconForHardwareListTemplatePartMultiple() {
        const parts = this.localEntity?.hardwareListTemplateParts.nodes; // convenience
        if (!parts?.length) {
            return null;
        }
        const hasAsBuiltNumber = parts.some((part) => part?.asBuiltNumber);
        return hasAsBuiltNumber ? this.$icons[EntityType.ITEM_MASTER] : this.$icons[EntityType.ITEM_DRAWING];
    }
    get iconForHardwareListTemplatePartSingle() {
        const parts = this.localEntity?.hardwareListTemplateParts.nodes; // convenience
        if (!parts?.length) {
            return null;
        }
        return parts[0]?.asBuiltNumber ? this.$icons[EntityType.ITEM_MASTER] : this.$icons[EntityType.ITEM_DRAWING];
    }
    get maxQuantityDisplay() {
        return this.$numberDisplay(this.localEntity?.maxQuantity);
    }
    get minQuantityDisplay() {
        return this.$numberDisplay(this.localEntity?.minQuantity);
    }
    get myHardwareListTemplateParts() {
        return this.localEntity?.hardwareListTemplateParts.nodes || [];
    }
    get name() {
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get rowsPerPageItems() {
        return [
            {
                text: '50',
                value: 50
            },
            {
                text: '100',
                value: 100
            },
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get shouldShowHardwareListTemplatePartsDisplay() {
        return this.myHardwareListTemplateParts.length === 1;
    }
    get singleHardwareListTemplatePartDisplay() {
        const atps = this.myHardwareListTemplateParts;
        return this.shouldShowHardwareListTemplatePartsDisplay ? this.$itemNumberDisplay(atps[0]) : DEFAULT_DASH;
    }
    get singleHardwareListTemplatePartDisplayHref() {
        if (!this.shouldShowHardwareListTemplatePartsDisplay) {
            return '';
        }
        if (this.singleHardwareListTemplatePartItemMasterPrimaryFields) {
            return this.buildItemMasterManageLink(this.singleHardwareListTemplatePartItemMasterPrimaryFields) || '';
        }
        if (this.singleHardwareListTemplatePartItemDrawingDrawingNumber) {
            return this.buildItemDrawingManageLink(this.singleHardwareListTemplatePartItemDrawingDrawingNumber) || '';
        }
        return '';
    }
    get singleHardwareListTemplatePartItemDrawingDrawingNumber() {
        const atps = this.myHardwareListTemplateParts;
        return this.shouldShowHardwareListTemplatePartsDisplay ? atps[0]?.itemDrawing?.nodeId : null;
    }
    get singleHardwareListTemplatePartItemMasterPrimaryFields() {
        const atps = this.myHardwareListTemplateParts;
        const { drawingNumber, asBuiltNumber, side } = atps[0];
        return this.shouldShowHardwareListTemplatePartsDisplay && drawingNumber && asBuiltNumber && side
            ? {
                drawingNumber,
                asBuiltNumber,
                side
            }
            : null;
    }
    onFetchUpdate(data) {
        if (data.hardwareListTemplateSlotById) {
            this.localEntity = data.hardwareListTemplateSlotById;
        }
    }
    // Called from ui. This gives us a chance to use vue router.
    onClickOfPartChip(event) {
        if (event.metaKey || event.ctrlKey) {
            return;
        }
        event.preventDefault();
        let path = '';
        if (this.singleHardwareListTemplatePartItemMasterPrimaryFields) {
            const { drawingNumber, asBuiltNumber, side } = this.singleHardwareListTemplatePartItemMasterPrimaryFields;
            path = `/hardware/${EntityType.ITEM_MASTER}/manage/${encodeURIComponent(drawingNumber)}/${encodeURIComponent(asBuiltNumber)}/${side}`;
        }
        else if (this.singleHardwareListTemplatePartItemDrawingDrawingNumber) {
            path = `/hardware/${EntityType.ITEM_DRAWING}/manage/${encodeURIComponent(this.singleHardwareListTemplatePartItemDrawingDrawingNumber)}`;
        }
        this.$router.push(path);
    }
    // Called from ui
    onClickOfEdit() {
        if (!this.allowEdit) {
            return;
        }
        this.showModalHardwareListEditTemplateSlot = true;
    }
    // Called from ui
    onClickOfShowDetail() {
        this.showDetail = !this.showDetail;
    }
    // Called from ui
    // Called from ui. Rather than show the create modal in this mini, we're
    // emitting an event with a sequence number. It's easier to emit a sequence
    // as an event than it is to pass down all the crap this mini would need to
    // show the create modal.
    onClickOfInstall() {
        return this.localEntity?.sequence;
    }
    buildItemDrawingManageLink(drawingNumber) {
        return drawingNumber ? `/hardware/${EntityType.ITEM_DRAWING}/manage/${encodeURIComponent(drawingNumber)}` : '';
    }
    buildItemInstanceManageLink(id) {
        return id ? `/hardware/${EntityType.ITEM_INSTANCE}/manage/${id}` : '';
    }
    buildItemMasterManageLink(obj) {
        return obj
            ? `/hardware/${EntityType.ITEM_MASTER}/manage/${encodeURIComponent(obj.drawingNumber)}/${encodeURIComponent(obj.asBuiltNumber)}/${obj.side}`
            : '';
    }
    // Called from ui
    buildHardwareUrl(clickedItem) {
        if (!clickedItem) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Unable to navigate.'
            });
            return null;
        }
        if (clickedItem?.drawingNumber && clickedItem.asBuiltNumber && clickedItem.side) {
            // this is an ItemMaster
            return this.buildItemMasterManageLink(clickedItem);
        }
        else if (clickedItem?.drawingNumber) {
            // this is an ItemDrawing
            return this.buildItemDrawingManageLink(clickedItem.drawingNumber);
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplateSlot.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplateSlot.prototype, "allowInstall", void 0);
__decorate([
    Emit('add-to-hardware-list-at-sequence'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareListTemplateSlot.prototype, "onClickOfInstall", null);
MiniHardwareListTemplateSlot = __decorate([
    Component
], MiniHardwareListTemplateSlot);
export default MiniHardwareListTemplateSlot;
