var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.isValueDifferent ? _c('div', {
    staticClass: "ml-3 mb-4"
  }, [_c('p', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v(_vm._s(_vm.label))])]), _c('p', {
    staticClass: "diff-display-values mb-0"
  }, [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.oldDisplay,
      expression: "oldDisplay"
    }],
    staticClass: "red--text"
  }), _c('c-icon-fa', {
    staticClass: "diff-display-icon mx-2",
    attrs: {
      "icon": _vm.$icons['arrow_right']
    }
  }), _c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.newDisplay,
      expression: "newDisplay"
    }],
    staticClass: "green--text"
  })], 1)]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };