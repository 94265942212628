function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.hasAnyCreateItems ? _c('v-menu', {
    staticClass: "create_menu",
    attrs: {
      "max-height": "90vh",
      "offset-y": "",
      "left": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var menu = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "create-button",
          attrs: {
            "icon": "",
            "tooltip": "Create COSMIC items",
            "ripple": false
          },
          on: {
            "mouseover": function mouseover($event) {
              _vm.isHoveringOverCreateMenuIcon = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.isHoveringOverCreateMenuIcon = false;
            }
          }
        }, _objectSpread({}, menu)), [!_vm.isHoveringOverCreateMenuIcon ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.CosmicIcons.create,
            "icon-color": "white",
            "icon-size": "22px"
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon": _vm.CosmicIcons.create,
            "icon-color": "black",
            "icon-size": "22px"
          }
        }), _c('span', {
          staticClass: "assistive-text"
        }, [_vm._v("Create")])], 1)];
      }
    }], null, false, 931741945)
  }, [_c('v-list', _vm._l(_vm.selectOptions, function (option, i) {
    return _c('v-list-tile', {
      key: i,
      attrs: {
        "href": option.destinationUrl
      },
      on: {
        "click": function click($event) {
          return _vm.onClickOfCreateItem(option, $event);
        }
      }
    }, [_c('v-list-tile-action', {
      staticStyle: {
        "justify-content": "center"
      }
    }, [_c('c-icon-fa', {
      attrs: {
        "icon": option.icon,
        "icon-color": option.textColor,
        "icon-size": "22px"
      }
    })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(option.displayText))])], 1)], 1);
  }), 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };