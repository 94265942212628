var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity && !_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_vm.selectedEntity.payload.inheritLimitedLifeFromMaster ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppDisplayTextDiff', {
    attrs: {
      "label": "Inherit Limited Life from Item Master",
      "newVal": "Item Master Limited Life Values",
      "oldVal": "Item Instance Limited Life Values"
    }
  })], 1) : _vm._e(), _vm.shouldShowValueAndServiceDiffs ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._l(_vm.intervalFields, function (field, index) {
    return _c('div', {
      key: index
    }, [_vm.previousValues[_vm.toCamelCase(field)] && _vm.modifiedValues[_vm.toCamelCase(field)] ? _c('div', _vm._l(Object.keys(_vm.previousValues[_vm.toCamelCase(field)]), function (key) {
      return _c('div', {
        key: key
      }, [key !== 'overrideValueDate' && _vm.modifiedValues[_vm.toCamelCase(field)][key] && _vm.modifiedValues[_vm.toCamelCase(field)][key] !== _vm.DEFAULT_DASH && _vm.previousValues[_vm.toCamelCase(field)][key] !== _vm.DEFAULT_DASH ? _c('div', [_c('AppDisplayTextDiff', {
        key: key,
        attrs: {
          "label": _vm.getLabelForCurrentValue(field, key),
          "newVal": _vm.getValue(_vm.modifiedValues, _vm.toCamelCase(field), key),
          "oldVal": _vm.getValue(_vm.previousValues, _vm.toCamelCase(field), key)
        }
      })], 1) : _vm._e()]);
    }), 0) : _vm._e()]);
  }), _vm._l(_vm.cycleFields, function (field) {
    return _c('div', {
      key: field
    }, _vm._l(Object.keys(_vm.modifiedValues[_vm.toCamelCase(field)]), function (key) {
      return _c('div', {
        key: key
      }, [key !== 'overrideValueDate' && _vm.modifiedValues[_vm.toCamelCase(field)][key] && _vm.modifiedValues[_vm.toCamelCase(field)][key] !== _vm.DEFAULT_DASH && !_vm.previousValues[_vm.toCamelCase(field)] ? _c('div', [_c('AppDisplayTextDiff', {
        key: key,
        attrs: {
          "label": _vm.getLabelForCurrentValue(_vm.toCamelCase(field), key),
          "newVal": _vm.getValue(_vm.modifiedValues, _vm.toCamelCase(field), key),
          "oldVal": "No Value"
        }
      })], 1) : _vm._e(), key !== 'overrideValueDate' && _vm.modifiedValues[_vm.toCamelCase(field)][key] && _vm.modifiedValues[_vm.toCamelCase(field)][key] !== _vm.DEFAULT_DASH && _vm.previousValues[_vm.toCamelCase(field)][key] !== _vm.DEFAULT_DASH ? _c('div', [_c('AppDisplayTextDiff', {
        key: key,
        attrs: {
          "label": _vm.getLabelForCurrentValue(_vm.toCamelCase(field), key),
          "newVal": _vm.getValue(_vm.modifiedValues, _vm.toCamelCase(field), key),
          "oldVal": _vm.getValue(_vm.previousValues, _vm.toCamelCase(field), key)
        }
      })], 1) : _vm._e()]);
    }), 0);
  }), _vm._l(Object.keys(_vm.previousValues), function (field) {
    return _c('div', {
      key: field
    }, [_vm.modifiedValues[field].overrideValueDate && _vm.modifiedValues[field].overrideValueDate !== _vm.DEFAULT_DASH && !_vm.previousValues[field] ? _c('div', [_c('AppDisplayTextDiff', {
      attrs: {
        "label": _vm.capitalizeWord(field) + ' Override Value',
        "newVal": _vm.$dateDisplay(_vm.modifiedValues[field].overrideValueDate),
        "oldVal": "No Value"
      }
    })], 1) : _vm._e(), _vm.modifiedValues[field].overrideValueDate && _vm.modifiedValues[field].overrideValueDate !== _vm.DEFAULT_DASH && _vm.previousValues[field] && _vm.previousValues[field].overrideValueDate ? _c('div', [_c('AppDisplayTextDiff', {
      attrs: {
        "label": _vm.capitalizeWord(field) + ' Override Value',
        "newVal": _vm.$dateDisplay(_vm.modifiedValues[field].overrideValueDate),
        "oldVal": _vm.$dateDisplay(_vm.previousValues[field].overrideValueDate)
      }
    })], 1) : _vm._e()]);
  }), 'code' in _vm.previousValues ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Code",
      "newVal": _vm.codeDisplay(_vm.modifiedValues.code),
      "oldVal": _vm.codeDisplay(_vm.previousValues.code)
    }
  }) : _vm._e(), 'description' in _vm.previousValues ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Description",
      "newVal": _vm.modifiedValues.description,
      "oldVal": _vm.previousValues.description
    }
  }) : _vm._e()], 2) : _vm._e()], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };