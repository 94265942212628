function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, _vm.color, _vm.color), "hasNoActions", !_vm.hasAnyActions), "hideChips", _vm.$vuetify.breakpoint.smAndDown), 'no-entity-icon', !_vm.showEntityTypeIcon)
  }, [_vm.showEntityTypeIcon ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _vm._e(), _vm.number !== '-' ? _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.number))])]), _c('small', {
    staticClass: "caption pl-1",
    attrs: {
      "title": _vm.nameDisplay
    }
  }, [_vm._v(_vm._s(_vm.nameDisplay))])]) : _vm._e(), _c('div', {
    staticClass: "vertical-display-items hidden-sm-and-down white--text"
  }, [_c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Items")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value monospace_font"
  }, [_vm._v(_vm._s(_vm.children.length))])]), _vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style
    }, [_c('div', {
      staticClass: "text-capitalize mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  })], 2), _vm.hasAnyActions ? _c('div', {
    staticClass: "mini_actions_v2"
  }, [_vm.hasChildren && _vm.showChildren ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
          attrs: {
            "icon": _vm.chevronIcon
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onClickOfShowChildren.apply(null, arguments);
            }
          }
        })], 1)];
      }
    }], null, false, 457853435)
  }, [_c('span', [_vm._v("Show/Hide children")])]) : _vm._e(), _vm.shouldShowManageLink ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionManage', {
          staticClass: "manage_icon",
          attrs: {
            "href": _vm.manageLink,
            "tooltip": _vm.manageLinkTitle,
            "icon-color": "white"
          }
        })], 1)];
      }
    }], null, false, 2888646126)
  }, [_c('span', [_vm._v(" Navigate to this Lists manage page ")])]) : _vm._e(), _vm.allowEdit ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionEdit', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleEditHardwareListModalEvent.apply(null, arguments);
            }
          }
        })], 1)];
      }
    }], null, false, 344423229)
  }, [_c('span', [_vm._v(" Edit this List ")])]) : _vm._e(), _vm.shouldShowCreateChildIcon ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionCreate', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.showModalHardwareItemCreate = true;
            }
          }
        })], 1)];
      }
    }], null, false, 9121602)
  }, [_c('span', [_vm._v("Create List item")])]) : _vm._e(), _vm.inlineClear && !_vm.readOnly ? _c('ButtonMiniAction', {
    staticClass: "inline_clear_icon",
    attrs: {
      "icon": _vm.inlineClearIcon,
      "icon-color": "#fff",
      "tooltip": "Clear selection"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.entityType,
      "center": "",
      "type": "linear"
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowChildren ? _c('div', {
    staticClass: "hardware_list_mini_child_container"
  }, _vm._l(_vm.children, function (child) {
    return _c('MiniHardwareListDynamicItem', {
      key: child.sequence,
      attrs: {
        "allow-child-creation": _vm.hasAssemblyTemplateAdminPermission && _vm.allowChildCreation,
        "allow-edit": _vm.hasAssemblyTemplateAdminPermission && _vm.allowEdit,
        "hardware-list-item": child,
        "read-only": _vm.isReadOnly
      },
      on: {
        "add-to-hardware-list-at-sequence": _vm.onAddToHardwareListAtSequence,
        "emit-show-edit-hardware-list-item-modal": function emitShowEditHardwareListItemModal($event) {
          return _vm.handleEditHardwareItemModalEvent(child);
        },
        "delete-non-cosmic-inventory": _vm.refreshData
      }
    });
  }), 1) : _vm._e()]), _vm.showModalHardwareListEdit ? _c('ModalHardwareListEdit', {
    attrs: {
      "hardware-list": _vm.localEntity
    },
    on: {
      "modal-close": _vm.onCloseOfModalHardwareListEdit
    }
  }) : _vm._e(), _vm.showModalHardwareItemCreate && _vm.localEntity ? _c('ModalHardwareListAssemblyCreate', {
    key: _vm.localEntity.id,
    attrs: {
      "default-sequence": _vm.sequenceToInstall,
      "existing-inventory-values": _vm.existingInventoryValues,
      "hide-item-type": _vm.hideItemType,
      "parent-hardware-list": _vm.localEntity,
      "template-items": _vm.hardwareListTemplateSlots,
      "show-additional-metadata": ""
    },
    on: {
      "entityCreated": _vm.onHardwareItemsUpdated,
      "modal-close": _vm.onCloseOfModalHardwareItemCreate
    }
  }) : _vm._e(), _vm.showModalHardwareItemEdit && _vm.selectedHardwareItem ? _c('ModalHardwareListAssemblyEdit', {
    key: _vm.selectedHardwareItem.id,
    attrs: {
      "existing-inventory-values": _vm.existingInventoryValues,
      "hide-item-type": _vm.hideItemType,
      "parent-hardware-list": _vm.localEntity,
      "hardware-item": _vm.selectedHardwareItem,
      "template-items": _vm.hardwareListTemplateSlots
    },
    on: {
      "entityEdited": _vm.onHardwareItemsUpdated,
      "entityDeleted": _vm.onHardwareItemsUpdated,
      "modal-close": _vm.onCloseOfModalHardwareItemEdit
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };