var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity && !_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHeader', [_vm._v("Hardware being removed")]), _c('AppFormWrapper', {
    attrs: {
      "color": "red",
      "size": "1"
    }
  }, [_vm.toBeRemovedHardwareNodeId ? _c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.toBeRemovedHardwareNodeId,
      "hide-border-bottom": ""
    }
  }) : _vm._e()], 1), _vm.locationId ? _c('MiniLocation', {
    staticClass: "mt-2",
    attrs: {
      "title": "Moving to location:",
      "id": _vm.locationId
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };