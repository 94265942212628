var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { InventoryUnitTypeDisplay } from '~/nasa_ui/types';
import { InventoryControlDisplay } from '~/nasa_ui/types/enums/inventory-control';
import { InventoryMethodDisplay } from '~/nasa_ui/types/enums/inventory-method';
let ChangeSetPayloadDisplayItemMasterInventoryMethodChange = class ChangeSetPayloadDisplayItemMasterInventoryMethodChange extends Vue {
    isFetching = false;
    changeSet;
    get changeLog() {
        return this.changeSet?.changeLogs?.nodes[0] || null;
    }
    get hasInventoryControl() {
        return this.payload && 'inventoryControl' in this.payload;
    }
    get hasInventoryMethod() {
        return this.payload && 'inventoryMethod' in this.payload;
    }
    get hasInventoryUnit() {
        return this.payload && 'inventoryUnitType' in this.payload;
    }
    get inventoryControl() {
        if (!this.payload?.inventoryControl) {
            return null;
        }
        return InventoryControlDisplay.get(this.payload.inventoryControl);
    }
    get inventoryControlOld() {
        const inventoryControl = this.changeLog?.changeDetails.changedFrom.inventoryControl
            ? this.changeLog?.changeDetails.changedFrom.inventoryControl
            : null;
        return inventoryControl ? InventoryControlDisplay.get(inventoryControl) : DEFAULT_DASH;
    }
    get inventoryMethod() {
        if (!this.payload?.inventoryMethod) {
            return null;
        }
        return InventoryMethodDisplay.get(this.payload.inventoryMethod);
    }
    get inventoryMethodOld() {
        const inventoryMethod = this.changeLog?.changeDetails.changedFrom.inventoryMethod
            ? this.changeLog?.changeDetails.changedFrom.inventoryMethod
            : null;
        return inventoryMethod ? InventoryMethodDisplay.get(inventoryMethod) : DEFAULT_DASH;
    }
    get inventoryUnit() {
        if (!this.payload?.inventoryUnitType) {
            return null;
        }
        return InventoryUnitTypeDisplay.get(this.payload.inventoryUnitType);
    }
    get inventoryUnitOld() {
        const inventoryUnitType = this.changeLog?.changeDetails.changedFrom.inventoryUnitType
            ? this.changeLog?.changeDetails.changedFrom.inventoryUnitType
            : null;
        return inventoryUnitType ? InventoryUnitTypeDisplay.get(inventoryUnitType) : DEFAULT_DASH;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemMasterInventoryMethodChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemMasterInventoryMethodChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemMasterInventoryMethodChange);
export default ChangeSetPayloadDisplayItemMasterInventoryMethodChange;
