import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseTable from '~/nasa_ui/base/BaseTable';
import { EntityType } from '~/nasa_ui/types';
import { OnOrbitFailuresTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let OnOrbitFailuresTable = class OnOrbitFailuresTable extends BaseTable {
    selectedItemInstance = null;
    hideClosedDate;
    get computedEntityType() {
        return EntityType.CHANGE_SET;
    }
    get computedTableHeaders() {
        if (this.hideClosedDate) {
            return OnOrbitFailuresTableHeaders.filter((header) => header.text !== 'Closed');
        }
        return OnOrbitFailuresTableHeaders;
    }
    get computedTitle() {
        return this.title || 'On-Orbit Failures';
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], OnOrbitFailuresTable.prototype, "hideClosedDate", void 0);
OnOrbitFailuresTable = __decorate([
    Component
], OnOrbitFailuresTable);
export default OnOrbitFailuresTable;
