import gql from 'graphql-tag';
import { apolloClient } from '~/nasa_ui/apollo.config';
/**
 * This utility is for creating a change set with a comment. If the comment text
 * is empty then only a change set will be created.
 *
 * TODO: check for CommentType.GENERAL and if true then submit the comment with
 * the createChangeSet mutation instead of a separate createComment mutation
 *
 * @param options takes the inputs and preferred error text for each mutation
 * @returns the created change set and, if available, the created comment
 */
export async function createChangeSetWithComment(options) {
    if (!options.changeSet) {
        return null;
    }
    const changeSetErrorText = options.changeSetErrorText ?? 'Failed to create change set.';
    // define this outside the try/catch block so we can use it later
    let changeSet;
    // attempt to create the change set
    try {
        changeSet = await createChangeSet(options.changeSet);
        // if we don't have a change set, we gotta abort
        if (!changeSet) {
            return null;
        }
    }
    catch (err) {
        return null;
    }
    const commentErrorText = options.commentErrorText ?? 'Failed to create comment.';
    // attempt to create the comment
    try {
        // attach the change set to this comment
        options.comment.changeSetId = changeSet.id;
        const comment = await createComment(options.comment);
        // if we don't have a comment we can still return the change set
        if (!comment) {
            return {
                changeSet,
                comment: null
            };
        }
        // success -- return the full payload
        return {
            changeSet,
            comment
        };
    }
    catch (err) {
        // error creating the comment, but we can still return the change set
        return {
            changeSet,
            comment: null
        };
    }
}
export async function createChangeSet(changeSet) {
    if (!changeSet) {
        return null;
    }
    const resp = await createChangeSetMutation(changeSet);
    if (resp?.data?.createChangeSet?.changeSet) {
        return resp.data.createChangeSet.changeSet;
    }
    return null;
}
export function createChangeSetMutation(changeSet) {
    if (!changeSet) {
        return null;
    }
    return apolloClient.mutate({
        mutation: gql `
      fragment ChangeSetOnChangeSetCommentUtility on ChangeSet {
        computedStatus
        id
        nodeId
        subType
      }

      mutation CreateChangeSetOnChangeSetCommentUtility($changeSet: CreateChangeSetInput!) {
        createChangeSet(input: $changeSet) {
          changeSet {
            ...ChangeSetOnChangeSetCommentUtility
          }
        }
      }
    `,
        variables: {
            changeSet
        }
    });
}
export async function createComment(comment) {
    if (!comment.comment) {
        return null;
    }
    const resp = await createCommentMutation(comment);
    if (resp?.data?.createComment?.comment) {
        return resp.data.createComment.comment;
    }
    return null;
}
export function createCommentMutation(comment) {
    if (!comment) {
        return null;
    }
    return apolloClient.mutate({
        mutation: gql `
      fragment CommentOnChangeSetCommentUtility on Comment {
        comment
        id
        nodeId
        subType
      }

      mutation CreateCommentOnChangeSetCommentUtility($comment: CommentInput!) {
        createComment(input: { comment: $comment }) {
          comment {
            ...CommentOnChangeSetCommentUtility
          }
        }
      }
    `,
        variables: {
            comment
        }
    });
}
