var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { FederalSupplyClassDisplay } from '~/nasa_ui/types/enums/federal-supply-class';
import { ItemMasterPropertyClassDisplay } from '~/nasa_ui/types/enums/property-class';
let ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange = class ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange extends Vue {
    isFetching = false;
    changeSet;
    get changeLog() {
        return this.changeSet?.changeLogs?.nodes[0] || null;
    }
    get hasFederalSupplyClass() {
        return this.payload && 'federalSupplyClass' in this.payload;
    }
    get hasPropertyClass() {
        return this.payload && 'propertyClass' in this.payload;
    }
    get federalSupplyClass() {
        if (!this.payload?.federalSupplyClass) {
            return null;
        }
        return FederalSupplyClassDisplay.get(this.payload.federalSupplyClass);
    }
    get federalSupplyClassOld() {
        const supplyClass = this.changeLog?.changeDetails.changedFrom.federalSupplyClass
            ? this.changeLog?.changeDetails.changedFrom.federalSupplyClass
            : null;
        return supplyClass ? FederalSupplyClassDisplay.get(supplyClass) : DEFAULT_DASH;
    }
    get propertyClass() {
        if (!this.payload?.propertyClass) {
            return null;
        }
        return ItemMasterPropertyClassDisplay.get(this.payload.propertyClass);
    }
    get propertyClassOld() {
        const propertyClass = this.changeLog?.changeDetails.changedFrom.propertyClass
            ? this.changeLog?.changeDetails.changedFrom.propertyClass
            : null;
        return propertyClass ? ItemMasterPropertyClassDisplay.get(propertyClass) : DEFAULT_DASH;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange);
export default ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange;
