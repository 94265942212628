var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
let ChangeSetDisplayItemInstanceOnOrbitFailure = class ChangeSetDisplayItemInstanceOnOrbitFailure extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : {};
    }
    changesInclude(property) {
        return Object.keys(this.payload).includes(property);
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query SelectChangeSetForItemInstanceOnOrbitFailure($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              payload
              itemInstance {
                nodeId
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceOnOrbitFailure.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemInstanceOnOrbitFailure.prototype, "syncId", null);
ChangeSetDisplayItemInstanceOnOrbitFailure = __decorate([
    Component
], ChangeSetDisplayItemInstanceOnOrbitFailure);
export default ChangeSetDisplayItemInstanceOnOrbitFailure;
