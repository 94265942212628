var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
let ChangeSetPayloadDisplayItemInstanceConfigurationChange = class ChangeSetPayloadDisplayItemInstanceConfigurationChange extends Vue {
    isFetching = false;
    changeSet;
    get changeLog() {
        return this.changeSet?.changeLogs?.nodes[0] || null;
    }
    get hasAsBuiltNumber() {
        return this.payload && 'asBuiltNumber' in this.payload;
    }
    get hasAsDesignNumber() {
        return this.payload && 'asDesignNumber' in this.payload;
    }
    get asBuiltNumber() {
        return this.payload?.asBuiltNumber || null;
    }
    get asBuiltNumberOld() {
        return this.changeLog?.changeDetails.changedFrom.asBuiltNumber
            ? this.changeLog?.changeDetails.changedFrom.asBuiltNumber
            : DEFAULT_DASH;
    }
    get asDesignNumber() {
        return this.payload?.asDesignNumber || null;
    }
    get asDesignNumberOld() {
        return this.changeLog?.changeDetails.changedFrom.asBuiltNumber
            ? this.changeLog?.changeDetails.changedFrom.asBuiltNumber
            : DEFAULT_DASH;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemInstanceConfigurationChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemInstanceConfigurationChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemInstanceConfigurationChange);
export default ChangeSetPayloadDisplayItemInstanceConfigurationChange;
