var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
let ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove = class ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove extends Vue {
    isFetching = false;
    changeSet;
    get locationId() {
        return this.payload?.toLocationId || null;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove = __decorate([
    Component
], ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove);
export default ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove;
