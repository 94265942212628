import { __decorate, __metadata } from "tslib";
import { isAfter, isBefore } from 'date-fns';
import { isNumber } from 'lodash';
import get from 'lodash/get';
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseTable, { CURRENCY_HEADERS } from '~/nasa_ui/base/BaseTable';
import { HeaderSortOptions, TableTypes } from '~/nasa_ui/types';
import { stripHtmlFromString } from '~/nasa_ui/utils';
/**
 * AVAILABLE PROPS:
 *  everything is optional
 *  bools are defaulted to false
 *
 * color
 * icon
 * items
 * hide-pagination
 * headers (table headers)
 * entity-type
 * rows-per-page-items (determines what the pagination stops are)
 * show-search-input
 * show-header
 * title
 */
let AppTableExpandable = class AppTableExpandable extends BaseTable {
    disabled;
    selectedItemKey = null;
    tableType = TableTypes.TABLE_EXPANDABLE;
    customSort = (items, colClicked, isDescending) => {
        const headerDefinition = (this.headers || this.computedTableHeaders || []).find((header) => header.value === colClicked);
        const customSortOption = headerDefinition?.headerSortType;
        // check for explicity passed custom sort first
        if (customSortOption) {
            const customSortFunc = `_customSort${customSortOption}`;
            return this[customSortFunc]
                ? this[customSortFunc](colClicked, items, isDescending)
                : this._customSortTEXT(colClicked, items, isDescending);
        }
        // then do blanket checks for known header sorts
        if (CURRENCY_HEADERS.includes(colClicked)) {
            const customSortFunc = `_customSort${HeaderSortOptions.CURRENCY}`;
            return this[customSortFunc](colClicked, items, isDescending);
        }
        return items.sort((itemA, itemB) => {
            if (typeof itemB[colClicked] !== 'undefined' && typeof itemA[colClicked] !== 'undefined') {
                let valA = typeof itemA[colClicked] === 'number' ? itemA[colClicked].toString() : itemA[colClicked];
                let valB = typeof itemB[colClicked] === 'number' ? itemB[colClicked].toString() : itemB[colClicked];
                // Handles situation where the val could be null/undefined
                valA = valA || '';
                valB = valB || '';
                if (!isDescending) {
                    return valA.toLowerCase().localeCompare(valB.toLowerCase());
                }
                else {
                    return valB.toLowerCase().localeCompare(valA.toLowerCase());
                }
            }
        });
    };
    onClickOfExpandableRow(trProps) {
        if (this.disabled) {
            return;
        }
        trProps.expanded = !trProps.expanded;
        this.expandableRowClick(trProps.item);
    }
    expandableRowClick(val) {
        const getItemKey = this.$get(val, this.itemKey);
        // We are selecting the same row so
        // we must be closing it...
        if (this.selectedItemKey === getItemKey) {
            this.selectedItemKey = null;
            // Return here is for the input emission
            return null;
        }
        else {
            // Otherwise we are clicking on a new row
            this.selectedItemKey = getItemKey;
            // Return here is for the input emission
            return val;
        }
    }
    // THESE FNCs ARE COPIED INTO EVER APPTABLE bc vuetify doesnt pass the context
    _customSortDATE = (colClicked, items, isDescending) => {
        items = items.sort(function (a, b) {
            const objectProp = `${colClicked}`;
            const _a = get(a, objectProp);
            const _b = get(b, objectProp);
            // always puts values of `-` at the bottom of the sort, irregardless of `isDescending`
            if (!_a || _a === '-') {
                return 1;
            }
            if (!_b || _b === '-') {
                return -1;
            }
            const aDate = new Date(_a);
            const bDate = new Date(_b);
            return isDescending ? (isAfter(bDate, aDate) ? 1 : -1) : isBefore(bDate, aDate) ? 1 : -1;
        });
        return items;
    };
    _customSortDATETIME = (colClicked, items, isDescending) => {
        items = items.sort(function (a, b) {
            const ticksVersion = `${colClicked.replace('TimeWithBreak', '')}Ticks`;
            const _a = get(a, ticksVersion);
            const _b = get(b, ticksVersion);
            // always puts values of `-` at the bottom of the sort, irregardless of `isDescending`
            if (!_a || _a === '-') {
                return 1;
            }
            if (!_b || _b === '-') {
                return -1;
            }
            // sorting by ticks to account for same day and minute
            return isDescending ? _b - _a : _a - _b;
        });
        return items;
    };
    _customSortNUMBER = (colClicked, items, isDescending) => {
        const converted = items.sort((a, b) => {
            const _a = get(a, colClicked);
            const _b = get(b, colClicked);
            // always puts values of `-` or 'ALL' at the bottom of the sort, irregardless of `isDescending`
            if (_a === '-' || _a === 'ALL') {
                return 1;
            }
            if (_b === '-' || _b === 'ALL') {
                return -1;
            }
            const numberA = isNumber(_a) ? _a : parseFloat(_a.replace(',', '')) || 0;
            const numberB = isNumber(_b) ? _b : parseFloat(_b.replace(',', '')) || 0;
            return isDescending ? numberB - numberA : numberA - numberB;
        });
        return converted;
    };
    _customSortCURRENCY = (colClicked, items, isDescending) => {
        const converted = items.sort((a, b) => {
            const _a = get(a, colClicked);
            const _b = get(b, colClicked);
            // always puts values of `-` at the bottom of the sort, irregardless of `isDescending`
            if (_a === '-') {
                return 1;
            }
            if (_b === '-') {
                return -1;
            }
            const numberA = parseFloat(stripHtmlFromString(_a).replace('$', '').replace(',', ''));
            const numberB = parseFloat(stripHtmlFromString(_b).replace('$', '').replace(',', ''));
            return isDescending ? numberB - numberA : numberA - numberB;
        });
        return converted;
    };
    _customSortPERCENT = (colClicked, items, isDescending) => {
        const converted = items.sort((a, b) => {
            const _a = get(a, colClicked);
            const _b = get(b, colClicked);
            // always puts values of `-` at the bottom of the sort, irregardless of `isDescending`
            if (_a === '-') {
                return 1;
            }
            if (_b === '-') {
                return -1;
            }
            const numberA = parseFloat(stripHtmlFromString(_a).replace('%', '').replace('&nbsp;&percnt;', ''));
            const numberB = parseFloat(stripHtmlFromString(_b).replace('%', '').replace('&nbsp;&percnt;', ''));
            return isDescending ? numberB - numberA : numberA - numberB;
        });
        return converted;
    };
    _customSortTEXT = (colClicked, items, isDescending) => {
        const converted = items.sort((a, b) => {
            const _a = get(a, colClicked);
            const _b = get(b, colClicked);
            // always puts values of `-` at the bottom of the sort, irregardless of `isDescending`
            if (_a === '-') {
                return 1;
            }
            if (_b === '-') {
                return -1;
            }
            const strA = stripHtmlFromString(_a);
            const strB = stripHtmlFromString(_b);
            return isDescending ? (strB < strA ? -1 : 1) : strA > strB ? 1 : -1;
        });
        return converted;
    };
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppTableExpandable.prototype, "disabled", void 0);
__decorate([
    Emit('expandableRowClick'),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppTableExpandable.prototype, "expandableRowClick", null);
AppTableExpandable = __decorate([
    Component
], AppTableExpandable);
export default AppTableExpandable;
