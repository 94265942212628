var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import difference from 'lodash/difference';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { CosmicItemDrawingSlimFragment } from '~/nasa_ui/DAL/itemDrawing/slim';
import { AlertType } from '~/nasa_ui/types';
let ChangeSetDisplayItemDrawingValidSizesChange = class ChangeSetDisplayItemDrawingValidSizesChange extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get currentValue() {
        return this.selectedEntity && this.selectedEntity.itemDrawing ? this.selectedEntity.itemDrawing.sizes : null;
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : null;
    }
    get finalValue() {
        return this.payload && this.payload.sizes
            ? this.payload.sizes.map((text) => {
                return {
                    text,
                    color: 'primary',
                    textColor: 'white'
                };
            })
            : null;
    }
    get additions() {
        if (!this.currentValue || !this.payload || !this.payload.hasOwnProperty('sizes')) {
            return null;
        }
        return difference(this.payload.sizes, this.currentValue).map((text) => {
            return {
                text,
                color: AlertType.SUCCESS,
                textColor: 'white'
            };
        });
    }
    get removals() {
        if (!this.currentValue || !this.payload || !this.payload.hasOwnProperty('sizes')) {
            return null;
        }
        return difference(this.currentValue, this.payload.sizes).map((text) => {
            return {
                text,
                color: AlertType.ERROR,
                textColor: 'white'
            };
        });
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemDrawingSlimFragment}

          query SelectChangeSetForItemDrawingValidSizesChange($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              payload
              itemDrawing {
                ...CosmicItemDrawingSlim
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemDrawingValidSizesChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemDrawingValidSizesChange.prototype, "syncId", null);
ChangeSetDisplayItemDrawingValidSizesChange = __decorate([
    Component
], ChangeSetDisplayItemDrawingValidSizesChange);
export default ChangeSetDisplayItemDrawingValidSizesChange;
