var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppDisplayTextDiff = class AppDisplayTextDiff extends Vue {
    label;
    newVal;
    oldVal;
    get isValueDifferent() {
        return this.newVal !== this.oldVal;
    }
    get newDisplay() {
        return this.displayValue(this.newVal);
    }
    get oldDisplay() {
        return this.displayValue(this.oldVal);
    }
    displayValue(val) {
        if (val === null || val === undefined) {
            return 'No Value';
        }
        return val.toString();
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppDisplayTextDiff.prototype, "label", void 0);
__decorate([
    Prop({
        type: [String, Number, Boolean]
    }),
    __metadata("design:type", typeof (_a = typeof DiffValue !== "undefined" && DiffValue) === "function" ? _a : Object)
], AppDisplayTextDiff.prototype, "newVal", void 0);
__decorate([
    Prop({
        type: [String, Number, Boolean]
    }),
    __metadata("design:type", typeof (_b = typeof DiffValue !== "undefined" && DiffValue) === "function" ? _b : Object)
], AppDisplayTextDiff.prototype, "oldVal", void 0);
AppDisplayTextDiff = __decorate([
    Component
], AppDisplayTextDiff);
export default AppDisplayTextDiff;
