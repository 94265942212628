var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({
    staticClass: "app-blockquote",
    class: ["text-xs-".concat(_vm.align), _vm.removeMarginBottom ? 'mb-0' : '']
  }, _vm.$listeners), [_c('div', {
    staticClass: "title"
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2), _vm.hasDefaultSlot ? _c('blockquote', {
    staticClass: "blockquote"
  }, [_vm.bold ? _c('strong', [_vm._t("default")], 2) : _vm._t("default")], 2) : _c('blockquote', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.valueToDisplay,
      expression: "valueToDisplay"
    }],
    staticClass: "blockquote"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };