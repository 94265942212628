import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { CREATED_DATE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CosmicChangeSetStatusFragment } from '~/nasa_ui/DAL/changeSet';
import { CosmicChangeSetSlimFragment } from '~/nasa_ui/DAL/changeSet/slim';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicItemDrawingSlimFragment } from '~/nasa_ui/DAL/itemDrawing/slim';
import { CosmicItemMasterSlimFragment as CosmicItemMasterSlimFragmentOverride } from '~/nasa_ui/DAL/itemMaster/slim';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { EntityType, EntityTypeSingularDisplay } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
import { ChangeSetTableHeaders, ItemInstancesTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformChangeSets, transformItemDrawings, transformItemInstances, transformItemMasters } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { buildIconAsString } from '~/nasa_ui/utils/ui/buildIconAsString';
let LatestThings = class LatestThings extends Vue {
    isFetching = false;
    queryResults = null;
    selectedChangeSet = null;
    selectedHardware = null;
    get computedLatestItemDrawings() {
        return this.latestItemDrawings.map((id) => {
            const transformedId = transformItemDrawings([id])[0];
            return {
                _asBuiltNumber: NOT_APPLICABLE,
                _hardwareLevel: buildIconAsString({
                    iconClass: this.$icons[EntityType.ITEM_DRAWING],
                    title: `Relates to ${EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)}`,
                    color: EntityType.ITEM_DRAWING
                }),
                _lotNumber: NOT_APPLICABLE,
                _serialNumber: NOT_APPLICABLE,
                _side: NOT_APPLICABLE,
                _size: NOT_APPLICABLE,
                _subType: NOT_APPLICABLE,
                _projectCodes: NOT_APPLICABLE,
                ...transformedId
            };
        });
    }
    get computedLatestItemMasters() {
        return this.latestItemMasters.map((im) => {
            const transformedIm = transformItemMasters([im])[0];
            // Cant spread here bc the spread operator always overwrites
            return Object.assign({}, { ...transformedIm }, {
                _hardwareLevel: buildIconAsString({
                    iconClass: this.$icons[EntityType.ITEM_MASTER],
                    title: `Relates to ${EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)}`,
                    color: EntityType.ITEM_MASTER
                }),
                _lotNumber: NOT_APPLICABLE,
                _projectCodes: NOT_APPLICABLE,
                _serialNumber: NOT_APPLICABLE,
                _size: NOT_APPLICABLE,
                _subType: NOT_APPLICABLE // this is the II subType (class) bc they are all slammed together in this table
            });
        });
    }
    get computedLatestItemInstances() {
        return this.latestItemInstances.map((ii) => {
            const transformedIi = transformItemInstances([ii])[0];
            return {
                _hardwareLevel: buildIconAsString({
                    iconClass: this.$icons[EntityType.ITEM_INSTANCE],
                    title: `Relates to ${EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)}`,
                    color: EntityType.ITEM_INSTANCE
                }),
                _createdOn: this.$dateTimeDisplay(ii.createdDateTime),
                ...transformedIi
            };
        });
    }
    get computedLatestChangeSets() {
        return transformChangeSets(this.latestChangeSets).map((changeSet) => {
            if (changeSet.subType.startsWith('ITEM_DRAWING')) {
                changeSet._asBuiltNumber = NOT_APPLICABLE;
                changeSet._serialNumber = NOT_APPLICABLE;
                changeSet._lotNumber = NOT_APPLICABLE;
            }
            if (changeSet.subType.startsWith('ITEM_MASTER')) {
                changeSet._serialNumber = NOT_APPLICABLE;
                changeSet._lotNumber = NOT_APPLICABLE;
            }
            return changeSet;
        });
    }
    get customChangeSetsPagination() {
        return {
            descending: true,
            sortBy: '_openedOn'
        };
    }
    get customHardwarePagination() {
        return {
            descending: true,
            sortBy: '_createdDateTimeTicks'
        };
    }
    get latestChangeSets() {
        if (!this.queryResults) {
            return [];
        }
        return (this.queryResults.changeSets?.nodes || []);
    }
    get latestItemDrawings() {
        if (!this.queryResults) {
            return [];
        }
        return (this.queryResults.itemDrawings?.nodes || []);
    }
    get latestItemMasters() {
        if (!this.queryResults) {
            return [];
        }
        return (this.queryResults.itemMasters?.nodes || []);
    }
    get latestItemInstances() {
        if (!this.queryResults) {
            return [];
        }
        return (this.queryResults.itemInstances?.nodes || []);
    }
    get latestHardwareTableHeaders() {
        const iiHeaders = [...ItemInstancesTableHeaders];
        const iiHeadersWithoutInv = iiHeaders
            .filter((header) => header.value !== '_inventoryQuantity')
            .map((header) => {
            return Object.assign({}, header, { noEscape: true });
        });
        return [
            {
                text: 'Hardware<br/>level',
                value: '_hardwareLevel',
                sortable: true,
                align: 'center',
                noEscape: true
            },
            ...iiHeadersWithoutInv,
            CREATED_DATE_COL
        ];
    }
    get latestChangeSetTableHeaders() {
        return [...ChangeSetTableHeaders].map((header) => {
            return Object.assign({}, header, { noEscape: true });
        });
    }
    get latestChangeSetTableItems() {
        return this.computedLatestChangeSets; // same just wanted the names to match
    }
    get latestHardwareTableItems() {
        const computedId = this.computedLatestItemDrawings;
        const computedIm = this.computedLatestItemMasters;
        const computedIi = this.computedLatestItemInstances;
        return [...computedId, ...computedIm, ...computedIi].sort(sortObjectBy('_createdDateTimeTicks', true));
    }
    get rowsPerPageItems() {
        return [
            5,
            30,
            100,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get selectedHardwareNodeId() {
        if (!this.selectedHardware) {
            return null;
        }
        return this.selectedHardware.nodeId || null;
    }
    get selectedChangeSetHardwareNodeId() {
        if (!this.selectedChangeSet) {
            return null;
        }
        if (this.selectedChangeSet.itemInstance) {
            return this.selectedChangeSet.itemInstance.nodeId;
        }
        else if (this.selectedChangeSet.itemMaster) {
            return this.selectedChangeSet.itemMaster.nodeId;
        }
        else if (this.selectedChangeSet.itemDrawing) {
            return this.selectedChangeSet.itemDrawing.nodeId;
        }
        return null;
    }
    mounted() {
        this.getTheLatestThings();
    }
    handleHardwareRowClick(row) {
        this.selectedHardware = row;
    }
    handleChangeSetRowClick(row) {
        this.selectedChangeSet = row;
    }
    async getTheLatestThings() {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemDrawingSlimFragment}
          ${CosmicChangeSetStatusFragment}
          ${CosmicUserSlimFragment}
          ${CosmicChangeSetSlimFragment}
          ${CosmicDocumentSlimFragment}

          fragment ItemDrawingsOnLatestThings on ItemDrawing {
            createdDateTime
            ...CosmicItemDrawingSlim
          }

          ${CosmicItemMasterSlimFragmentOverride}

          fragment ItemInstancesOnLatestThings on ItemInstance {
            asBuiltNumber
            createdDateTime
            drawingNumber
            itemDrawingDescription
            lotNumber
            nodeId
            projectCodes
            serialNumber
            side
            size
            subType
          }

          fragment ChangeSetsOnLastestThings on ChangeSet {
            ...CosmicChangeSetSlim

            createdByUser {
              ...CosmicUserSlim
            }

            document {
              ...CosmicDocumentSlim
            }

            itemDrawing {
              ...ItemDrawingsOnLatestThings
            }
            itemMaster {
              ...CosmicItemMasterSlim
            }
            itemInstance {
              ...ItemInstancesOnLatestThings
            }
            status {
              ...CosmicChangeSetStatus
            }
          }

          query LatestThings {
            itemDrawings(first: 10, orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...ItemDrawingsOnLatestThings
              }
            }

            itemMasters(first: 10, orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...CosmicItemMasterSlim
              }
            }

            itemInstances(first: 10, orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...ItemInstancesOnLatestThings
              }
            }

            changeSets(first: 50, orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...ChangeSetsOnLastestThings
              }
            }
          }
        `
            });
            this.isFetching = false;
            this.queryResults = resp.data;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to get the latest things.'
            });
        }
    }
};
LatestThings = __decorate([
    Component
], LatestThings);
export default LatestThings;
