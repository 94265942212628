var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.color,
      "modal-width": _vm.modalWidth,
      "hide-title-section": ""
    },
    on: {
      "input": _vm.emitModalClose,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('AppHeader', {
          staticClass: "mb-3"
        }, [_vm._v(_vm._s(_vm.computedTitle))]), _c('Alert', {
          attrs: {
            "type": "warning"
          }
        }, [_vm._t("text", function () {
          return [_vm._v(" This is a "), _c('em', [_vm._v("permanent")]), _vm._v(" "), _c('strong', [_vm._v("DELETE")]), _vm._v(". Be careful. ")];
        })], 2), _vm._t("html")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-spacer'), _c('ButtonGeneric', {
          attrs: {
            "color": "var(--v-secondary-base)"
          },
          on: {
            "click": _vm.onClickOfNo
          }
        }, [_vm._v("No")]), _c('ButtonGeneric', {
          attrs: {
            "color": "var(--v-success-base)",
            "disabled": _vm.disabled
          },
          on: {
            "click": _vm.onClickOfYes
          }
        }, [_vm._v(" Yes, I'm sure ")])];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };