var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity && !_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_vm.previousValues ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._l(_vm.intervalFields, function (field) {
    return _c('div', {
      key: field
    }, [field in _vm.previousValues ? _c('div', _vm._l(Object.keys(_vm.previousValues[field]), function (key) {
      return _c('AppDisplayTextDiff', {
        key: "previous-".concat(key),
        attrs: {
          "label": _vm.getLabelForCurrentValue(field, key),
          "newVal": _vm.getValue(_vm.modifiedValues, field, key),
          "oldVal": _vm.getValue(_vm.previousValues, field, key)
        }
      });
    }), 1) : _vm._e()]);
  }), _vm._l(_vm.cycleFields, function (field) {
    return _c('div', {
      key: field
    }, [field in _vm.previousValues ? _c('div', _vm._l(Object.keys(_vm.previousValues[field]), function (key) {
      return _c('AppDisplayTextDiff', {
        key: key,
        attrs: {
          "label": _vm.getLabelForCurrentValue(field, key),
          "newVal": _vm.getValue(_vm.modifiedValues, field, key),
          "oldVal": _vm.getValue(_vm.previousValues, field, key)
        }
      });
    }), 1) : _vm._e()]);
  }), 'code' in _vm.previousValues ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Code",
      "newVal": _vm.codeDisplay(_vm.modifiedValues.code),
      "oldVal": _vm.codeDisplay(_vm.previousValues.code)
    }
  }) : _vm._e(), 'description' in _vm.previousValues ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Description",
      "newVal": _vm.modifiedValues.description,
      "oldVal": _vm.previousValues.description
    }
  }) : _vm._e()], 2) : _vm._e()], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };