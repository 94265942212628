var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType } from '~/db_types';
import DueInMixin from '~/mixins/DueInMixin';
import BaseView from '~/nasa_ui/base/BaseView';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
import { AlertType, IAppAuthority } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { ItemMastersTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformChangeSets, transformItemMasters } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let HomeView = class HomeView extends mixins(BaseView, DueInMixin) {
    finishedInitialFetchForInventoryDashboard = false;
    finishedInitialFetchForOpenFailures = false;
    isFetching = false;
    isRecentActivityOpen = false;
    isSaving = false;
    ItemMastersTableHeaders = [...ItemMastersTableHeaders];
    openFailures = [];
    selectedUnapprovedItemMaster = null;
    showAhdModal = false;
    showItemMasterInitModal = false;
    unapprovedItemMasters = [];
    user = this.currentUser;
    appAuthorityData = {
        authorityCode: null,
        authorityNumber: null,
        comment: null
    };
    initializationFormData = {
        payload: {
            initializationCode: null,
            initializationAuthorityNumber: null,
            initializationComment: null
        }
    };
    get computedUnapprovedItemMasters() {
        return transformItemMasters(this.unapprovedItemMasters || []);
    }
    get computedUnapprovedItemMasterTableColumns() {
        const cols = [...this.ItemMastersTableHeaders].slice(0, this.ItemMastersTableHeaders.length - 1);
        return cols.filter((col) => col.value !== '_approvalStatusIcon');
    }
    get isItemInitializationFormValid() {
        return (this.initializationFormData.payload.initializationCode &&
            this.initializationFormData.payload.initializationAuthorityNumber);
    }
    get gqlFetchOpenFailureChangeSetsVariables() {
        return {
            condition: {
                subType: ChangeSetType.ITEM_INSTANCE_ON_ORBIT_FAILURE,
                computedStatus: ChangeSetStatus.OPEN
            }
        };
    }
    get myInventoryLink() {
        return `/reporting/inventory?supportContext=${this.currentUserActiveSupportContext}`;
    }
    get openFailuresTableItems() {
        if (!this.openFailures.length) {
            return undefined;
        }
        return this.openFailures.map((changeSet) => {
            if (!changeSet) {
                return null;
            }
            const formattedChangeSet = transformChangeSets([changeSet])[0];
            return {
                _failureDate: this.$dateDisplay(changeSet.openedDate),
                _failureMode: changeSet.payload.failureMode || DEFAULT_DASH,
                _side: changeSet.itemInstance?.side || DEFAULT_DASH,
                _size: changeSet.itemInstance?.size || DEFAULT_DASH,
                ...formattedChangeSet
            };
        });
    }
    get rowsPerPageItems() {
        return [
            5,
            10,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get selectedRowItemMasterNodeId() {
        return this.selectedUnapprovedItemMaster?.nodeId;
    }
    get shouldShowHomeView() {
        return ['HomeView'].indexOf(this.$route.name || '') > -1;
    }
    get shouldShowItemMasterInitModal() {
        return Boolean(this.selectedRowItemMasterNodeId) && this.showItemMasterInitModal;
    }
    get shouldShowAhdModal() {
        return Boolean(this.selectedRowItemMasterNodeId) && this.showAhdModal;
    }
    get shouldShowNoExpectedShipmentAlert() {
        return (this.unapprovedItemMasters.length === 0 &&
            this.inTransitTableItems.length === 0 &&
            this.inventoryDueBackItems.length === 0 &&
            this.openPurchaseRequests.length === 0 &&
            this.finishedInitialFetchForInventoryDashboard);
    }
    get shouldShowOpenPurchaseRequestsTable() {
        return (Boolean(this.openPurchaseRequests.length) && this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN));
    }
    get shouldShowInTransitTable() {
        return (Boolean(this.inTransitTableItems.length) && this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN));
    }
    get shouldShowInventoryDueBackTable() {
        return (Boolean(this.inventoryDueBackItems.length) && this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN));
    }
    get shouldShowUnapprovedItemMastersTable() {
        return (Boolean(this.computedUnapprovedItemMasters.length) &&
            this.currentUserHasPermission(UserPermission.ITEM_MASTER_ADMIN));
    }
    get shouldShowOpenItemsDashboardPanel() {
        return (Boolean(this.openFailuresTableItems?.length) &&
            this.currentUserHasPermission(UserPermission.ON_ORBIT_FAILURE_ADMIN));
    }
    created() {
        this.$listen(`reload_due_back`, this.fetchInventoryDueBack);
        this.fetchData();
        // Every 5m
        setInterval(this.fetchData, 1000 * 60 * 5);
    }
    /**
     * ChangeSet creation for IM initialization
     *
     * @param payload JSON
     * @param changeSetType ChangeSetType
     */
    createChangeSet(payload, changeSetType) {
        if (!this.selectedUnapprovedItemMaster) {
            return;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation CreateItemMasterChangeSetOnHome(
          $asBuiltNumber: String!
          $drawingNumber: String!
          $openedDate: Date!
          $side: Side!
          $subType: String!
          $payload: JSON!
        ) {
          createChangeSet(
            input: {
              asBuiltNumber: $asBuiltNumber
              drawingNumber: $drawingNumber
              openedDate: $openedDate
              payload: $payload
              side: $side
              subType: $subType
            }
          ) {
            changeSet {
              id
              nodeId
              subType
            }
          }
        }
      `,
            variables: {
                asBuiltNumber: this.selectedUnapprovedItemMaster.asBuiltNumber,
                drawingNumber: this.selectedUnapprovedItemMaster.drawingNumber,
                openedDate: this.$dateDisplay(new Date()),
                payload,
                side: this.selectedUnapprovedItemMaster.side,
                subType: changeSetType
            }
        });
    }
    async createItemInitializationChangeSet() {
        if (!this.initializationFormData) {
            return;
        }
        try {
            const payload = this.initializationFormData.payload;
            const resp = await this.createChangeSet(payload, ChangeSetType.ITEM_MASTER_INITIALIZATION);
            if (resp && resp.data && resp.data.createChangeSet && resp.data.createChangeSet.changeSet) {
                this.$notification.add({
                    text: `A Change Set has been opened for the initialization of this Item.`,
                    type: AlertType.SUCCESS
                });
                // tell the navbar to refetch correct num of user alerts
                this.$message('reload_user_alerts');
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    // fetch item masters not approved
    async fetchUnapprovedItemMasters() {
        // Execute query
        const resp = await this.$apollo.query({
            query: gql `
        fragment UnapprovedItemMaster on ItemMaster {
          asBuiltNumber
          drawingNumber
          federalSupplyClass
          initializationStatus
          itemDrawingDescription
          nodeId
          side
          subType
          changeSets(filter: { subType: { equalTo: ITEM_MASTER_INITIALIZATION } }, orderBy: [CREATED_DATE_TIME_DESC]) {
            nodes {
              computedStatus
              id
              nodeId
            }
          }
        }

        query UnapprovedItemMastersTableOnCosmicHome {
          searchItemMasters(initializationStatus: [null, OPEN], orderBy: [UPDATED_DATE_TIME_DESC, DRAWING_NUMBER_ASC]) {
            nodes {
              ...UnapprovedItemMaster
            }
          }
        }
      `
        });
        this.unapprovedItemMasters = resp.data.searchItemMasters?.nodes || [];
        return resp;
    }
    onInitializationChangeSetCreated() {
        // remove from list
        this.unapprovedItemMasters = this.unapprovedItemMasters.filter((itemMaster) => itemMaster?.nodeId !== this.selectedRowItemMasterNodeId);
        this.selectedUnapprovedItemMaster = null;
    }
    // Called from ui
    onClickOfStartItemMasterInitialization(selectedRow) {
        this.selectedUnapprovedItemMaster = selectedRow;
        this.showItemMasterInitModal = true;
        this.showAhdModal = false;
    }
    // Called from ui
    onClickOfViewItemMasterDetails(selectedRow) {
        this.selectedUnapprovedItemMaster = selectedRow;
        this.showAhdModal = true;
        this.showItemMasterInitModal = false;
    }
    onCloseOfItemMasterInitModal() {
        this.selectedUnapprovedItemMaster = null;
        this.showAhdModal = false;
        this.showItemMasterInitModal = false;
    }
    // fetch change sets of type ITEM_INSTANCE_ON_ORBIT_FAILURE
    async fetchOpenFailureChangeSets() {
        try {
            const resp = await this.fetchOpenFailureChangeSetsQuery();
            if (resp.data.changeSets) {
                this.openFailures = resp.data.changeSets.nodes;
                this.finishedInitialFetchForOpenFailures = true;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
            this.finishedInitialFetchForOpenFailures = true;
        }
    }
    fetchOpenFailureChangeSetsQuery() {
        return this.$apollo.query({
            query: gql `
        ${CosmicItemInstanceSlimFragment}

        fragment ChangeSetForOpenFailuresTable on ChangeSet {
          computedStatus
          createdByUserId
          createdDateTime
          drawingNumber
          id
          itemInstanceId
          openedDate
          nodeId
          payload
          subType

          itemDrawing {
            description
            nodeId
          }

          itemInstance {
            ...CosmicItemInstanceSlim
          }

          status {
            status
          }
        }

        query GetOpenFailureChangeSets($condition: ChangeSetCondition) {
          changeSets(condition: $condition) {
            nodes {
              ...ChangeSetForOpenFailuresTable
            }
          }
        }
      `,
            variables: this.gqlFetchOpenFailureChangeSetsVariables
        });
    }
    fetchData() {
        this.fetchOpenFailureChangeSets();
        Promise.all([
            this.fetchInventoryDueBack(),
            this.fetchPendingInventoryReceipts(),
            this.fetchUnapprovedItemMasters()
        ]).finally(() => (this.finishedInitialFetchForInventoryDashboard = true));
    }
    syncAuthorityData(authority) {
        this.initializationFormData.payload.initializationCode = authority.authorityCode || null;
        this.initializationFormData.payload.initializationAuthorityNumber = authority.authorityNumber;
        this.initializationFormData.payload.initializationComment = authority.comment || null;
    }
};
__decorate([
    Watch('appAuthorityData', { deep: true, immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof IAppAuthority !== "undefined" && IAppAuthority) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], HomeView.prototype, "syncAuthorityData", null);
HomeView = __decorate([
    Component
], HomeView);
export default HomeView;
