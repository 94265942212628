var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
let ChangeSetPayloadDisplayItemMasterRefurbChange = class ChangeSetPayloadDisplayItemMasterRefurbChange extends Vue {
    isFetching = false;
    changeSet;
    get isRefurbishable() {
        const isRefurbishable = this.changeSet?.payload?.isRefurbishable;
        return isNullOrUndefined(isRefurbishable) ? DEFAULT_DASH : isRefurbishable ? 'Refurbishable' : 'Expendable';
    }
    get isRefurbishableOld() {
        const isRefurbishable = this.changeSet?.changeLogs?.nodes
            ? this.changeSet.changeLogs.nodes[0]?.changeDetails.changedFrom.isRefurbishable
            : null;
        return isNullOrUndefined(isRefurbishable) ? DEFAULT_DASH : isRefurbishable ? 'Refurbishable' : 'Expendable';
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemMasterRefurbChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemMasterRefurbChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemMasterRefurbChange);
export default ChangeSetPayloadDisplayItemMasterRefurbChange;
