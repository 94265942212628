var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.isDuplicate ? _c('Alert', {
          staticClass: "shake",
          class: {
            on: _vm.isDuplicate,
            off: !_vm.isDuplicate
          },
          attrs: {
            "type": "error"
          }
        }, [_vm._v(" " + _vm._s(_vm.duplicateAlertText) + " ")]) : _vm._e(), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Name",
            "c-value": _vm.formData.name,
            "clearable": ""
          },
          on: {
            "c-value": function cValue($event) {
              _vm.formData.name = $event.detail;
            }
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-3": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Sequence",
            "required": "",
            "messages": _vm.computedSequenceMessage,
            "error-messages": _vm.computedSequenceErrorMessage
          },
          model: {
            value: _vm.formData.sequence,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-2": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Min qty"
          },
          model: {
            value: _vm.formData.minQuantity,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "minQuantity", $$v);
            },
            expression: "formData.minQuantity"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Max qty"
          },
          model: {
            value: _vm.formData.maxQuantity,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "maxQuantity", $$v);
            },
            expression: "formData.maxQuantity"
          }
        })], 1)], 1)], 1)], 1), _c('AppFormWrapper', {
          attrs: {
            "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
            "size": "1"
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-3": ""
          }
        }, [_c('AppTableActions', {
          attrs: {
            "headers": _vm.computedHardwareListTemplatePartTableHeaders,
            "hide-pagination": _vm.hardwareListTemplatePartTableItems.length < 5,
            "items": _vm.hardwareListTemplatePartTableItems,
            "rows-per-page-items": _vm.rowsPerPageItems,
            "color": _vm.EntityType.ITEM_MASTER,
            "title": "Hardware shopping list",
            "show-count": ""
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('ButtonMiniActionRemoveFromList', {
                on: {
                  "click": function click($event) {
                    return _vm.onClickOfHardwareListTemplatePartTableItem(item);
                  }
                }
              })];
            }
          }])
        })], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('SearchItemDrawingItemMaster', {
          attrs: {
            "rows-per-page-items": _vm.rowsPerPageItems,
            "keep-search-results-open": "",
            "keep-search-value-after-selection": "",
            "output-mode": "object"
          },
          on: {
            "input": _vm.onSelectionOfHardwareListTemplatePart
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonSave', {
          staticClass: "ml-0",
          attrs: {
            "is-saving": _vm.isSaving,
            "disabled": _vm.shouldDisableEditButton
          },
          on: {
            "click": _vm.onClickOfModalHardwareListEditTemplate
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.onClickOfCloseModal
          }
        }), _c('v-spacer')];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };