var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
let ChangeSetDisplayItemInstanceInventoryAssemblyInstall = class ChangeSetDisplayItemInstanceInventoryAssemblyInstall extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get changesForDisplay() {
        // format the value of each change using the appropriate display
        return {
            sequence: this.payload.sequence,
            quantity: this.payload.quantity,
            installedOn: this.$humanize(this.payload.installedOn)
        };
    }
    get toBeInstallHardwareNodeId() {
        return this.selectedEntity && this.selectedEntity.childInventory ? this.selectedEntity.childInventory.nodeId : null;
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : {};
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query SelectChangeSetDisplayItemInstanceInventoryAssemblyInstall($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              subType
              payload
              childInventory {
                nodeId
              }
              inventory {
                nodeId
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    async syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceInventoryAssemblyInstall.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ChangeSetDisplayItemInstanceInventoryAssemblyInstall.prototype, "syncId", null);
ChangeSetDisplayItemInstanceInventoryAssemblyInstall = __decorate([
    Component
], ChangeSetDisplayItemInstanceInventoryAssemblyInstall);
export default ChangeSetDisplayItemInstanceInventoryAssemblyInstall;
