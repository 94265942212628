import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppBlockquote = class AppBlockquote extends Vue {
    align;
    bold;
    center;
    html;
    removeMarginBottom;
    title;
    value;
    get hasDefaultSlot() {
        return this.hasSlot();
    }
    get valueToDisplay() {
        const value = this.hasDefaultSlot ? null : this.html || this.value || '-';
        return this.bold ? `<strong>${value}</strong>` : value;
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
__decorate([
    Prop({
        type: String,
        default: 'left'
    }),
    __metadata("design:type", String)
], AppBlockquote.prototype, "align", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppBlockquote.prototype, "bold", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppBlockquote.prototype, "center", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], AppBlockquote.prototype, "html", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppBlockquote.prototype, "removeMarginBottom", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], AppBlockquote.prototype, "title", void 0);
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], AppBlockquote.prototype, "value", void 0);
AppBlockquote = __decorate([
    Component
], AppBlockquote);
export default AppBlockquote;
