var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
let ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall = class ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall extends Vue {
    isFetching = false;
    changeSet;
    get changeLog() {
        return this.changeSet?.changeLogs?.nodes[0] || null;
    }
    get hasInstalledOn() {
        return this.payload && 'installedOn' in this.payload;
    }
    get hasParentQuantity() {
        return this.payload && 'parentQuantity' in this.payload;
    }
    get hasProjectCode() {
        return (this.changeLog?.changeDetails.changedFrom &&
            'projectCode' in this.changeLog?.changeDetails.changedFrom &&
            this.projectCode !== this.projectCodeOld);
    }
    get hasSequence() {
        return this.payload && 'sequence' in this.payload;
    }
    get hasQuantity() {
        return this.payload && 'quantity' in this.payload;
    }
    get installedOn() {
        if (!this.payload?.installedOn) {
            return null;
        }
        return this.$humanize(this.payload.installedOn);
    }
    get parentQuantity() {
        return this.payload?.parentQuantity || null;
    }
    get projectCode() {
        return this.changeSet?.changeLogs?.nodes
            ? this.changeSet.changeLogs.nodes[0]?.changeDetails.changedTo.projectCode
            : DEFAULT_DASH;
    }
    get projectCodeOld() {
        return this.changeSet?.changeLogs?.nodes
            ? this.changeSet.changeLogs.nodes[0]?.changeDetails.changedFrom.projectCode
            : DEFAULT_DASH;
    }
    get sequence() {
        return this.payload?.sequence || null;
    }
    get quantity() {
        return this.payload?.quantity || null;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall = __decorate([
    Component
], ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall);
export default ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall;
