var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { CosmicLimitedLifeQuotaFragment as CosmicLimitedLifeQuotaFragmentOverride } from '~/nasa_ui/DAL/limitedLife';
import { LimitedLifeCycleField, LimitedLifeCycleFieldDisplay, LimitedLifeIntervalField, LimitedLifeIntervalFieldDisplay } from '~/nasa_ui/types/enums/hardware';
import { reduceIntervalToLargestMeasurement } from '~/nasa_ui/utils/dates/intervals';
let ChangeSetDisplayItemInstanceLimitedLifeChange = class ChangeSetDisplayItemInstanceLimitedLifeChange extends Vue {
    isFetching = false;
    currentLimitedLife = null;
    LimitedLifeCycleField = LimitedLifeCycleField;
    LimitedLifeIntervalField = LimitedLifeIntervalField;
    payloadLimitedLife = null;
    selectedEntity = null;
    id;
    get cycleFields() {
        return Object.values(LimitedLifeCycleField);
    }
    get intervalFields() {
        return Object.values(LimitedLifeIntervalField);
    }
    get modifiedValues() {
        try {
            if (!this.currentLimitedLife || !this.payloadLimitedLife) {
                return null;
            }
            const currentLimitedLife = this.normalizeLimitedLifeObject(this.currentLimitedLife);
            const payloadLimitedLife = this.normalizeLimitedLifeObject(this.payloadLimitedLife);
            const modifiedLimitedLife = {};
            Object.values(LimitedLifeCycleField).forEach((key) => {
                /*
                 * Cycles through `FLIGHT`, `CYCLE`, `EVA`, etc.
                 */
                const currentLl = currentLimitedLife[this.toCamelCase(key)];
                const payloadLl = payloadLimitedLife[this.toCamelCase(key)];
                const modifiedLl = {};
                if (!currentLl && !payloadLl) {
                    return;
                }
                if (currentLl === payloadLl) {
                    return;
                }
                if (currentLl?.value !== payloadLl?.value) {
                    modifiedLl.value = payloadLl.value ? payloadLl.value : null;
                }
                if (currentLl?.service !== payloadLl?.service) {
                    modifiedLl.service = payloadLl.service ? payloadLl.service : null;
                }
                modifiedLimitedLife[this.toCamelCase(key)] = modifiedLl;
            });
            Object.values(LimitedLifeIntervalField).forEach((key) => {
                /*
                 * Cycles through `SHELF`, `USAGE`, `OPERATION`, `PRESSURIZED_TIME`, etc.
                 */
                const currentLl = currentLimitedLife[this.toCamelCase(key)];
                const payloadLl = payloadLimitedLife[this.toCamelCase(key)];
                const modifiedLl = {};
                if (currentLl === payloadLl) {
                    return;
                }
                if (currentLl?.overrideValueDate !== payloadLl?.overrideValueDate) {
                    modifiedLl.overrideValueDate = payloadLl.overrideValueDate;
                }
                if (!this.areIntervalsEqual(currentLl?.value, payloadLl?.value)) {
                    modifiedLl.value = payloadLl.value;
                }
                if (!this.areIntervalsEqual(currentLl?.service, payloadLl?.service)) {
                    modifiedLl.service = payloadLl.service;
                }
                modifiedLimitedLife[this.toCamelCase(key)] = modifiedLl;
            });
            if (payloadLimitedLife.code &&
                JSON.stringify(currentLimitedLife.code) !== JSON.stringify(payloadLimitedLife.code)) {
                modifiedLimitedLife.code = [...payloadLimitedLife.code];
            }
            if (currentLimitedLife.description !== payloadLimitedLife.description) {
                modifiedLimitedLife.description = payloadLimitedLife.description;
            }
            return modifiedLimitedLife;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    get previousValues() {
        if (!this.modifiedValues) {
            return null;
        }
        const previousValues = {};
        // build up previous values for fields that have changed
        Object.keys(this.modifiedValues).forEach((key) => {
            const upperKey = key.toUpperCase();
            if (!this.currentLimitedLife || !this.modifiedValues) {
                return;
            }
            if (Object.values(LimitedLifeIntervalField).includes(upperKey)) {
                previousValues[this.toCamelCase(key)] = this.currentLimitedLife[this.toCamelCase(key)];
            }
            if (key === 'code') {
                previousValues[key] = this.currentLimitedLife[key];
                return;
            }
            if (key === 'description') {
                previousValues[key] = this.currentLimitedLife[key];
                return;
            }
            if ('value' in this.modifiedValues[key]) {
                previousValues[key] = { value: this.currentLimitedLife[key]?.value };
            }
            if ('service' in this.modifiedValues[key]) {
                previousValues[key] = previousValues[key] ? previousValues[key] : { service: null };
                previousValues[key].service = this.currentLimitedLife[key]?.service;
            }
        });
        return previousValues;
    }
    get shouldShowValueAndServiceDiffs() {
        if (this.selectedEntity?.payload.inheritLimitedLifeFromMaster) {
            // exit and don't even compute previousValues because .value will not be there
            // and it will keep erroring
            return false;
        }
        if (!this.previousValues) {
            return false;
        }
        return true;
    }
    areIntervalsEqual(a, b) {
        if (a === null && b === null) {
            return true;
        }
        if ((!a && b) || (a && !b)) {
            return false;
        }
        const partialA = { ...a };
        const partialB = { ...b };
        for (const unit in partialA) {
            if (unit in partialB && partialA[unit] === partialB[unit]) {
                delete partialA[unit];
                delete partialB[unit];
            }
            if (unit in partialA && !(unit in partialB)) {
                delete partialA[unit];
            }
            if (!(unit in partialA) && unit in partialB) {
                delete partialB[unit];
            }
        }
        return JSON.stringify(partialA) === JSON.stringify(partialB);
    }
    toCamelCase(word) {
        return word.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }
    capitalizeWord(word) {
        let multipleWords = [];
        word = word.toLowerCase();
        if (word.includes('_')) {
            multipleWords = word.split('_');
        }
        const _capitalize = (word) => word.substring(0, 1).toUpperCase() + word.substring(1);
        if (multipleWords.length > 1) {
            const sentence = [];
            for (let word of multipleWords) {
                word = _capitalize(word);
                sentence.push(word);
            }
            return sentence.join(' ');
        }
        return _capitalize(word);
    }
    codeDisplay(code) {
        if (!code || code.length === 0) {
            return null;
        }
        return code.join(', ');
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicLimitedLifeQuotaFragmentOverride}

          query SelectChangeSetForItemInstanceLimitedLifeChange($id: Int!) {
            changeSetById(id: $id) {
              id
              itemInstance {
                limitedLife {
                  ...CosmicLimitedLifeQuota
                }
                nodeId
              }
              nodeId
              payload
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
            this.isFetching = false;
        }
    }
    getLabelForCurrentValue(field, key) {
        if (!this.previousValues) {
            return null;
        }
        if (!(this.toCamelCase(`${field}`) in this.previousValues)) {
            return;
        }
        let displayName = '';
        if (LimitedLifeIntervalFieldDisplay.has(field)) {
            displayName = LimitedLifeIntervalFieldDisplay.get(field) || '';
        }
        else if (LimitedLifeCycleFieldDisplay.has(field)) {
            displayName = LimitedLifeCycleFieldDisplay.get(field) || '';
        }
        else {
            displayName = this.capitalizeWord(`${field}`);
        }
        key = this.capitalizeWord(key);
        return `${displayName} ${key}`;
    }
    getValue(values, field, key) {
        if (!values || !values[field]) {
            return null;
        }
        let value = values[field]; // Object is possibly null/undefined !? idk how but...
        if (!value) {
            return null;
        }
        if (field === 'code' && Array.isArray(value)) {
            return value.join(', ');
        }
        value = value[key];
        if (value === null) {
            return null;
        }
        if (typeof value === 'object') {
            const reducedInterval = reduceIntervalToLargestMeasurement(value);
            const entry = Object.entries(reducedInterval).find(([unit, num]) => num !== null);
            if (entry) {
                const [unit, num] = entry;
                return `${num} ${unit}`;
            }
        }
        return value;
    }
    normalizeLimitedLifeObject(limitedLife) {
        Object.values(LimitedLifeIntervalField).forEach((key) => {
            if (limitedLife[key] === null) {
                limitedLife[key] = {
                    value: null,
                    service: null
                };
            }
        });
        Object.values(LimitedLifeCycleField).forEach((key) => {
            if (limitedLife[key] === null) {
                limitedLife[key] = {
                    value: null,
                    service: null
                };
            }
        });
        return limitedLife;
    }
    async syncId(id) {
        if (!id) {
            return;
        }
        await this.fetchEntity(id);
        if (!this.selectedEntity) {
            return;
        }
        this.payloadLimitedLife = this.selectedEntity.payload && this.selectedEntity.payload.limitedLife;
        this.currentLimitedLife =
            (this.selectedEntity.itemInstance && this.selectedEntity.itemInstance.limitedLife) || null;
    }
};
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceLimitedLifeChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ChangeSetDisplayItemInstanceLimitedLifeChange.prototype, "syncId", null);
ChangeSetDisplayItemInstanceLimitedLifeChange = __decorate([
    Component
], ChangeSetDisplayItemInstanceLimitedLifeChange);
export default ChangeSetDisplayItemInstanceLimitedLifeChange;
