var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { CosmicItemMasterSlimFragment } from '~/nasa_ui/DAL/itemMaster/slim';
let ChangeSetDisplayItemMasterInventoryMethodChange = class ChangeSetDisplayItemMasterInventoryMethodChange extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get currentInventoryMethod() {
        if (!this.selectedEntity || !this.selectedEntity.itemMaster) {
            return null;
        }
        return {
            inventoryControl: this.selectedEntity.itemMaster.inventoryControl,
            inventoryMethod: this.selectedEntity.itemMaster.inventoryMethod,
            inventoryUnitType: this.selectedEntity.itemMaster.inventoryUnitType
        };
    }
    get currentValue() {
        if (!this.currentInventoryMethod) {
            return {};
        }
        const keys = this.changes ? Object.keys(this.changes) : [];
        return Object.keys(this.currentInventoryMethod).reduce((out, key) => {
            if (!this.currentInventoryMethod) {
                return out;
            }
            if (keys && keys.includes(key)) {
                out[key] = this.currentInventoryMethod[key];
            }
            return out;
        }, {});
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : null;
    }
    get changes() {
        if (!this.currentInventoryMethod || !this.payload) {
            return null;
        }
        return Object.keys(this.payload).reduce((diff, key) => {
            if (!this.currentInventoryMethod) {
                return diff;
            }
            const payload = this.payload ? this.payload : {};
            if (this.currentInventoryMethod[key] === payload[key]) {
                return diff;
            }
            return {
                ...diff,
                [key]: payload[key]
            };
        }, {});
    }
    formatTitle(title) {
        return this.$humanize(title);
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemMasterSlimFragment}

          query SelectChangeSetForItemMasterInventoryMethodChange($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              payload
              itemMaster {
                ...CosmicItemMasterSlim
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemMasterInventoryMethodChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemMasterInventoryMethodChange.prototype, "syncId", null);
ChangeSetDisplayItemMasterInventoryMethodChange = __decorate([
    Component
], ChangeSetDisplayItemMasterInventoryMethodChange);
export default ChangeSetDisplayItemMasterInventoryMethodChange;
