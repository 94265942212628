import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { ITEM_NUMBER_COL, QUANTITY_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { HeaderSortOptions } from '~/nasa_ui/types';
import BaseDueInInventoryTable from '../BaseDueInInventoryTable';
let OpenPurchaseRequestsTable = class OpenPurchaseRequestsTable extends BaseDueInInventoryTable {
    selectedRow = null;
    get link() {
        if (!this.selectedRow || !this.selectedRow.inventoryId || !this.selectedRow.prCreatedByOrg) {
            return '';
        }
        return `/hardware/purchase_request_receipt?inventoryId=${this.selectedRow.inventoryId}&context=${this.selectedRow.prCreatedByOrg}`;
    }
    get computedTableHeaders() {
        return [
            ITEM_NUMBER_COL,
            QUANTITY_COL,
            {
                text: 'Purchase request',
                value: '_prDateDisplay',
                sortable: true,
                headerSortType: HeaderSortOptions.DATE
            },
            {
                text: 'Created by Org',
                value: 'prCreatedByOrg',
                sortable: true
            },
            {
                text: 'Estimated receipt',
                value: '_estimatedReceiptDate',
                sortable: true,
                headerSortType: HeaderSortOptions.DATE
            }
        ];
    }
    get selectedRowMatchesContext() {
        return this.selectedRow && this.selectedRow.prCreatedByOrg === this.currentUserActiveSupportContext;
    }
};
OpenPurchaseRequestsTable = __decorate([
    Component
], OpenPurchaseRequestsTable);
export default OpenPurchaseRequestsTable;
