function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: _defineProperty(_defineProperty(_defineProperty({}, _vm.color, _vm.color), "hasNoActions", !_vm.hasAnyActions), 'no-entity-icon', !_vm.showEntityTypeIcon)
  }, [_vm.showEntityTypeIcon ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_vm._v(_vm._s(_vm.name))]), _vm.shouldShowSubText ? _c('small', {
    staticClass: "caption pl-1",
    attrs: {
      "title": _vm.subNameText
    }
  }, [_vm._v(_vm._s(_vm.subNameText))]) : _vm._e()]), _c('div', {
    staticClass: "vertical-display-items white--text",
    class: {
      is_archived: _vm.isArchived
    }
  }, [_vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style,
      attrs: {
        "title": verticalDisplayItem.title || verticalDisplayItem.value || ''
      }
    }, [_c('div', {
      staticClass: "mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  }), _vm.initializationStatusIconDisplay ? _c('div', {
    staticClass: "vertical-display-item init_status white--text"
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_vm._v("Init status")]), _c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.initializationStatusIconDisplay,
      expression: "initializationStatusIconDisplay"
    }]
  })]) : _vm._e(), _vm.isArchived ? _c('div', {
    staticClass: "vertical-display-item text-xs-center"
  }, [_c('c-icon-fa', {
    attrs: {
      "tooltip": "This Item Drawing has been archived.",
      "icon-color": "white",
      "icon": _vm.$icons.archived
    }
  })], 1) : _vm._e()], 2), _c('div', {
    staticClass: "mini_actions"
  }, [_vm.localEntity ? _c('ButtonMiniAction', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons['eye']
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showAhd = !_vm.showAhd;
      }
    }
  }) : _vm._e(), _vm.shouldShowManageLink ? _c('ButtonMiniAction', {
    staticClass: "mr-2",
    attrs: {
      "href": _vm.manageLink,
      "icon": _vm.manageIcon,
      "tooltip": _vm.manageLinkTitle,
      "is-link": ""
    }
  }) : _vm._e(), _vm.shouldShowInlineClearIcon ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.inlineClearIcon
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showAhd && _vm.localEntity ? _c('AppFormWrapper', {
    attrs: {
      "color": _vm.entityType,
      "bottom": "1",
      "color-modifier": "",
      "left": "1",
      "right": "1",
      "top": "0"
    }
  }, [_c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.localEntity.nodeId,
      "hide-border-bottom": ""
    }
  })], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "center": "",
      "color": _vm.EntityType.ITEM_DRAWING,
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };