var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isFetching ? _c('v-layout', [_vm.hasFederalSupplyClass ? _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [!_vm.isFetching ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Federal Supply Class",
      "newVal": _vm.federalSupplyClass,
      "oldVal": _vm.federalSupplyClassOld
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.hasPropertyClass ? _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [!_vm.isFetching ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Property Class",
      "newVal": _vm.propertyClass,
      "oldVal": _vm.propertyClassOld
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };