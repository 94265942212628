var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isFetching ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Limited Life Quotas"
    }
  }, [_vm._l(_vm.limitedLifeIntervals, function (interval, index) {
    return _c('div', {
      key: interval.heading
    }, [interval.value || interval.service ? _c('div', {
      staticClass: "mb-2"
    }, [_c('strong', [_vm._v(_vm._s(interval.heading))]), index !== 0 ? _c('br') : _vm._e(), _c('ul', [interval.value ? _c('li', [_vm._v(_vm._s(interval.valueLabel) + ": " + _vm._s(interval.value))]) : _vm._e(), interval.service ? _c('li', [_vm._v(_vm._s(interval.serviceLabel) + ": " + _vm._s(interval.service))]) : _vm._e()])]) : _vm._e()]);
  }), _vm._l(_vm.limitedLifeCycles, function (cycle) {
    return _c('div', {
      key: cycle.heading
    }, [cycle.value || cycle.service ? _c('div', {
      staticClass: "mb-2"
    }, [_c('strong', [_vm._v(_vm._s(cycle.heading))]), _c('br'), _c('ul', [cycle.value ? _c('li', [_vm._v(_vm._s(cycle.valueLabel) + ": " + _vm._s(cycle.value))]) : _vm._e(), cycle.service ? _c('li', [_vm._v(_vm._s(cycle.serviceLabel) + ": " + _vm._s(cycle.service))]) : _vm._e()])]) : _vm._e()]);
  }), !_vm.doLimitedLifeIntervalsContainValues ? _c('div', [_vm._v(" - ")]) : _vm._e()], 2)], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "html": _vm.limitedLifeCodes,
      "title": "Limited Life Codes"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "html": _vm.description,
      "title": "Description"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };