var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
let ChangeSetDisplayItemInstanceSerialOrLotNumberChange = class ChangeSetDisplayItemInstanceSerialOrLotNumberChange extends Vue {
    isFetching = false;
    selectedEntity = null;
    id;
    get currentValues() {
        if (!this.selectedEntity) {
            return null;
        }
        return this.selectedEntity.itemInstance;
    }
    get payloadValues() {
        if (!this.selectedEntity) {
            return null;
        }
        return this.selectedEntity.payload;
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemInstanceSlimFragment}

          query SelectChangeSetForSerialOrLotNumberChange($id: Int!) {
            changeSetById(id: $id) {
              id
              itemInstance {
                ...CosmicItemInstanceSlim
              }
              nodeId
              payload
            }
          }
        `,
                variables: {
                    id
                }
            });
            this.isFetching = false;
            if (resp && resp.data && resp.data.changeSetById) {
                this.selectedEntity = resp.data.changeSetById;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    getLabel(key) {
        if (key === 'lotNumber') {
            return 'Lot';
        }
        if (key === 'serialNumber') {
            return 'Serial';
        }
        return key.substring(0, 1).toUpperCase() + key.substring(1);
    }
    syncId(id) {
        if (!id) {
            return;
        }
        this.fetchEntity(id);
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceSerialOrLotNumberChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemInstanceSerialOrLotNumberChange.prototype, "syncId", null);
ChangeSetDisplayItemInstanceSerialOrLotNumberChange = __decorate([
    Component
], ChangeSetDisplayItemInstanceSerialOrLotNumberChange);
export default ChangeSetDisplayItemInstanceSerialOrLotNumberChange;
