var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types/enums/item-instance';
let ChangeSetDisplayItemInstanceInventoryClassChange = class ChangeSetDisplayItemInstanceInventoryClassChange extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get itemInstance() {
        return this.selectedEntity && this.selectedEntity.itemInstance ? this.selectedEntity.itemInstance : null;
    }
    get currentValuesForDisplay() {
        // format the value using the appropriate display
        const inventoryItemClass = this.itemInstance && this.itemInstance.subType ? this.itemInstance.subType : null;
        return {
            subType: inventoryItemClass ? ItemInstanceInventoryTypeDisplay.get(inventoryItemClass) : null
        };
    }
    get changesForDisplay() {
        // format the value of each change using the appropriate display
        return {
            subType: ItemInstanceInventoryTypeDisplay.get(this.payload.subType)
        };
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : {};
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query SelectChangeSetForItemInstanceInventoryClassChange($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              subType
              payload
              itemInstance {
                nodeId
                subType
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceInventoryClassChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemInstanceInventoryClassChange.prototype, "syncId", null);
ChangeSetDisplayItemInstanceInventoryClassChange = __decorate([
    Component
], ChangeSetDisplayItemInstanceInventoryClassChange);
export default ChangeSetDisplayItemInstanceInventoryClassChange;
