import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ChangeSetDisplayItemDrawingValidSizesChange from '~/components/ChangeSetDisplayItemDrawingValidSizesChange/ChangeSetDisplayItemDrawingValidSizesChange.vue';
import ChangeSetDisplayItemInstanceConfigurationChange from '~/components/ChangeSetDisplayItemInstanceConfigurationChange/ChangeSetDisplayItemInstanceConfigurationChange.vue';
import ChangeSetDisplayItemInstanceInventoryAssemblyInstall from '~/components/ChangeSetDisplayItemInstanceInventoryAssemblyInstall/ChangeSetDisplayItemInstanceInventoryAssemblyInstall.vue';
import ChangeSetDisplayItemInstanceInventoryAssemblyRemove from '~/components/ChangeSetDisplayItemInstanceInventoryAssemblyRemove/ChangeSetDisplayItemInstanceInventoryAssemblyRemove.vue';
import ChangeSetDisplayItemInstanceInventoryClassChange from '~/components/ChangeSetDisplayItemInstanceInventoryClassChange/ChangeSetDisplayItemInstanceInventoryClassChange.vue';
import ChangeSetDisplayItemInstanceInventoryCostChange from '~/components/ChangeSetDisplayItemInstanceInventoryCostChange/ChangeSetDisplayItemInstanceInventoryCostChange.vue';
import ChangeSetDisplayItemInstanceInventoryProjectCodeChange from '~/components/ChangeSetDisplayItemInstanceInventoryProjectCodeChange/ChangeSetDisplayItemInstanceInventoryProjectCodeChange.vue';
import ChangeSetDisplayItemInstanceLimitedLifeChange from '~/components/ChangeSetDisplayItemInstanceLimitedLifeChange/ChangeSetDisplayItemInstanceLimitedLifeChange.vue';
import ChangeSetDisplayItemInstanceOnOrbitFailure from '~/components/ChangeSetDisplayItemInstanceOnOrbitFailure/ChangeSetDisplayItemInstanceOnOrbitFailure.vue';
import ChangeSetDisplayItemInstanceSerialOrLotNumberChange from '~/components/ChangeSetDisplayItemInstanceSerialOrLotNumberChange/ChangeSetDisplayItemInstanceSerialOrLotNumberChange.vue';
import ChangeSetDisplayItemMasterEquipmentGroupChange from '~/components/ChangeSetDisplayItemMasterEquipmentGroupChange/ChangeSetDisplayItemMasterEquipmentGroupChange.vue';
import ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange from '~/components/ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange/ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange.vue';
import ChangeSetDisplayItemMasterInitialization from '~/components/ChangeSetDisplayItemMasterInitialization/ChangeSetDisplayItemMasterInitialization.vue';
import ChangeSetDisplayItemMasterInventoryMethodChange from '~/components/ChangeSetDisplayItemMasterInventoryMethodChange/ChangeSetDisplayItemMasterInventoryMethodChange.vue';
import ChangeSetDisplayItemMasterLimitedLifeChange from '~/components/ChangeSetDisplayItemMasterLimitedLifeChange/ChangeSetDisplayItemMasterLimitedLifeChange.vue';
import ChangeSetDisplayItemMasterRefurbChange from '~/components/ChangeSetDisplayItemMasterRefurbChange/ChangeSetDisplayItemMasterRefurbChange.vue';
import ChangeSetPayloadDisplayItemDrawingValidSizesChange from '~/components/ChangeSetPayloadDisplayItemDrawingValidSizesChange/ChangeSetPayloadDisplayItemDrawingValidSizesChange.vue';
import ChangeSetPayloadDisplayItemInstanceConfigurationChange from '~/components/ChangeSetPayloadDisplayItemInstanceConfigurationChange/ChangeSetPayloadDisplayItemInstanceConfigurationChange.vue';
import ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall from '~/components/ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall/ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall.vue';
import ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove from '~/components/ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove/ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove.vue';
import ChangeSetPayloadDisplayItemInstanceInventoryClassChange from '~/components/ChangeSetPayloadDisplayItemInstanceInventoryClassChange/ChangeSetPayloadDisplayItemInstanceInventoryClassChange.vue';
import ChangeSetPayloadDisplayItemInstanceInventoryCostChange from '~/components/ChangeSetPayloadDisplayItemInstanceInventoryCostChange/ChangeSetPayloadDisplayItemInstanceInventoryCostChange.vue';
import ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange from '~/components/ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange/ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange.vue';
import ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange from '~/components/ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange/ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange.vue';
import ChangeSetPayloadDisplayItemMasterEquipmentGroupChange from '~/components/ChangeSetPayloadDisplayItemMasterEquipmentGroupChange/ChangeSetPayloadDisplayItemMasterEquipmentGroupChange.vue';
import ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange from '~/components/ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange/ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange.vue';
import ChangeSetPayloadDisplayItemMasterInitialization from '~/components/ChangeSetPayloadDisplayItemMasterInitialization/ChangeSetPayloadDisplayItemMasterInitialization.vue';
import ChangeSetPayloadDisplayItemMasterInventoryMethodChange from '~/components/ChangeSetPayloadDisplayItemMasterInventoryMethodChange/ChangeSetPayloadDisplayItemMasterInventoryMethodChange.vue';
import ChangeSetPayloadDisplayItemMasterRefurbChange from '~/components/ChangeSetPayloadDisplayItemMasterRefurbChange/ChangeSetPayloadDisplayItemMasterRefurbChange.vue';
import ChangeSetPayloadDisplayLimitedLifeChange from '~/components/ChangeSetPayloadDisplayLimitedLifeChange/ChangeSetPayloadDisplayLimitedLifeChange.vue';
import { ChangeSetStatus, ChangeSetType } from '~/db_types';
import { AppDynamicWrapperType } from '~/nasa_ui/components/AppDynamicWrapper/AppDynamicWrapper';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicChangeSetStatusFragment } from '~/nasa_ui/DAL/changeSet';
import { CosmicChangeSetSlimFragment } from '~/nasa_ui/DAL/changeSet/slim';
import { CosmicCommentFragment } from '~/nasa_ui/DAL/comment';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { EntityType } from '~/nasa_ui/types';
import { dateDisplay } from '~/nasa_ui/utils/dates/dateDisplay';
import { changeSetDisplayBasedOnType, changeSetPayloadBasedOnType } from '~/nasa_ui/utils/helpers/changeSetHelper';
import { booleanIconDisplay } from '~/nasa_ui/utils/helpers/displays';
import { ClosedLineItemTableHeaders, CommentTableHeaders, OpenLineItemTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformChangeSets, transformComments, transformItemInstances } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ReadOnlyChangeSet = class ReadOnlyChangeSet extends Mixins(CurrentUserMixin) {
    adminUsers = null;
    alertText = [];
    AppDynamicWrapperType = AppDynamicWrapperType;
    CommentTableHeaders = CommentTableHeaders;
    isFetching = false;
    queryResults = null;
    transformedEntity = null;
    userIsNotAuthorized = false;
    // used directly in template
    changeSetDisplayBasedOnType = changeSetDisplayBasedOnType;
    changeSetPayloadBasedOnType = changeSetPayloadBasedOnType;
    hideAhd;
    hideMini;
    hideReportSection;
    id;
    get allAuthorities() {
        return this.changeSetAuthorities.map((authority) => {
            if (!authority) {
                return {};
            }
            const nodeId = authority.nodeId;
            const id = authority.id;
            const template = authority.template;
            const status = authority.status;
            const groupName = template?.userGroup?.name || DEFAULT_DASH;
            const _requiresQualityControlStampIcon = this.$booleanIconDisplay(template?.requiresQualityControlStamp);
            const _requiresCommentIcon = this.$booleanIconDisplay(template?.requiresComment);
            const _statusIcon = this.getStatusIcon(status);
            return Object.assign({}, template, {
                id,
                nodeId,
                status,
                _statusIcon,
                groupName,
                _requiresQualityControlStampIcon,
                _requiresCommentIcon
            });
        });
    }
    get allAuthHeaders() {
        return [
            {
                text: 'Group name',
                value: 'groupName'
            },
            {
                text: 'Group code',
                value: 'groupCode'
            },
            {
                text: 'Requires<br/>QC stamp',
                value: '_requiresQualityControlStampIcon',
                noEscape: true,
                align: 'center'
            },
            {
                text: 'Requires<br/>Comment',
                value: '_requiresCommentIcon',
                noEscape: true,
                align: 'center'
            },
            {
                text: 'Status',
                value: '_statusIcon',
                noEscape: true,
                align: 'center'
            }
        ];
    }
    get changeSetAuthorities() {
        return this.entity && this.entity.changeSetAuthorities ? this.entity.changeSetAuthorities.nodes : [];
    }
    get changeSetType() {
        return this.entity ? this.entity.subType : null;
    }
    get closedDateDisplay() {
        return this.entity && this.entity?.status?.closedDate
            ? this.$dateTimeDisplay(this.entity.status.closedDate)
            : DEFAULT_DASH;
    }
    get closedLineItems() {
        if (!this.entity || !this.entity?.document?.changeSets) {
            return [];
        }
        return transformItemInstances(this.entity?.document?.changeSets?.nodes
            .filter((cs) => {
            if (!cs || !cs.status) {
                return false;
            }
            return cs.status.status === ChangeSetStatus.APPROVED;
        })
            .map((cs) => {
            if (!cs || !cs.itemInstance || !cs.status) {
                return {};
            }
            return {
                itemInstance: { ...cs.itemInstance },
                ...cs.status,
                _openedDate: dateDisplay(cs.status.openedDate),
                _closedDate: dateDisplay(cs.status.closedDate),
                ...transformItemInstances([cs.itemInstance])[0]
            };
        }));
    }
    get closedLineItemTableHeaders() {
        return [...ClosedLineItemTableHeaders];
    }
    get computedComments() {
        return this.entity && this.entity?.comments && this.entity?.comments?.nodes
            ? transformComments(this.entity?.comments?.nodes)
            : [];
    }
    get correspondingChangeSetDisplay() {
        if (this.entity) {
            if (this.isClosed) {
                return 'ChangeSetPayloadDisplay' + startCase(camelCase(this.entity.subType)).replace(/ /g, '');
            }
            else {
                return 'ChangeSetDisplay' + startCase(camelCase(this.entity.subType)).replace(/ /g, '');
            }
        }
    }
    get createdDateDisplay() {
        return this.entity && this.entity.createdDateTime
            ? this.$dateTimeDisplay(this.entity.createdDateTime)
            : DEFAULT_DASH;
    }
    get documentLineItemType() {
        return this.entity ? `${this.entity?.document?.subType}_LINE_ITEM` : null;
    }
    get emailLinkToSend() {
        return `mailto:${this.usersToEmail.join(';')}?subject=COSMIC%3A%20A%20change%20set%20requires%20attention&body=
      ATTN USER_ADMIN:%0D%0A%0D%0AYou%20are%20receiving%20this%20because%20you%20are%20a%20member%20of%20the%20group%20
      USER_ADMIN.%0D%0A%0D%0AA%20COSMIC%20change%20set%20requires%20someone's%20attention.%0D%0A%0D%0A${window.location.href}%0D%0A%0D%0A`;
    }
    get entity() {
        return this.queryResults?.changeSetById;
    }
    get hardwareId() {
        if (!this.entity) {
            return null;
        }
        // return the most specific nodeId
        const mostSpecificEntity = this.entity.inventory
            ? this.entity.inventory
            : this.entity.itemInstance
                ? this.entity.itemInstance
                : this.entity.itemMaster
                    ? this.entity.itemMaster
                    : this.entity.itemDrawing;
        return mostSpecificEntity && mostSpecificEntity.hasOwnProperty('nodeId') ? mostSpecificEntity.nodeId : DEFAULT_DASH;
    }
    get hasNoLineItems() {
        if (!this.entity?.document) {
            return true;
        }
        return this.entity?.document.changeSets.nodes.length < 1;
    }
    get isChangeSetClosed() {
        return Boolean(this.entity?.status?.closedByUserId);
    }
    get itemInstanceFormattedForLinks() {
        if (!this.entity) {
            return;
        }
        return {
            asBuilt: this.entity?.asBuiltNumber,
            id: this.entity?.itemInstanceId,
            drawingNumber: this.entity?.drawingNumber,
            side: this.entity?.side
        };
    }
    get isClosed() {
        return Boolean(this.queryResults?.changeSetById?.status?.closedByUserId);
    }
    get isItemMasterInitialization() {
        return this.entity?.subType === ChangeSetType.ITEM_MASTER_INITIALIZATION;
    }
    get isSubTypeDocument() {
        return this.entity && this.entity.subType.endsWith('LINE_ITEM');
    }
    get openLineItems() {
        if (!this.entity || !this.entity?.document?.changeSets) {
            return [];
        }
        return transformItemInstances(this.entity?.document?.changeSets?.nodes
            .filter((cs) => {
            if (!cs || !cs.status) {
                return false;
            }
            return cs.status.status === ChangeSetStatus.OPEN;
        })
            .map((cs) => {
            if (!cs || !cs.itemInstance || !cs.status) {
                return {};
            }
            const hasMovement = booleanIconDisplay(Boolean(cs.changeSetInventoryTransaction?.nodeId));
            const changeSetAuthority = cs.changeSetAuthorities.nodes.slice(-1);
            return {
                changeSetAuthorityNodeId: changeSetAuthority.length ? changeSetAuthority[0]?.nodeId : null,
                projectedClosureDate: cs.projectedDate,
                hasMovement,
                _projectedDate: dateDisplay(cs.projectedDate),
                _openedDate: dateDisplay(cs.status.openedDate),
                ...transformItemInstances([cs.itemInstance])[0],
                ...cs.status
            };
        }));
    }
    get openLineItemTableHeaders() {
        return [...OpenLineItemTableHeaders];
    }
    get pipelineDisplay() {
        if (this.entity &&
            this.entity.computedStatus === ChangeSetStatus.APPROVED &&
            this.changeSetAuthorities.length === 0) {
            return [
                {
                    status: 'approved',
                    tooltip: 'Auto-approved.',
                    icon: 'pipeline_approved'
                }
            ];
        }
        return this.changeSetAuthorities.map((csAuth) => {
            const status = csAuth && csAuth.status ? csAuth.status.toLowerCase() : 'open';
            return {
                status,
                icon: `pipeline_${status}`
            };
        });
    }
    get projectedDateDisplay() {
        return this.entity && this.entity.projectedDate ? this.$dateTimeDisplay(this.entity.projectedDate) : DEFAULT_DASH;
    }
    get rowsPerPageItems() {
        return [
            50,
            100,
            500,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get shouldShowPendingChanges() {
        if (!this.entity || !this.entity.status || !this.entity.status.status) {
            return;
        }
        return this.entity.status.status === ChangeSetStatus.OPEN;
    }
    get shouldShowChangeSetDisplay() {
        return this.changeSetType && this.correspondingChangeSetDisplay !== changeSetPayloadBasedOnType(this.changeSetType);
    }
    get subTypeDisplay() {
        return this.entity ? this.$typeDisplay(EntityType.CHANGE_SET, this.entity.subType) : DEFAULT_DASH;
    }
    get userNamesTitleForEmailButton() {
        return `Send an email to
    ${this.userNamesToDisplay}
    `;
    }
    get userNamesToDisplay() {
        if (!this.adminUsers) {
            return [];
        }
        // Sort by last name descending
        const sorted = this.adminUsers.sort((a, b) => {
            return ('' + a.user?.lastName).localeCompare(b.user?.lastName);
        });
        // Pluck just the names
        const nameStrings = sorted.map((user) => {
            if (user?.firstName || user?.lastName) {
                return `${user?.firstName || ''} ${user?.lastName || ''}`;
            }
            return '';
        });
        //filter out any value that is empty
        const filteredNameStrings = nameStrings.filter(Boolean);
        // Join like this so everything lines up nicely
        return filteredNameStrings.join(``);
    }
    get usersToEmail() {
        return this.adminUsers ? this.adminUsers?.map((user) => user?.email) : [];
    }
    async fetchEntity() {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicUserSlimFragment}
          ${CosmicChangeSetStatusFragment}
          ${CosmicChangeSetSlimFragment}
          ${CosmicCommentFragment}
          ${CosmicItemInstanceSlimFragment}

          fragment userGroupOnChangeSetsReadOnly on UserGroup {
            nodeId
            name
            usersToUserGroups {
              nodes {
                nodeId # nodeId of userTouserGroup and not the users nodeId
                user {
                  ...CosmicUserSlim
                }
              }
            }
          }

          query SelectChangeSetForReadOnly($id: Int!) {
            changeSetById(id: $id) {
              ...CosmicChangeSetSlim

              comments {
                nodes {
                  ...CosmicComment
                }
              }
              createdByUser {
                ...CosmicUserSlim
              }
              document {
                id
                nodeId
                subType
                overrideCloseDate
                changeSets {
                  nodes {
                    nodeId
                    id
                    projectedDate
                    subType
                    changeSetAuthorities {
                      nodes {
                        nodeId
                      }
                    }
                    changeSetInventoryTransaction {
                      nodeId
                    }
                    itemInstance {
                      ...CosmicItemInstanceSlim
                    }
                    status {
                      ...CosmicChangeSetStatus
                    }
                  }
                }
              }
              inventory {
                nodeId
              }
              itemInstance {
                nodeId
              }
              itemMaster {
                nodeId
              }
              itemDrawing {
                nodeId
              }
              changeLogs {
                nodes {
                  nodeId
                  changeDetails
                }
              }
              changeSetAuthorities {
                nodes {
                  authorizedByUserId
                  authorizedDate
                  computedCommentText
                  createdDateTime
                  id
                  nodeId
                  qualityControlStamp
                  status
                  subType
                  templateId
                  template {
                    nodeId
                    groupCode
                    requiresComment
                    requiresQualityControlStamp
                    userGroup {
                      ...userGroupOnChangeSetsReadOnly
                    }
                  }
                }
              }
              status {
                ...CosmicChangeSetStatus
              }
            }
          }
        `,
                variables: {
                    id: this.id
                },
                fetchPolicy: 'network-only'
            });
            this.queryResults = resp.data || null;
            this.isFetching = false;
        }
        catch (err) {
            this.$errorUtility({
                backupErrorText: 'Could not fetch change set authorities.',
                err
            });
            this.isFetching = false;
        }
    }
    async getAdminUsers() {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query getUserAdmins {
            getUserAdmins {
              nodes {
                auid
                email
                firstName
                lastName
                middleName
                nodeId
              }
            }
          }
        `
            });
            this.adminUsers = resp.data.getUserAdmins?.nodes || [];
        }
        catch (err) {
            this.$errorUtility({ err });
            throw err;
        }
    }
    getStatusIcon(status) {
        if (status === ChangeSetStatus.OPEN) {
            return '<i class="fa-2x fa fa-circle-notch"></i>';
        }
        else if (status === ChangeSetStatus.APPROVED) {
            return '<i class="fa-2x fad fa-check-circle success--text"></i>';
        }
        else if (status === ChangeSetStatus.REJECTED) {
            return '<i class="fa-2x fas fa-ban error--text"></i>';
        }
        else {
            return '<i class="fa-2x fa fa-circle-notch"></i>';
        }
    }
    onNodeIdUpdate() {
        this.fetchEntity();
    }
    onEntityChange() {
        this.getAdminUsers();
        this.transformedEntity = transformChangeSets([this.entity])[0];
        const changeSetAuthorities = this.entity?.changeSetAuthorities.nodes;
        const csApprovedGroups = changeSetAuthorities.map((node) => node?.template?.groupCode);
        const userAdminGroups = this.currentUserGroups?.map((ug) => ug.code);
        if (this.isChangeSetClosed) {
            this.alertText.push(`<p>You are viewing the Read Only Change Set page because this change set has been closed.`);
        }
        const matchedGroups = csApprovedGroups?.filter((group) => userAdminGroups?.includes(group));
        // if the currentUser is not in any of the approved ChangeSet groups, forward to ReadOnlyChangeSet
        if (!matchedGroups.length && !this.isChangeSetClosed) {
            this.alertText.push(`<p>You are not authorized to take action on this Change Set.</p><p> You can click the button below to email the <span class="font-weight-bold">USER_ADMINS</span> to request permission to take action.</p>`);
            this.userIsNotAuthorized = true;
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReadOnlyChangeSet.prototype, "hideAhd", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReadOnlyChangeSet.prototype, "hideMini", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReadOnlyChangeSet.prototype, "hideReportSection", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ReadOnlyChangeSet.prototype, "id", void 0);
__decorate([
    Watch('nodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ReadOnlyChangeSet.prototype, "onNodeIdUpdate", null);
__decorate([
    Watch('entity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ReadOnlyChangeSet.prototype, "onEntityChange", null);
ReadOnlyChangeSet = __decorate([
    Component({
        components: {
            ChangeSetDisplayItemDrawingValidSizesChange,
            ChangeSetDisplayItemInstanceConfigurationChange,
            ChangeSetDisplayItemInstanceInventoryAssemblyInstall,
            ChangeSetDisplayItemInstanceInventoryAssemblyRemove,
            ChangeSetDisplayItemInstanceInventoryClassChange,
            ChangeSetDisplayItemInstanceInventoryCostChange,
            ChangeSetDisplayItemInstanceInventoryProjectCodeChange,
            ChangeSetDisplayItemInstanceLimitedLifeChange,
            ChangeSetDisplayItemInstanceOnOrbitFailure,
            ChangeSetDisplayItemInstanceSerialOrLotNumberChange,
            ChangeSetDisplayItemMasterEquipmentGroupChange,
            ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange,
            ChangeSetDisplayItemMasterInitialization,
            ChangeSetDisplayItemMasterInventoryMethodChange,
            ChangeSetDisplayItemMasterLimitedLifeChange,
            ChangeSetDisplayItemMasterRefurbChange,
            ChangeSetPayloadDisplayItemDrawingValidSizesChange,
            ChangeSetPayloadDisplayItemInstanceConfigurationChange,
            ChangeSetPayloadDisplayItemInstanceInventoryAssemblyInstall,
            ChangeSetPayloadDisplayItemInstanceInventoryAssemblyRemove,
            ChangeSetPayloadDisplayItemInstanceInventoryClassChange,
            ChangeSetPayloadDisplayItemInstanceInventoryCostChange,
            ChangeSetPayloadDisplayItemInstanceInventoryProjectCodeChange,
            ChangeSetPayloadDisplayItemInstanceSerialOrLotNumberChange,
            ChangeSetPayloadDisplayItemMasterEquipmentGroupChange,
            ChangeSetPayloadDisplayItemMasterFederalSupplyOrPropertyClassChange,
            ChangeSetPayloadDisplayItemMasterInitialization,
            ChangeSetPayloadDisplayItemMasterInventoryMethodChange,
            ChangeSetPayloadDisplayItemMasterRefurbChange,
            ChangeSetPayloadDisplayLimitedLifeChange
        }
    })
], ReadOnlyChangeSet);
export default ReadOnlyChangeSet;
