import { ChangeSetType } from '~/db_types';
export const formatTypeToCapitalCase = (type) => {
    if (typeof type !== 'string') {
        return;
    }
    const changeSetType = type.split('_').map((slug) => {
        return `${slug.substring(0, 1)}${slug.substring(1).toLowerCase()}`;
    });
    return changeSetType.join('');
};
export const changeSetDisplayBasedOnType = (changeSetType) => {
    if (!changeSetType) {
        return;
    }
    switch (changeSetType) {
        case ChangeSetType.ITEM_DRAWING_VALID_SIZES_CHANGE:
            return `ChangeSetDisplay${formatTypeToCapitalCase(changeSetType)}`;
        default:
            return `ChangeSetDisplay${formatTypeToCapitalCase(changeSetType)}`;
    }
};
export const changeSetPayloadBasedOnType = (changeSetType) => {
    if (!changeSetType) {
        return;
    }
    switch (changeSetType) {
        case ChangeSetType.ITEM_DRAWING_VALID_SIZES_CHANGE:
        case ChangeSetType.ITEM_DRAWING_CODES_CHANGE:
        case ChangeSetType.ITEM_MASTER_INVENTORY_METHOD_CHANGE:
        case ChangeSetType.ITEM_MASTER_INITIALIZATION:
        case ChangeSetType.ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE:
        case ChangeSetType.ITEM_MASTER_REFURB_CHANGE:
        case ChangeSetType.ITEM_INSTANCE_CONFIGURATION_CHANGE:
        case ChangeSetType.ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE:
        case ChangeSetType.ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE:
        case ChangeSetType.ITEM_INSTANCE_INVENTORY_CLASS_CHANGE:
        case ChangeSetType.ITEM_INSTANCE_INVENTORY_COST_CHANGE:
        case ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL:
        case ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE:
            return `ChangeSetPayloadDisplay${formatTypeToCapitalCase(changeSetType)}`;
        case ChangeSetType.ITEM_INSTANCE_LIMITED_LIFE_CHANGE:
        case ChangeSetType.ITEM_MASTER_LIMITED_LIFE_CHANGE:
            return 'ChangeSetPayloadDisplayLimitedLifeChange';
        default:
            return null;
    }
};
