export const leadTimeToUnitOfMeasure = function (leadTime) {
    if (!leadTime) {
        return null;
    }
    const unit = Object.keys(leadTime).find((key) => leadTime[key] !== null);
    return unit
        ? {
            unit,
            value: leadTime[unit]
        }
        : null;
};
