import { __decorate } from "tslib";
import { add } from 'date-fns';
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { ChangeSetStatus, InventoryMethod } from '~/db_types';
import BaseHardwareDisplay from '~/nasa_ui/base/BaseHardwareDisplay';
import { CosmicIntervalFragment } from '~/nasa_ui/DAL/base/interval';
import { CosmicLimitedLifeQuotaFragment, CosmicLimitedLifeRemainingFragment } from '~/nasa_ui/DAL/limitedLife';
import { CosmicLinkSlimFragment } from '~/nasa_ui/DAL/link';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import { EntityType } from '~/nasa_ui/types';
import { currentLocationsGroupedByProjectCode } from '~/nasa_ui/utils/helpers/displays';
let AppItemInstanceDisplay = class AppItemInstanceDisplay extends BaseHardwareDisplay {
    get _availableQuantity() {
        if (!this._hardwareData) {
            return '0';
        }
        return this._hardwareData.availableQuantity?.toString();
    }
    get _computedEquipmentControlNumber() {
        if (!this._hardwareData) {
            return;
        }
        return this._hardwareData.equipmentControlNumber;
    }
    get _computedLastServiceDate() {
        if (!this._hardwareData) {
            return;
        }
        return this.$dateDisplay(this._hardwareData?.computedLastServiceDate);
    }
    get _computedLastInspectionDate() {
        if (!this._hardwareData) {
            return;
        }
        return this.$dateDisplay(this._hardwareData?.computedLastInspectionDate);
    }
    get _currentLocationsForIIGroupedByProjectCode() {
        if (!this.currentUserActiveSupportContext) {
            return [];
        }
        return (currentLocationsGroupedByProjectCode(this._hardwareData?.inventories?.nodes, this.currentUserActiveSupportContext)
            // filter out any project codes that don't have any locations
            .filter((grouping) => Boolean(grouping.locations.length)));
    }
    get _hardwareData() {
        return this.hardwareData;
    }
    get _hasOpenPaperAssy() {
        if (!this._hardwareData) {
            return false;
        }
        const hasOpenPaperAssy = Boolean(this._hardwareData?.openDocumentCount) || Boolean(this._hardwareData?.openDocumentCountAssembly);
        return hasOpenPaperAssy;
    }
    get _installedQuantity() {
        if (!this._hardwareData) {
            return '0';
        }
        return this._hardwareData.installedQuantity?.toString();
    }
    get _inventoryQuantity() {
        if (!this._hardwareData) {
            return '0';
        }
        return this._hardwareData.inventoryQuantity?.toString();
    }
    get _initializationStatus() {
        if (!this._hardwareData) {
            return;
        }
        return this._hardwareData?.initializationStatus === ChangeSetStatus.APPROVED;
    }
    get _intervalLifeDisplay() {
        return this.buildLimitedLifeDisplay('interval', this._hardwareData || null);
    }
    get _inventoryIds() {
        if (!this._hardwareData) {
            return;
        }
        return this._hardwareData?.inventories?.nodes?.map((node) => node?.id);
    }
    get isApprovedAsDesign() {
        if (!this._hardwareData) {
            return;
        }
        return Boolean(this._hardwareData.itemMaster?.isApprovedAsDesign);
    }
    get _limitedLifeAlertCountIsGreaterThanZero() {
        if (!this._hardwareData) {
            return false;
        }
        const limitedLifeAlertTotalCount = this._hardwareData?.limitedLifeAlertCount;
        return limitedLifeAlertTotalCount ? limitedLifeAlertTotalCount > 0 : false;
    }
    get _onOrbitLifeDisplay() {
        return this.buildLimitedLifeDisplay('onOrbit', this._hardwareData || null);
    }
    get _operationLifeDisplay() {
        return this.buildLimitedLifeDisplay('operation', this._hardwareData || null);
    }
    get _pressurizedTimeLifeDisplay() {
        return this.buildLimitedLifeDisplay('pressurizedTime', this._hardwareData || null);
    }
    get _changelogHref() {
        const from = this.$dateDisplay(add(new Date(), { years: -1 }));
        const to = this.$dateDisplay(new Date());
        return `/reporting/changelog?from=${from}&to=${to}&entityType=${EntityType.ITEM_INSTANCE}&itemInstanceNodeId=${this.itemInstanceNodeId}&autoExecute=true&ahd=true`;
    }
    get _shelfLifeDisplay() {
        return this.buildLimitedLifeDisplay('shelf', this._hardwareData || null);
    }
    get _staticLifeDisplay() {
        return this.buildLimitedLifeDisplay('static', this._hardwareData || null);
    }
    get _usageLifeDisplay() {
        return this.buildLimitedLifeDisplay('usage', this._hardwareData || null);
    }
    get hasLimitedLife() {
        return this._hardwareData.hasLimitedLife;
    }
    get hasLimitedLifeShelf() {
        return this._hardwareData.hasLimitedLifeShelf;
    }
    get hasLimitedLifeUsage() {
        return this._hardwareData.hasLimitedLifeUsage;
    }
    get hasLimitedLifeOperation() {
        return this._hardwareData.hasLimitedLifeOperation;
    }
    get hasLimitedLifePressurizedTime() {
        return this._hardwareData.hasLimitedLifePressurizedTime;
    }
    get hasLimitedLifeInterval() {
        return this._hardwareData.hasLimitedLifeInterval;
    }
    get hasLimitedLifeOnOrbit() {
        return this._hardwareData.hasLimitedLifeOnOrbit;
    }
    get hasLimitedLifeStatic() {
        return this._hardwareData.hasLimitedLifeStatic;
    }
    get drawingNumber() {
        return this._hardwareData?.drawingNumber || '';
    }
    async fetchHardwareEntity(nodeId) {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicLocationSlimFragment}
          ${CosmicIntervalFragment}
          ${CosmicLimitedLifeQuotaFragment}
          ${CosmicLimitedLifeRemainingFragment}
          ${CosmicLinkSlimFragment}

          fragment ItemInstanceForAppHardwareDisplay on ItemInstance {
            acquisitionDate
            asBuiltNumber
            asDesignNumber
            availableQuantity
            birthDate
            computedHazardousMaterialText
            computedLastInspectionDate
            computedLastServiceDate
            computedSoftwareVersion
            cureDate
            drawingNumber
            equipmentControlNumber
            hasLimitedLife
            hasLimitedLifeCycle: hasLimitedLife(limitedLifeType: [CYCLE])
            hasLimitedLifeErrors
            hasLimitedLifeEva: hasLimitedLife(limitedLifeType: [EVA])
            hasLimitedLifeFlight: hasLimitedLife(limitedLifeType: [FLIGHT])
            hasLimitedLifeInterval: hasLimitedLife(limitedLifeType: [INTERVAL])
            hasLimitedLifeOnOrbit: hasLimitedLife(limitedLifeType: [ON_ORBIT])
            hasLimitedLifeOperation: hasLimitedLife(limitedLifeType: [OPERATION])
            hasLimitedLifePressurizedTime: hasLimitedLife(limitedLifeType: [PRESSURIZED_TIME])
            hasLimitedLifeProperties
            hasLimitedLifeShelf: hasLimitedLife(limitedLifeType: [SHELF])
            hasLimitedLifeStatic: hasLimitedLife(limitedLifeType: [STATIC])
            hasLimitedLifeUsage: hasLimitedLife(limitedLifeType: [USAGE])
            id
            initializationStatus
            installedQuantity
            inventoryQuantity
            isArchived
            itemDrawingDescription
            limitedLifeAlertCount
            lotNumber
            manufactureDate
            nodeId
            openDate
            openDocumentCount
            openDocumentCountAssembly
            operationalStartDate
            overrideSoftwareVersion
            pdaDate
            piaDate
            purchaseRequestDate
            serialNumber
            side
            size
            subType

            links {
              nodes {
                ...CosmicLinkSlim
              }
            }
            computedMannedPressurizedTime {
              ...CosmicInterval
            }
            computedUnmannedPressurizedTime {
              ...CosmicInterval
            }
            computedMannedPressurizedTimeSinceLastServ {
              ...CosmicInterval
            }
            itemDrawing {
              nodeId
            }
            itemMaster {
              contractEndingItemLevel
              contractEndingItemNumber
              federalSupplyClass
              hasSoftwareVersion
              hazardousMaterialText
              inventoryControl
              inventoryMethod
              inventoryUnitType
              isApprovedAsDesign
              isHazardousMaterial
              nodeId
              propertyClass
              softwareVersion
              subType
            }
            inventories {
              nodes {
                computedReadinessStatus
                id
                nodeId
                projectCode
                quantity

                location {
                  ...CosmicLocationSlim
                }
              }
            }
            limitedLife {
              ...CosmicLimitedLifeQuota
            }
            limitedLifeRemaining {
              ...CosmicLimitedLifeRemaining
            }
          }

          query GetItemInstanceForAppHardwareDisplay($nodeId: ID!) {
            itemInstance(nodeId: $nodeId) {
              ...ItemInstanceForAppHardwareDisplay
            }
          }
        `,
                variables: {
                    nodeId
                },
                fetchPolicy: this.fetchPolicy
            });
            if (!resp.data.itemInstance) {
                throw new Error('Couldnt fetch hardware');
            }
            this.hardwareType = EntityType.ITEM_INSTANCE;
            this.hardwareData = resp.data.itemInstance;
            this.links = resp.data.itemInstance?.links?.nodes || [];
            this.isLoading = false;
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to fetch Item Instance for the hardware display.'
            });
        }
    }
    get unabbreviatedMethodDisplay() {
        this.hardwareData = this.hardwareData;
        // methodTypeDisplay
        const isMethodUnit = this.hardwareData?.itemMaster?.inventoryMethod === InventoryMethod.UNIT;
        const methodDisplay = this.hardwareData?.itemMaster?.inventoryMethod
            ? this.hardwareData?.itemMaster?.inventoryMethod
            : '';
        const unitTypeDisplay = isMethodUnit && this.hardwareData?.itemMaster?.inventoryUnitType
            ? this.hardwareData?.itemMaster?.inventoryUnitType
            : '';
        return `${isMethodUnit ? unitTypeDisplay : methodDisplay}`;
    }
};
AppItemInstanceDisplay = __decorate([
    Component
], AppItemInstanceDisplay);
export default AppItemInstanceDisplay;
