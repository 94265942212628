var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "the_nav_bar_container",
    attrs: {
      "data-app": "true"
    }
  }, [_vm._t("alerts"), _c('div', {
    staticClass: "the_nav_bar"
  }, [_c('div', {
    staticClass: "nav_bar_item"
  }, [_c('v-toolbar-side-icon', {
    staticClass: "menu_hamburger_icon",
    attrs: {
      "aria-label": "toggle menu",
      "aria-selected": "false",
      "tooltip": "Toggle menu",
      "tooltip-position": "bottom left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleTheSideNavigationOpenState.apply(null, arguments);
      },
      "mouseover": function mouseover($event) {
        _vm.isHoveringOverHamburger = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.isHoveringOverHamburger = false;
      }
    }
  }, [!_vm.isHoveringOverHamburger ? _c('c-icon-fa', {
    attrs: {
      "icon": "fa fas fa-bars",
      "icon-color": "white",
      "icon-size": "20px"
    }
  }) : _c('c-icon-fa', {
    attrs: {
      "icon": "fa fas fa-bars",
      "icon-color": "black",
      "icon-size": "20px"
    }
  })], 1)], 1), _c('TheSideNav', {
    ref: "TheSideNav",
    attrs: {
      "aria-selected": _vm.ariaSelected,
      "outage-alert-state": _vm.outageAlertState
    },
    on: {
      "closeTheSideNav": _vm.closeTheSideNav,
      "openSideNav": function openSideNav($event) {
        _vm.navOpenState = true;
      }
    },
    model: {
      value: _vm.navOpenState,
      callback: function callback($$v) {
        _vm.navOpenState = $$v;
      },
      expression: "navOpenState"
    }
  }), _c('div', {
    staticClass: "nav_bar_item logo"
  }, [_c('v-toolbar-title', {
    staticClass: "application_title",
    attrs: {
      "tooltip": "COSMIC Home"
    }
  }, [_c('a', {
    staticClass: "delinkify",
    attrs: {
      "href": "/"
    },
    on: {
      "click": _vm.onClickOfCosmicLogo
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.cosmicLogoSrc,
      "alt": "COSMIC"
    }
  })])])], 1), _vm.shouldShowNewBrowserWindowIcon ? _c('div', {
    ref: "new_tab_icon",
    staticClass: "nav_bar_item new_tab_icon",
    on: {
      "mouseover": function mouseover($event) {
        _vm.isHoveringOverNewTab = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.isHoveringOverNewTab = false;
      }
    }
  }, [_c('a', {
    staticClass: "delinkify new_tab",
    attrs: {
      "aria-label": "open cosmic in a new tab",
      "href": "/",
      "target": "_blank",
      "tooltip": "Open COSMIC in a new tab"
    }
  }, [_c('div', [_c('c-icon-fa', {
    attrs: {
      "icon-color": _vm.isHoveringOverNewTab ? 'black' : 'white',
      "icon": "fad fa-browser",
      "icon-size": "20px"
    }
  }), _c('c-icon-fa', {
    staticClass: "browser-plus",
    attrs: {
      "icon-color": _vm.isHoveringOverNewTab ? 'black' : 'white',
      "icon": "fas fa-plus",
      "icon-size": "16px"
    }
  })], 1)])]) : _vm._e(), _c('v-spacer'), _vm.currentUserHasSupportContexts ? _c('div', {
    staticClass: "nav_bar_item"
  }, [_c('TheCreateMenu', {
    attrs: {
      "items": _vm.createMenuItems
    }
  })], 1) : _vm._e(), _vm.currentUserHasSupportContexts && _vm.shouldShowLimitedLifeLink ? _c('div', {
    ref: "limited_life_link",
    staticClass: "nav_bar_item"
  }, [_c('router-link', {
    staticClass: "navbar_link",
    attrs: {
      "to": _vm.hrefToLimitedLifeReport,
      "tooltip": "View Limited Life alerts"
    }
  }, [_c('v-badge', {
    attrs: {
      "right": "",
      "color": "error"
    },
    scopedSlots: _vm._u([_vm.limitedLifeAlertCount && _vm.limitedLifeAlertCount > 0 ? {
      key: "badge",
      fn: function fn() {
        return [_c('span', {
          staticClass: "badge_display"
        }, [_vm._v(_vm._s(_vm.limitedLifeBadgeCount))])];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v(" Limited Life ")])], 1)], 1) : _vm._e(), _vm.currentUserHasSupportContexts && _vm.shouldShowCalibrationsLink ? _c('div', {
    ref: "calibrations_link",
    staticClass: "nav_bar_item"
  }, [_c('router-link', {
    staticClass: "navbar_link",
    attrs: {
      "to": _vm.hrefToCalibrationsReport,
      "tooltip": "View Calibration alerts"
    }
  }, [_c('v-badge', {
    attrs: {
      "right": "",
      "color": "error"
    },
    scopedSlots: _vm._u([_vm.calibrations ? {
      key: "badge",
      fn: function fn() {
        return [_c('span', {
          staticClass: "badge_display"
        }, [_vm._v(_vm._s(_vm.calibrations.length))])];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v(" Calibrations ")])], 1)], 1) : _vm._e(), _vm.shouldShowFavoritesLink ? _c('div', {
    ref: "favorites_link",
    staticClass: "nav_bar_item"
  }, [_c('button', {
    staticClass: "navbar_link",
    attrs: {
      "tooltip": "View your favorites"
    },
    on: {
      "click": function click($event) {
        _vm.showFavoritesModal = !_vm.showFavoritesModal;
      }
    }
  }, [_vm._v(" Favorites ")]), _vm.showFavoritesModal ? _c('AppModal', {
    attrs: {
      "hide-title-section": ""
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.showFavoritesModal = false;
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) return null;
        _vm.showFavoritesModal = false;
      }
    }
  }, [_c('template', {
    slot: "content"
  }, [_c('UserFavorites', {
    attrs: {
      "hide-title": ""
    }
  })], 1), _c('template', {
    slot: "actions"
  }, [_c('ButtonLink', {
    attrs: {
      "aria-label": "user settings",
      "color": _vm.EntityType.USER,
      "dark": "",
      "to": _vm.usersManageLink
    },
    on: {
      "click": function click($event) {
        _vm.showFavoritesModal = false;
      }
    }
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": "fa fa-cog",
      "icon-color": "white"
    }
  }), _c('span', [_vm._v("User settings")])], 1), _c('v-spacer'), _c('ButtonCancel', {
    attrs: {
      "aria-label": "close"
    },
    on: {
      "click": function click($event) {
        _vm.showFavoritesModal = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 2) : _vm._e()], 1) : _vm._e(), _vm.currentUserHasSupportContexts && _vm.shouldShowMyApprovals ? _c('div', {
    ref: "my_approvals",
    staticClass: "nav_bar_item"
  }, [_c('MenuMyCosmicApprovals')], 1) : _vm._e(), _vm.currentUserHasSupportContexts && _vm.shouldShowUserSupport ? _c('div', {
    ref: "support_context",
    staticClass: "nav_bar_item"
  }, [_c('ActiveUserSupportsDisplay', {
    attrs: {
      "id": _vm.userId
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "nav_bar_item"
  }, [_c('TheSessionTimeout')], 1), _c('div', {
    staticClass: "nav_bar_item my-1 mr-1 ml-3"
  }, [_c('TheUserAvatar')], 1), _vm.userNeedsNewSession ? _c('AppModal', {
    attrs: {
      "color": "white",
      "hideTitleSection": "",
      "persistent": "",
      "modalWidth": "400"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('p', {
          staticClass: "headline"
        }, [_vm._v("Your session has expired.")]), _c('div', {
          staticClass: "pt-3"
        }, [_vm._v("Please reauthorize your session.")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-spacer'), _c('ButtonGeneric', {
          attrs: {
            "aria-label": "authorize",
            "color": "var(--v-success-base)"
          },
          on: {
            "click": _vm.handleLaunchpadSessionAuthorizeClick
          }
        }, [_vm._v(" Authorize ")])];
      },
      proxy: true
    }], null, false, 1772124642)
  }) : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };