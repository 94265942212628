export var CosmicSystem;
(function (CosmicSystem) {
    CosmicSystem["ESOC"] = "ESOC";
})(CosmicSystem || (CosmicSystem = {}));
export var CosmicUserSupportContext;
(function (CosmicUserSupportContext) {
    CosmicUserSupportContext["CTSD"] = "CTSD";
    CosmicUserSupportContext["ESOC"] = "ESOC";
    CosmicUserSupportContext["NASA"] = "NASA";
    CosmicUserSupportContext["EHP"] = "EHP";
    CosmicUserSupportContext["xEMU"] = "xEMU";
})(CosmicUserSupportContext || (CosmicUserSupportContext = {}));
export const CosmicSystemToCosmicUserSupport = {
    ESOC: [CosmicUserSupportContext.ESOC, CosmicUserSupportContext.xEMU, CosmicUserSupportContext.CTSD]
};
