import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EquipmentGroupDisplay, FederalSupplyClassDisplay, FederalSupplyClassFCSRef, InventoryClassDisplay, ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types';
import { addNodeIdToResponseObj } from '~/nasa_ui/vue_plugins/axios';
import { CosmicIcons } from '../../ui/CosmicIcons';
import { addAsBuiltNumberDisplay, addAsDesignNumberDisplay, addBuildingStockBinDisplay, addComputedLastInspectionDate, addContactDisplays, addCrossContextLocationDisplay, addDateDisplays, addDescriptionDisplay, addDrawingNumberDisplay, addInitializationStatusDisplay, addInventoryPlusMethodDisplay, addInventoryQuantityDisplay, addInventoryStatusDisplay, addIsToolIcon, addItemNumberDisplay, addLastServiceDate, addLocationDisplay, addLotNumberDisplay, addMannedPressurizedTime, addMannedPressurizedTimeSinceLastService, addOverrideSoftwareVersionDisplay, addProjectCodeDisplay, addProjectCodesDisplay, addQuantitiesDisplay, addSerialNumberDisplay, addServicePressurizedTime, addSideDisplay, addSinceLastInspection, addSizeDisplay, addSizesDisplay, addSoftwareVersionDisplay, addSubTypeDisplay, addUnitCost } from '../displayMappers';
import { booleanIconDisplay } from '../displays';
import { isNullOrUndefined } from '../isNullOrUndefined';
export const transformInventories = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addBuildingStockBinDisplay)
        .map(addCrossContextLocationDisplay)
        .map(addDescriptionDisplay)
        .map(addInventoryPlusMethodDisplay)
        .map(addInventoryStatusDisplay)
        .map(addIsToolIcon)
        .map(addItemNumberDisplay)
        .map(addLocationDisplay)
        .map(addLotNumberDisplay)
        .map(addOverrideSoftwareVersionDisplay)
        .map(addProjectCodeDisplay)
        .map(addSerialNumberDisplay)
        .map(addSideDisplay)
        .map(addSizeDisplay)
        .map(addSoftwareVersionDisplay)
        .map(addSubTypeDisplay(ItemInstanceInventoryTypeDisplay))
        .map(addUnitCost)
        .map((item) => {
        let icon = '';
        if (item.isInstalled) {
            icon = `
          <i
            class="fa-2x ${CosmicIcons['true']} success--text"
            title="This item is INSTALLED on another item"
          ></i>`;
        }
        else {
            icon = `<i class="fa-2x ${CosmicIcons['false']} secondary--text" title="Not installed"></i>`;
        }
        item._isInstalled = icon;
        return item;
    });
};
export const transformInventoryResponses = (items) => {
    return transformInventories(items);
};
export const transformItemInstances = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addAsDesignNumberDisplay)
        .map(addComputedLastInspectionDate)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addInitializationStatusDisplay)
        .map(addInventoryQuantityDisplay)
        .map(addIsToolIcon)
        .map(addItemNumberDisplay)
        .map(addLastServiceDate)
        .map(addLotNumberDisplay)
        .map(addMannedPressurizedTime)
        .map(addMannedPressurizedTimeSinceLastService)
        .map(addNodeIdToResponseObj)
        .map(addProjectCodesDisplay)
        .map(addSerialNumberDisplay)
        .map(addServicePressurizedTime)
        .map(addSideDisplay)
        .map(addSinceLastInspection)
        .map(addSizeDisplay)
        .map((item) => {
        const _item = { ...item };
        // CEI
        _item._ceiNumber = _item.itemMaster?.contractEndingItemNumber ?? DEFAULT_DASH;
        // subType
        if (_item.subType) {
            if (Array.isArray(_item.subType)) {
                const subTypes = _item.subType
                    ? _item.subType.map((_) => InventoryClassDisplay.get(_))
                    : [];
                _item._subType = subTypes.length ? subTypes.join(', ') : DEFAULT_DASH;
            }
            else {
                _item._subType = InventoryClassDisplay.get(_item.subType) || DEFAULT_DASH;
            }
        }
        return _item;
    });
};
export const transformItemInstanceResponses = (items) => {
    return transformItemInstances(items);
};
export const transformItemMasters = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addDrawingNumberDisplay)
        .map(addInitializationStatusDisplay)
        .map(addItemNumberDisplay)
        .map(addQuantitiesDisplay)
        .map(addSideDisplay)
        .map(addSizesDisplay)
        .map(addIsToolIcon)
        .map(addSubTypeDisplay(EquipmentGroupDisplay))
        .map((item) => {
        // FederalSupplyClass
        item._federalSupplyClass = item.federalSupplyClass
            ? FederalSupplyClassDisplay.get(item.federalSupplyClass)
            : DEFAULT_DASH;
        item._federalSupplyClassNumber = item.federalSupplyClass
            ? FederalSupplyClassFCSRef.get(item.federalSupplyClass)
            : DEFAULT_DASH;
        // Approved AD
        item._approvalStatusIcon = !isNullOrUndefined(item.isApprovedAsDesign)
            ? booleanIconDisplay(item.isApprovedAsDesign)
            : DEFAULT_DASH;
        return item;
    });
};
export const transformItemMasterResponses = (items) => {
    return transformItemMasters(items);
};
export const transformItemDrawings = (items) => {
    return items
        .map(addContactDisplays)
        .map(addDateDisplays)
        .map(addInitializationStatusDisplay)
        .map(addDrawingNumberDisplay)
        .map(addItemNumberDisplay)
        .map(addDescriptionDisplay)
        .map(addQuantitiesDisplay)
        .map((item) => {
        // _eeeIdentifier
        const icon = booleanIconDisplay(item.eeeIdentifier);
        item._eeeIdentifier = icon;
        return item;
    })
        .map((item) => {
        // _aliases
        if (item.aliases && item.aliases.length) {
            const aliases = item.aliases.map((alias) => alias && alias.trim && `<li class="mt-1">${alias.trim()}</li>`);
            aliases.unshift('<ul>');
            aliases.push('</ul>');
            item._aliases = aliases.join('');
        }
        else {
            item._aliases = DEFAULT_DASH;
        }
        return item;
    });
};
export const transformItemDrawingResponses = (items) => {
    return transformItemDrawings(items);
};
//#endregion
