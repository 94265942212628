var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.hasComputedTitleOrSlot ? _c('AppHeader', {
    attrs: {
      "size": "mini",
      "uppercase": false
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('div', {
    staticClass: "info_container d-flex",
    class: {
      hideBorderBottom: _vm.hideBorderBottom
    }
  }, [_vm.isItemDrawing ? _c('AppItemDrawingDisplay', {
    attrs: {
      "node-id": _vm.nodeId,
      "fetch-policy": _vm.fetchPolicy
    }
  }) : _vm._e(), _vm.isItemMaster ? _c('AppItemMasterDisplay', {
    attrs: {
      "node-id": _vm.nodeId,
      "fetch-policy": _vm.fetchPolicy
    }
  }) : _vm._e(), _vm.isItemInstance ? _c('AppItemInstanceDisplay', {
    attrs: {
      "node-id": _vm.nodeId,
      "fetch-policy": _vm.fetchPolicy
    },
    on: {
      "hardwareDataChange": _vm.hardwareDataChange
    }
  }) : _vm._e(), _vm.isInventory ? _c('AppInventoryDisplay', {
    attrs: {
      "node-id": _vm.nodeId,
      "fetch-policy": _vm.fetchPolicy
    },
    on: {
      "hardwareDataChange": _vm.hardwareDataChange
    }
  }) : _vm._e(), _vm.isCrossContextInventory ? _c('AppInventoryCrossContextDisplay', {
    attrs: {
      "cross-context-inventory-id": _vm.crossContextInventoryId,
      "fetch-policy": _vm.fetchPolicy
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };