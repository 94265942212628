var render = function render() {
  var _vm$adminUsers;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.id ? _c('div', [_c('MiniChangeSet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideMini,
      expression: "!hideMini"
    }],
    staticClass: "mb-3",
    attrs: {
      "show-manage-link": false,
      "id": _vm.id
    }
  }), _c('AppLoader', {
    staticClass: "mb-3",
    attrs: {
      "show": _vm.isFetching,
      "color": _vm.EntityType.CHANGE_SET,
      "type": "linear"
    }
  }), _vm.entity ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppDynamicWrapper', {
    staticClass: "mb-3",
    attrs: {
      "display-type": _vm.AppDynamicWrapperType.NONE,
      "color": _vm.EntityType.CHANGE_SET
    }
  }, [!_vm.hideAhd ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-3": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.isSubTypeDocument && _vm.entity.document ? _c('MiniDocument', {
    staticClass: "mb-2",
    attrs: {
      "id": _vm.entity.document.id
    }
  }) : _vm._e(), _c('AppPageDescription', [_vm._v("Change opened against this Hardware")]), _c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.hardwareId
    }
  })], 1)], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._l(_vm.alertText, function (text) {
    return _c('Alert', {
      key: text,
      staticClass: "mb-3",
      attrs: {
        "type": "warning"
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(text)
      }
    })]);
  }), _vm.userIsNotAuthorized && !_vm.isChangeSetClosed ? _c('ButtonGeneric', {
    staticClass: "emailButton mb-3",
    attrs: {
      "href": _vm.emailLinkToSend,
      "title": _vm.userNamesTitleForEmailButton,
      "text-color": "white",
      "color": "var(--v-primary-base)",
      "target": "_blank",
      "icon": "fad fa-envelope-open-text"
    }
  }, [_vm._v(" Email ADMIN_USERS ( "), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(((_vm$adminUsers = _vm.adminUsers) === null || _vm$adminUsers === void 0 ? void 0 : _vm$adminUsers.length) || 0))]), _vm._v(" members) ")]) : _vm._e()], 2)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.allAuthorities && _vm.allAuthorities.length > 0 ? _c('AppTable', {
    staticClass: "mb-3",
    attrs: {
      "disable-initial-sort": true,
      "headers": _vm.allAuthHeaders,
      "items": _vm.allAuthorities,
      "icon": "fa fa-balance-scale",
      "color": "change_sets darken-2",
      "hide-pagination": "",
      "selectable-rows": false,
      "title": "Approvals"
    }
  }) : _vm._e()], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": "",
      "pl-3": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('AppHeader', [_vm._v("Details")])], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Created by",
      "value": _vm.$contactDisplay(_vm.entity.createdByUser)
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Closed by",
      "value": _vm.$contactDisplay(_vm.entity.status.closedByUser)
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Created",
      "value": _vm.createdDateDisplay
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Closed",
      "value": _vm.closedDateDisplay
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Type",
      "value": _vm.subTypeDisplay
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Projected",
      "value": _vm.projectedDateDisplay
    }
  })], 1)], 1), !_vm.shouldShowPendingChanges && _vm.changeSetPayloadBasedOnType(_vm.changeSetType) ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": "",
      "pl-3": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._v("Payload")]), _c(_vm.changeSetPayloadBasedOnType(_vm.changeSetType), {
    tag: "component",
    attrs: {
      "change-set": _vm.entity
    }
  })], 1)], 1) : _vm._e(), !_vm.isSubTypeDocument && !_vm.isItemMasterInitialization && _vm.shouldShowChangeSetDisplay ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c(_vm.correspondingChangeSetDisplay, {
    tag: "component",
    attrs: {
      "id": _vm.id,
      "change-set": _vm.entity
    }
  })], 1)], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-3": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('div', {
    staticClass: "authority-pipeline pa-2"
  }, _vm._l(_vm.pipelineDisplay, function (csAuth, index) {
    return _c('v-tooltip', {
      key: index,
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('span', _vm._g({}, on), [_c('c-icon-fa', {
            attrs: {
              "icon": _vm.$icons[csAuth.icon],
              "icon-color": csAuth.status
            }
          }), index !== _vm.pipelineDisplay.length - 1 ? _c('c-icon-fa', {
            staticClass: "connector",
            attrs: {
              "icon-size": "10px",
              "icon": _vm.$icons.pipeline_connector
            }
          }) : _vm._e()], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(csAuth.status))])]);
  }), 1)])], 1), _vm.isSubTypeDocument && !_vm.hasNoLineItems ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "mt-2",
    attrs: {
      "headers": _vm.openLineItemTableHeaders,
      "hide-pagination": _vm.openLineItems.length < 10,
      "items": _vm.openLineItems,
      "color": _vm.EntityType.CHANGE_SET,
      "title": "Open line items"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "my-2",
    attrs: {
      "headers": _vm.closedLineItemTableHeaders,
      "items": _vm.closedLineItems,
      "color": _vm.EntityType.CHANGE_SET,
      "title": "Closed line items"
    }
  })], 1)], 1) : _vm._e(), _vm.computedComments && _vm.computedComments.length > 0 ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "mb-3",
    attrs: {
      "headers": _vm.CommentTableHeaders,
      "items": _vm.computedComments,
      "disable-initial-sort": "",
      "color": _vm.EntityType.COMMENT,
      "show-search-input": "",
      "title": "Comments"
    }
  })], 1)], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppLinks', {
    attrs: {
      "hideIfNone": "",
      "item-instance": _vm.itemInstanceFormattedForLinks,
      "read-only": true
    }
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };