import { __decorate, __metadata } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { OrganizationType } from '~/db_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { EntityType } from '~/nasa_ui/types';
import { transformOrganizationResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniOrganization = class MiniOrganization extends BaseMini {
    entityType = EntityType.ORGANIZATION;
    localEntity = null;
    get isActive() {
        return Boolean(this.localEntity?.isActive);
    }
    get manageLink() {
        return this.localEntity
            ? `/${EntityType.ORGANIZATION}/manage/${encodeURIComponent(this.localEntity.code)}`
            : '';
    }
    get organizationCodeLabel() {
        return this.organizationTypeIsManufacturer && this.localEntity?.isCageCode ? 'Cage Code' : 'Organization Code';
    }
    get organizationTypeIsManufacturer() {
        return this.localEntity && this.localEntity.subType === OrganizationType.MANUFACTURER;
    }
    get transformedOrganization() {
        return this.localEntity ? transformOrganizationResponses([this.localEntity])[0] : null;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        items.push({
            name: 'Type',
            value: this.transformedOrganization?._subType
        });
        if (this.localEntity?.name) {
            items.push({
                name: 'Name',
                value: this.transformedOrganization?._name
            });
        }
        return items;
    }
    async onCodeUpdate() {
        if (!this.code) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/${EntityType.ORGANIZATION}/${encodeURIComponent(this.code)}`);
            this.localEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
            this.isFetching = false;
        }
    }
};
__decorate([
    Watch('code', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniOrganization.prototype, "onCodeUpdate", null);
MiniOrganization = __decorate([
    Component
], MiniOrganization);
export default MiniOrganization;
