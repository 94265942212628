var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "headers": _vm.latestHardwareTableHeaders,
      "items": _vm.latestHardwareTableItems,
      "loading": _vm.isFetching,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "userDefinedPagination": _vm.customHardwarePagination,
      "disable-initial-sort": "",
      "hide-icon": "",
      "show-search-input": "",
      "title": "Latest Hardware",
      "sticky": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ButtonMiniActionInfo', {
          on: {
            "click": function click($event) {
              _vm.selectedHardware = item;
            }
          }
        })];
      }
    }])
  }), _vm.selectedHardwareNodeId ? _c('ModalAppHardwareDisplay', {
    attrs: {
      "node-id": _vm.selectedHardwareNodeId
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.selectedHardware = null;
      }
    }
  }) : _vm._e(), _vm.selectedChangeSet ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.selectedChangeSet = null;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.selectedChangeSetHardwareNodeId ? _c('AppHardwareDisplay', {
          attrs: {
            "node-id": _vm.selectedChangeSetHardwareNodeId
          }
        }) : _vm._e(), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('SummaryCardChangeSet', {
          attrs: {
            "change-set": _vm.selectedChangeSet
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonCancel', {
          on: {
            "click": function click($event) {
              _vm.selectedChangeSet = null;
            }
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }], null, false, 709833157)
  }) : _vm._e(), _c('AppTableActions', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.latestChangeSetTableHeaders,
      "items": _vm.latestChangeSetTableItems,
      "loading": _vm.isFetching,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "userDefinedPagination": _vm.customChangeSetsPagination,
      "disable-initial-sort": "",
      "hide-icon": "",
      "show-search-input": "",
      "sticky": "",
      "title": "Latest public changes"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('ButtonMiniActionInfo', {
          on: {
            "click": function click($event) {
              _vm.selectedChangeSet = item;
            }
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };