var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DB_DEFAULT, DEFAULT_DASH } from '~/nasa_ui/constants';
import { GetItemDrawingByDrawingNumberWithOptionalHardware, GetItemDrawingByNodeId } from '~/nasa_ui/DAL/itemDrawing';
import Hardware from '~/nasa_ui/modules/Hardware';
import { EntityType } from '~/nasa_ui/types';
let MiniItemDrawing = class MiniItemDrawing extends BaseMini {
    entityType = EntityType.ITEM_DRAWING;
    hardware = null;
    localEntity = null;
    showAhd = false;
    BASE_FETCH = this.nodeId
        ? gql `
        ${GetItemDrawingByNodeId}
      `
        : gql `
        ${GetItemDrawingByDrawingNumberWithOptionalHardware}
      `;
    get initializationStatusIconDisplay() {
        if (!this.localEntity || !this.hardware) {
            return null;
        }
        return this.hardware.initializationStatusIconDisplay.replace('approved', '');
    }
    get isArchived() {
        return this.localEntity?.isArchived;
    }
    get manageLink() {
        return this.localEntity ? `/hardware/${EntityType.ITEM_DRAWING}/manage/${this.localEntity.nodeId}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Item Drawing not found';
        }
        return this.localEntity ? this.localEntity.drawingNumber : DEFAULT_DASH;
    }
    get subNameText() {
        return this.localEntity ? this.localEntity.description : null;
    }
    get shouldShowSubText() {
        return Boolean(this.localEntity?.description);
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        const item = this.localEntity;
        if (item.sizes && item.sizes.length > 0) {
            items.push({
                name: 'Valid sizes',
                value: item.sizes
                    ? item.sizes.length <= 3
                        ? item.sizes
                            .map((size) => (size === DB_DEFAULT ? DEFAULT_DASH : size))
                            .join(', ')
                            .toString()
                        : `(${item.sizes.length} listed)`
                    : DEFAULT_DASH
            });
        }
        items.push({
            name: 'PoC',
            value: this.$contactDisplay(item.pointOfContact)
        });
        return items;
    }
    onFetchUpdate(data) {
        if (data.itemDrawing || data.itemDrawingByDrawingNumber) {
            this.localEntity = data.itemDrawing || data.itemDrawingByDrawingNumber;
            this.hardware = new Hardware(this.localEntity);
        }
    }
    onDrawingNumberForMiniChange(drawingNumber) {
        if (!drawingNumber) {
            return;
        }
        this.executeQuery();
    }
};
__decorate([
    Watch('drawingNumberForMini', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], MiniItemDrawing.prototype, "onDrawingNumberForMiniChange", null);
MiniItemDrawing = __decorate([
    Component
], MiniItemDrawing);
export default MiniItemDrawing;
