var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.selectableRows && !_vm.expandableRows ? _c('AppTable', _vm._b({
    attrs: {
      "headers": _vm.computedTableHeaders,
      "icon": _vm.$icons['ON_ORBIT_FAILURE'],
      "color": _vm.computedEntityType,
      "title": _vm.computedTitle
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('input', val);
      },
      "rowClicked": function rowClicked(val) {
        return _vm.$emit('rowClicked', val);
      },
      "searchFilterInput": function searchFilterInput(val) {
        return _vm.$emit('searchFilterInput', val);
      }
    }
  }, 'AppTable', _vm.$props, false)) : _vm.selectableRows ? _c('AppTableSelectable', _vm._b({
    attrs: {
      "headers": _vm.computedTableHeaders,
      "color": _vm.computedEntityType,
      "title": _vm.computedTitle,
      "icon": _vm.$icons['ON_ORBIT_FAILURE']
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('input', val);
      },
      "rowClicked": function rowClicked(val) {
        return _vm.$emit('rowClicked', val);
      },
      "searchFilterInput": function searchFilterInput(val) {
        return _vm.$emit('searchFilterInput', val);
      }
    }
  }, 'AppTableSelectable', _vm.$props, false)) : _vm.expandableRows ? _c('AppTableActions', _vm._b({
    attrs: {
      "headers": _vm.computedTableHeaders,
      "color": _vm.computedEntityType,
      "title": _vm.computedTitle,
      "icon": _vm.$icons['ON_ORBIT_FAILURE']
    },
    on: {
      "expandableRowClick": function expandableRowClick(val) {
        return _vm.$emit('expandableRowClick', val);
      },
      "input": function input(val) {
        return _vm.$emit('input', val);
      },
      "rowClicked": function rowClicked(val) {
        return _vm.$emit('rowClicked', val);
      },
      "searchFilterInput": function searchFilterInput(val) {
        return _vm.$emit('searchFilterInput', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ButtonMiniActionInfo', {
          on: {
            "click": function click($event) {
              _vm.selectedItemInstance = item.itemInstance;
            }
          }
        }), item.itemInstance ? _c('ButtonMiniActionManage', {
          attrs: {
            "to": "/hardware/failures/manage/".concat(item.id)
          }
        }) : _vm._e()];
      }
    }])
  }, 'AppTableActions', _vm.$props, false)) : _vm._e(), _vm.selectedItemInstance ? _c('ModalAppHardwareDisplay', {
    attrs: {
      "node-id": _vm.selectedItemInstance.nodeId
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.selectedItemInstance = null;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };