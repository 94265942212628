var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
let ChangeSetPayloadDisplayItemDrawingValidSizesChange = class ChangeSetPayloadDisplayItemDrawingValidSizesChange extends Vue {
    isFetching = false;
    changeSet;
    get sizes() {
        return this.changeSet?.payload?.sizes
            ? this.changeSet?.payload?.sizes
                .map((size) => `${size}</br>`)
                .toString()
                .split(',')
                .join('')
            : DEFAULT_DASH;
    }
    get sizesOld() {
        return this.changeSet?.changeLogs?.nodes
            ? this.changeSet.changeLogs.nodes[0]?.changeDetails.changedFrom.sizes
                .map((size) => `${size}</br>`)
                .toString()
                .split(',')
                .join('')
            : DEFAULT_DASH;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemDrawingValidSizesChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemDrawingValidSizesChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemDrawingValidSizesChange);
export default ChangeSetPayloadDisplayItemDrawingValidSizesChange;
