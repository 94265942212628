import { __decorate } from "tslib";
import { add } from 'date-fns';
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { ChangeSetStatus } from '~/db_types';
import BaseHardwareDisplay from '~/nasa_ui/base/BaseHardwareDisplay';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicLinkSlimFragment } from '~/nasa_ui/DAL/link';
import { EntityType } from '~/nasa_ui/types';
let AppItemMasterDisplay = class AppItemMasterDisplay extends BaseHardwareDisplay {
    get _availableQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData.availableQuantity?.toString();
    }
    get _initializationStatus() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData?.initializationStatus === ChangeSetStatus.APPROVED;
    }
    get isApprovedAsDesign() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return Boolean(this.hardwareData.isApprovedAsDesign);
    }
    get _specialPackagingStatus() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData?.isSpecialPackaging;
    }
    get _toolStatus() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData?.isTool;
    }
    get _fractureCriticalStatus() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData?.isFractureCritical;
    }
    get _changelogHref() {
        const from = this.$dateDisplay(add(new Date(), { years: -1 }));
        const to = this.$dateDisplay(new Date());
        return `/reporting/changelog?from=${from}&to=${to}&entityType=${EntityType.ITEM_MASTER}&itemMasterNodeId=${this.itemMasterNodeId}&autoExecute=true&ahd=true`;
    }
    get _cleanlinessTrackingStatus() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData?.isCleanlinessTracking;
    }
    get _installedQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData.installedQuantity?.toString();
    }
    get _inventoryQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData.inventoryQuantity?.toString();
    }
    get _itemMasterSizes() {
        if (!this.hardwareData) {
            return DEFAULT_DASH;
        }
        if (this.isItemMaster) {
            this.hardwareData = this.hardwareData;
            return (this.hardwareData.sizes && this.hardwareData.sizes.join(', ')) || DEFAULT_DASH;
        }
        return DEFAULT_DASH;
    }
    get unabbreviatedMethodDisplay() {
        this.hardwareData = this.hardwareData;
        return this.hardwareData?.inventoryMethod;
    }
    async fetchHardwareEntity(nodeId) {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicLinkSlimFragment}

          fragment ItemMasterForAppHardwareDisplay on ItemMaster {
            asBuiltNumber
            availableQuantity
            contractEndingItemLevel
            contractEndingItemNumber
            criticalityCode
            drawingNumber
            equipmentGroup: subType
            federalSupplyClass
            hasSoftwareVersion
            hazardousMaterialText
            initializationStatus
            installedQuantity
            inventoryControl
            inventoryMethod
            inventoryQuantity
            inventoryUnitType
            isApprovedAsDesign
            isArchived
            isCleanlinessTracking
            isFractureCritical
            isHazardousMaterial
            isSpecialPackaging
            isTool
            itemDrawingDescription
            nodeId
            opsNom
            propertyClass
            side
            sizes
            softwareVersion

            itemDrawing {
              nodeId
            }

            links {
              nodes {
                ...CosmicLinkSlim
              }
            }
          }

          query GetItemMasterForAppHardwareDisplay($nodeId: ID!) {
            itemMaster(nodeId: $nodeId) {
              ...ItemMasterForAppHardwareDisplay
            }
          }
        `,
                variables: {
                    nodeId
                },
                fetchPolicy: this.fetchPolicy
            });
            if (!resp.data.itemMaster) {
                throw new Error('Couldnt fetch hardware');
            }
            this.hardwareType = EntityType.ITEM_MASTER;
            this.hardwareData = resp.data.itemMaster;
            this.links = resp.data.itemMaster?.links?.nodes || [];
            this.isLoading = false;
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to fetch Item Master for the hardware display.'
            });
        }
    }
};
AppItemMasterDisplay = __decorate([
    Component
], AppItemMasterDisplay);
export default AppItemMasterDisplay;
