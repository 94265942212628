import { __decorate } from "tslib";
import { add } from 'date-fns';
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { ChangeSetStatus } from '~/db_types';
import BaseHardwareDisplay from '~/nasa_ui/base/BaseHardwareDisplay';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicIntervalFragment } from '~/nasa_ui/DAL/base/interval';
import { CosmicItemDrawingFragment } from '~/nasa_ui/DAL/itemDrawing';
import { CosmicLinkSlimFragment } from '~/nasa_ui/DAL/link';
import { EntityType } from '~/nasa_ui/types';
const DRAWING_SIZES_TRUNCATED_COUNT = 4;
let AppItemDrawingDisplay = class AppItemDrawingDisplay extends BaseHardwareDisplay {
    showAllSizes = false;
    get _availableQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData.availableQuantity?.toString();
    }
    get _changelogHref() {
        const from = this.$dateDisplay(add(new Date(), { years: -1 }));
        const to = this.$dateDisplay(new Date());
        return `/reporting/changelog?from=${from}&to=${to}&entityType=${EntityType.ITEM_DRAWING}&itemDrawingNodeId=${this.itemDrawingNodeId}&autoExecute=true&ahd=true`;
    }
    get _installedQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData.installedQuantity?.toString();
    }
    get _inventoryQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData.inventoryQuantity?.toString();
    }
    get _eeeIdentifier() {
        if (!this.hardwareData) {
            return DEFAULT_DASH;
        }
        this.hardwareData = this.hardwareData;
        return this.createBooleanIcon(this.hardwareData.eeeIdentifier);
    }
    get _initializationStatus() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData?.initializationStatus === ChangeSetStatus.APPROVED;
    }
    get _itemDrawingSizes() {
        const hardwareData = this.hardwareData;
        if (!hardwareData) {
            return DEFAULT_DASH;
        }
        if (hardwareData.sizes?.length === 1 && hardwareData.sizes[0] === 'DEFAULT') {
            return DEFAULT_DASH;
        }
        if (!this.showAllSizes) {
            return hardwareData.sizes?.slice(0, 4).join(', ') || DEFAULT_DASH;
        }
        return hardwareData.sizes?.join(', ') || DEFAULT_DASH;
    }
    get _pointOfContact() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.$contactDisplay(this.hardwareData.pointOfContact);
    }
    get _meanTimeBetweenFailure() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.$intervalDisplay(this.hardwareData.meanTimeBetweenFailure);
    }
    get _meanTimeToRepair() {
        if (!this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        return this.$intervalDisplay(this.hardwareData.meanTimeToRepair);
    }
    get moreSizesCount() {
        return Math.max(0, this.sizes.length - DRAWING_SIZES_TRUNCATED_COUNT);
    }
    get sizes() {
        const hardwareData = this.hardwareData;
        return (hardwareData.sizes || []).sort();
    }
    async fetchHardwareEntity(nodeId) {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicIntervalFragment}
          ${CosmicItemDrawingFragment}
          ${CosmicLinkSlimFragment}

          fragment ItemDrawingForAppHardwareDisplay on ItemDrawing {
            ...CosmicItemDrawing

            links {
              nodes {
                ...CosmicLinkSlim
              }
            }
            meanTimeBetweenFailure {
              ...CosmicInterval
            }
            meanTimeToRepair {
              ...CosmicInterval
            }
          }

          query GetItemDrawingForAppHardwareDisplay($nodeId: ID!) {
            itemDrawing(nodeId: $nodeId) {
              ...ItemDrawingForAppHardwareDisplay
            }
          }
        `,
                variables: {
                    nodeId
                },
                fetchPolicy: this.fetchPolicy
            });
            if (!resp.data.itemDrawing) {
                throw new Error('Couldnt fetch hardware');
            }
            this.hardwareType = EntityType.ITEM_DRAWING;
            this.hardwareData = resp.data.itemDrawing;
            this.links = resp.data.itemDrawing?.links?.nodes || [];
            this.isLoading = false;
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to fetch Item Drawing for the hardware display.'
            });
        }
    }
};
AppItemDrawingDisplay = __decorate([
    Component
], AppItemDrawingDisplay);
export default AppItemDrawingDisplay;
