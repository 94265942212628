var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
let ChangeSetDisplayItemInstanceInventoryProjectCodeChange = class ChangeSetDisplayItemInstanceInventoryProjectCodeChange extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get currentValuesForDisplay() {
        const projectCode = this.selectedEntity && this.selectedEntity.inventory && this.selectedEntity.inventory.projectCode
            ? this.selectedEntity.inventory.projectCode
            : null;
        return {
            projectCode: projectCode ? projectCode : null
        };
    }
    get changesForDisplay() {
        // format the value of each change using the appropriate display
        return {
            projectCode: this.payload.projectCode
        };
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : {};
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query SelectChangeSetForItemInstanceInventoryProjectCodeChange($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              subType
              payload
              inventory {
                nodeId
                projectCode
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceInventoryProjectCodeChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemInstanceInventoryProjectCodeChange.prototype, "syncId", null);
ChangeSetDisplayItemInstanceInventoryProjectCodeChange = __decorate([
    Component
], ChangeSetDisplayItemInstanceInventoryProjectCodeChange);
export default ChangeSetDisplayItemInstanceInventoryProjectCodeChange;
