var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppTableExpandable', {
    attrs: {
      "headers": _vm.computedTableHeaders,
      "items": _vm.items,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "color": "movement",
      "icon": "fad fa-shipping-fast fa-flip-horizontal",
      "itemKey": "inventoryId",
      "show-search-input": "",
      "title": "Open Purchase Requests"
    },
    on: {
      "expandableRowClick": _vm.handleRowClick
    }
  }, [_c('template', {
    slot: "expanded-row"
  }, [_vm.selectedRow && _vm.selectedRowMatchesContext ? _c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.selectedRow.inventoryNodeId
    }
  }) : _vm._e(), _vm.selectedRow && !_vm.selectedRowMatchesContext ? _c('AppHardwareDisplay', {
    attrs: {
      "cross-context-inventory-id": _vm.selectedRow.inventoryId
    }
  }) : _vm._e(), _vm.link ? _c('ButtonGeneric', {
    staticClass: "ml-0",
    attrs: {
      "color": "movement",
      "dark": "",
      "href": _vm.link,
      "icon": _vm.$icons.movement
    }
  }, [_c('span', [_vm._v("Receive Purchase Request")])]) : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };