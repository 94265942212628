var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EquipmentGroupDisplay } from '~/nasa_ui/types/enums/equipment-group';
let ChangeSetPayloadDisplayItemMasterEquipmentGroupChange = class ChangeSetPayloadDisplayItemMasterEquipmentGroupChange extends Vue {
    isFetching = false;
    changeSet;
    get equipmentGroup() {
        if (!this.payload?.subType) {
            return null;
        }
        return EquipmentGroupDisplay.get(this.payload.subType);
    }
    get equipmentGroupOld() {
        const equipmentGroup = this.changeSet?.changeLogs?.nodes
            ? this.changeSet.changeLogs.nodes[0]?.changeDetails.changedFrom.subType
            : null;
        return equipmentGroup ? EquipmentGroupDisplay.get(equipmentGroup) : DEFAULT_DASH;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemMasterEquipmentGroupChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemMasterEquipmentGroupChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemMasterEquipmentGroupChange);
export default ChangeSetPayloadDisplayItemMasterEquipmentGroupChange;
