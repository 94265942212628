var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
let ChangeSetDisplayItemInstanceInventoryCostChange = class ChangeSetDisplayItemInstanceInventoryCostChange extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get inventory() {
        return this.selectedEntity && this.selectedEntity.inventory ? this.selectedEntity.inventory : {};
    }
    get currentValuesForDisplay() {
        // format the value using the appropriate display
        return Object.keys(this.payload).reduce((out, key) => {
            const value = this.inventory[key];
            if (key === 'unitCost' || key === 'totalCost') {
                out[key] = value ? this.$currencyDisplay(value) : null;
            }
            if (key === 'isCostEstimated') {
                out[key] = value === true ? 'Yes' : value === false ? 'No' : null;
            }
            return out;
        }, {});
    }
    get changesForDisplay() {
        // format the value of each change using the appropriate display
        return Object.keys(this.payload).reduce((out, key) => {
            const value = this.payload[key];
            if (key === 'unitCost') {
                out[key] = this.$currencyDisplay(value);
            }
            if (key === 'isCostEstimated') {
                out[key] = value === true ? 'Yes' : value === false ? 'No' : 'Unknown';
            }
            return out;
        }, {});
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : {};
    }
    changesInclude(property) {
        return (Object.keys(this.changesForDisplay)
            // filter out keys that haven't changes
            .filter((key) => {
            return this.inventory[key] !== this.payload[key];
        })
            .includes(property));
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query SelectChangeSetDisplayItemInstanceInventoryCostChange($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              subType
              payload
              inventory {
                nodeId
                unitCost
                isCostEstimated
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceInventoryCostChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemInstanceInventoryCostChange.prototype, "syncId", null);
ChangeSetDisplayItemInstanceInventoryCostChange = __decorate([
    Component
], ChangeSetDisplayItemInstanceInventoryCostChange);
export default ChangeSetDisplayItemInstanceInventoryCostChange;
