var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
let ChangeSetPayloadDisplayItemInstanceInventoryCostChange = class ChangeSetPayloadDisplayItemInstanceInventoryCostChange extends Vue {
    isFetching = false;
    changeSet;
    get changeLog() {
        return this.changeSet?.changeLogs?.nodes[0] || null;
    }
    get hasIsCostEstimated() {
        return this.payload && 'isCostEstimated' in this.payload;
    }
    get hasUnitCost() {
        return this.payload && 'unitCost' in this.payload;
    }
    get isCostEstimated() {
        return this.payload?.isCostEstimated ? 'Yes' : 'No';
    }
    get isCostEstimatedOld() {
        return this.changeLog?.changeDetails?.changedFrom.hasOwnProperty('isCostEstimated')
            ? this.changeLog?.changeDetails?.changedFrom?.isCostEstimated
                ? 'Yes'
                : 'No'
            : DEFAULT_DASH;
    }
    get unitCost() {
        if (!this.payload?.unitCost) {
            return null;
        }
        return this.$currencyDisplay(this.payload.unitCost);
    }
    get unitCostOld() {
        return this.changeLog?.changeDetails.changedFrom.unitCost
            ? this.changeLog?.changeDetails.changedFrom.unitCost
            : DEFAULT_DASH;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemInstanceInventoryCostChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemInstanceInventoryCostChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemInstanceInventoryCostChange);
export default ChangeSetPayloadDisplayItemInstanceInventoryCostChange;
