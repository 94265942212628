var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonGeneric', {
    class: _vm.computedClass,
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.reportIcon,
      "to": _vm.href,
      "color": "var(--v-primary-base)"
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('span', {
    staticClass: "pl-2"
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };