var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: {
      hasNoActions: !_vm.hasAnyActions,
      'no-entity-icon': !_vm.showEntityTypeIcon
    }
  }, [_vm.showEntityTypeIcon ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.ChangeSetType.ITEM_INSTANCE_ON_ORBIT_FAILURE],
      "icon-color": "white"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_vm._v("On-Orbit Failure")])]), _c('div', {
    staticClass: "vertical-display-items hidden-sm-and-down"
  }, _vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class
    }, [_c('div', {
      staticClass: "text-capitalize mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  }), 0), _vm.hasAnyActions ? _c('div', {
    staticClass: "mini_actions"
  }, [_vm.shouldShowManageLink ? _c('router-link', {
    staticClass: "delinkify",
    attrs: {
      "to": _vm.manageLink
    }
  }, [_c('c-icon-fa', {
    staticClass: "manage_icon",
    attrs: {
      "icon": _vm.manageIcon,
      "icon-color": "white"
    }
  })], 1) : _vm._e(), _vm.inlineClear && !_vm.readOnly ? _c('c-icon-fa', {
    staticClass: "inline_clear_icon",
    attrs: {
      "tooltip": "Clear selection",
      "icon": _vm.$icons.clear
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.failureItemInstanceNodeId ? _c('div', {
    staticClass: "hardware_display my-2 pt-2"
  }, [_c('AppHardwareDisplay', {
    attrs: {
      "title": "Failure logged against this Hardware",
      "node-id": _vm.failureItemInstanceNodeId
    }
  })], 1) : _vm._e(), _vm.failureMode ? _c('div', {
    staticClass: "failure_mode"
  }, [_c('AppHeader', {
    staticClass: "mb-2"
  }, [_vm._v("Failure Mode")]), _c('p', [_vm._v(_vm._s(_vm.failureMode))])], 1) : _vm._e(), _c('div', {
    staticClass: "description"
  }, [_c('AppHeader', {
    staticClass: "mb-2"
  }, [_vm._v("Description")]), _c('p', [_vm._v(_vm._s(_vm.failureDescription))])], 1)], 1) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };