var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { AuthorityCodeDisplay } from '~/nasa_ui/types/enums/authority-codes';
let ChangeSetDisplayItemMasterInitialization = class ChangeSetDisplayItemMasterInitialization extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get authorityCode() {
        return this.payload && this.payload.initializationCode
            ? AuthorityCodeDisplay.get(this.payload.initializationCode)
            : null;
    }
    get authorityNumber() {
        return this.payload && this.payload.initializationAuthorityNumber
            ? this.payload.initializationAuthorityNumber
            : null;
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : null;
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query SelectChangeSetForItemMasterEquipmentInitialization($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              payload
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemMasterInitialization.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemMasterInitialization.prototype, "syncId", null);
ChangeSetDisplayItemMasterInitialization = __decorate([
    Component
], ChangeSetDisplayItemMasterInitialization);
export default ChangeSetDisplayItemMasterInitialization;
