import { RiskHardwareType, RiskReportability } from '~/db_types/swagger_types';
export const RiskHardwareTypeDisplay = new Map([
    [RiskHardwareType.EMU, 'EMU'],
    [RiskHardwareType.TOOLS, 'Tools']
]);
export const RiskReportabilityDisplay = new Map([
    [RiskReportability.ALWAYS, 'Always'],
    [RiskReportability.DEFAULT, 'Default'],
    [RiskReportability.NEVER, 'Never']
]);
