var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { EntityType } from '~/nasa_ui/types';
import AppInventoryCrossContextDisplay from './AppInventoryCrossContextDisplay/AppInventoryCrossContextDisplay.vue';
import AppInventoryDisplay from './AppInventoryDisplay/AppInventoryDisplay.vue';
import AppItemDrawingDisplay from './AppItemDrawingDisplay/AppItemDrawingDisplay.vue';
import AppItemInstanceDisplay from './AppItemInstanceDisplay/AppItemInstanceDisplay.vue';
import AppItemMasterDisplay from './AppItemMasterDisplay/AppItemMasterDisplay.vue';
let AppHardwareDisplay = class AppHardwareDisplay extends Vue {
    EntityType = EntityType;
    inventoryNodeId = '';
    inventoryQuantity = null;
    isSerialControlled = true;
    /* -- start IBaseHardwareDisplayProps -- */
    crossContextInventoryId;
    fetchPolicy;
    hideBorderBottom;
    hideButtons;
    nodeId;
    title;
    /* -- end IBaseHardwareDisplayProps -- */
    get computedTitle() {
        return this.title;
    }
    get hardwareType() {
        if (!this.nodeId) {
            return null;
        }
        const data = JSON.parse(atob(this.nodeId));
        return data[0];
    }
    get hasComputedTitleOrSlot() {
        return this.hasTitleSlot || Boolean(this.computedTitle);
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    get isCrossContextInventory() {
        return this.crossContextInventoryId;
    }
    get isInventory() {
        return this.hardwareType === EntityType.INVENTORY;
    }
    get isItemDrawing() {
        return this.hardwareType === EntityType.ITEM_DRAWING;
    }
    get isItemInstance() {
        return this.hardwareType === EntityType.ITEM_INSTANCE;
    }
    get isItemMaster() {
        return this.hardwareType === EntityType.ITEM_MASTER;
    }
    hardwareDataChange(val) {
        return val;
    }
    async onItemInstanceValueChange(val) {
        if (val) {
            try {
                const resp = await this.$apollo.query({
                    query: gql `
            query GetInventoriesForItemInstancesForAhd($itemInstanceNodeId: ID!) {
              itemInstance(nodeId: $itemInstanceNodeId) {
                nodeId
                id
                inventoryQuantity
                serialNumber

                inventories(filter: { quantity: { greaterThan: 0 } }) {
                  nodes {
                    nodeId
                  }
                }
              }
            }
          `,
                    variables: {
                        itemInstanceNodeId: this.nodeId
                    }
                });
                if (resp.data.itemInstance.serialNumber) {
                    this.isSerialControlled = true;
                }
                this.inventoryQuantity = resp.data.itemInstance.inventoryQuantity;
            }
            catch (err) {
                if (err instanceof Error) {
                    this.$errorUtility({
                        err
                    });
                }
                else {
                    console.log(`Unexpected error: ${err}`);
                }
            }
        }
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppHardwareDisplay.prototype, "crossContextInventoryId", void 0);
__decorate([
    Prop({
        type: String,
        default: 'network-only'
    }),
    __metadata("design:type", typeof (_b = typeof FetchPolicy !== "undefined" && FetchPolicy) === "function" ? _b : Object)
], AppHardwareDisplay.prototype, "fetchPolicy", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "hideBorderBottom", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "hideButtons", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "nodeId", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "title", void 0);
__decorate([
    Emit('hardwareDataChange'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], AppHardwareDisplay.prototype, "hardwareDataChange", null);
__decorate([
    Watch('isItemInstance', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", Promise)
], AppHardwareDisplay.prototype, "onItemInstanceValueChange", null);
AppHardwareDisplay = __decorate([
    Component({
        components: {
            AppItemDrawingDisplay,
            AppItemMasterDisplay,
            AppItemInstanceDisplay,
            AppInventoryDisplay,
            AppInventoryCrossContextDisplay
        }
    })
], AppHardwareDisplay);
export default AppHardwareDisplay;
