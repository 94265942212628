import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicItemMasterSlimFragment } from '../itemMaster/slim';
import { CosmicLocationSlimFragment } from '../location/slim';
import { CosmicOrganizationSlimFragment } from './slim';
export const CosmicResponsibleOrganizationFragment = gql `
  ${CosmicItemMasterSlimFragment}

  fragment CosmicResponsibleOrganization on ResponsibleOrganization {
    itemMaster {
      ...CosmicItemMasterSlim
    }
  }
`;
export const CosmicOrganizationFragment = gql `
  ${CosmicOrganizationSlimFragment}

  fragment CosmicOrganization on Organization {
    ...CosmicOrganizationSlim
  }
`;
export const CosmicOrganizationFullFragment = gql `
  ${CosmicLocationSlimFragment}
  ${CosmicOrganizationSlimFragment}
  ${CosmicResponsibleOrganizationFragment}

  fragment CosmicOrganizationFull on Organization {
    ...CosmicOrganizationSlim

    locations {
      nodes {
        ...CosmicLocationSlim
      }
    }

    responsibleOrganizations {
      nodes {
        ...CosmicResponsibleOrganization
      }
    }
  }
`;
export const GetAllOrganizationCodes = gql `
  ${CosmicOrganizationSlimFragment}

  query GetAllOrganizationCodes {
    organizations {
      nodes {
        ...CosmicOrganizationSlim
      }
    }
  }
`;
export const GetOrganizationSlimByNodeId = gql `
  ${CosmicOrganizationSlimFragment}

  query GetOrganizationSlimByNodeId($nodeId: ID!) {
    organization(nodeId: $nodeId) {
      ...CosmicOrganizationSlim
    }
  }
`;
export const GetOrganizationsByCode = gql `
  ${CosmicOrganizationSlimFragment}

  query GetOrganizationsByCode($codes: [String!]) {
    organizations(filter: { code: { in: $codes } }) {
      nodes {
        ...CosmicOrganizationSlim
      }
    }
  }
`;
export const GetOrganizationByNodeId = gql `
  ${CosmicOrganizationFullFragment}

  query GetOrganizationByNodeId($nodeId: ID!) {
    organization(nodeId: $nodeId) {
      ...CosmicOrganizationFull
    }
  }
`;
export const GetAllUserSupportContextOrgs = gql `
  ${CosmicOrganizationSlimFragment}

  query GetAllUserSupportContextOrgs($userSupportOrgCodes: [String!]) {
    organizations(filter: { code: { in: $userSupportOrgCodes } }) {
      nodes {
        ...CosmicOrganizationSlim
      }
    }
  }
`;
export const CreateOrganization = gql `
  ${CosmicOrganizationSlimFragment}

  mutation CreateOrganization($organization: OrganizationInput!) {
    createOrganization(input: { organization: $organization }) {
      organization {
        ...CosmicOrganizationSlim
      }
    }
  }
`;
let OrganizationRepoMixin = class OrganizationRepoMixin extends Mixins(LoaderMixin) {
    // QUERIES
    getOrganizationByNodeId = queryFactory(this, GetOrganizationByNodeId);
    getOrganizationSlimByNodeId = queryFactory(this, GetOrganizationSlimByNodeId);
    getOrganizationsByCode = queryFactory(this, GetOrganizationsByCode);
    getAllOrganizationCodes = queryFactory(this, GetAllOrganizationCodes);
    getAllUserSupportContextOrgs = queryFactory(this, GetAllUserSupportContextOrgs);
    // MUTATIONS
    createOrganization = mutationFactory(this, CreateOrganization);
};
OrganizationRepoMixin = __decorate([
    Component
], OrganizationRepoMixin);
export { OrganizationRepoMixin };
