var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.to ? _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.$icons.create_fat,
      "to": _vm.to,
      "tooltip": _vm.computedTooltip,
      "tooltip-position": _vm.tooltipPosition,
      "icon-color": "var(--v-success-base)",
      "icon-size": "22"
    }
  }) : _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.$icons.create_fat,
      "tooltip": _vm.computedTooltip,
      "tooltip-position": _vm.tooltipPosition,
      "icon-color": "var(--v-success-base)",
      "icon-size": "22"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };