import { getEnvironment } from '~/nasa_ui/types/cosmic/environments';
export const AUTH_PATH = '/api/oauth/authorize';
export const cDrBuyoffGroupCode = 'cDR_BUYOFF';
// This is the date COSMIC "started"; HTS was "turned off" on this day
export const COSMIC_START_DATE = '2021-01-15';
export const cTpsBuyoffGroupCode = 'cTPS_BUYOFF';
export const CURRENT_SUPPORT_CONTEXT_LS_VARIABLE = 'currentSupportContext';
export const CURRENT_USER_LS_VARIABLE = 'currentUser';
export const CURRENT_USER_NEEDS_NEW_SESSION_LS_VARIABLE = 'needsNewSession';
export const CURRENT_USER_PERMISSION_LS_VARIABLE = 'up';
export const DB_DEFAULT = 'DEFAULT';
export const DEFAULT_DASH = '-';
export const GENERAL = 'GENERAL';
export const LOC_DB_DEFAULT = GENERAL;
export const LOGOUT_PATH = '/api/oauth/logout';
export var AS_BUILT_DEFAULT_OPTIONS;
(function (AS_BUILT_DEFAULT_OPTIONS) {
    AS_BUILT_DEFAULT_OPTIONS["DB_DEFAULT"] = "DEFAULT";
    AS_BUILT_DEFAULT_OPTIONS["DEFAULT_DASH"] = "-";
    AS_BUILT_DEFAULT_OPTIONS["VIEW_ITEM_MASTER"] = "View Item Master";
})(AS_BUILT_DEFAULT_OPTIONS || (AS_BUILT_DEFAULT_OPTIONS = {}));
export const STATIC_RESOURCE_URL = {
    local: '/src/static_resources/resources/assets',
    development: 'https://public.dev.cosmic.appdat.jsc.nasa.gov/assets',
    staging: 'https://public.staging.cosmic.appdat.jsc.nasa.gov/assets',
    production: 'https://public.cosmic.appdat.jsc.nasa.gov/assets'
};
export const getStaticResourcePath = () => STATIC_RESOURCE_URL[getEnvironment()];
export const ERROR_MESSAGE_MAPPER = (error, backupErrorText) => {
    /*
     * This error message mapper checks for a GraphQL error string
     * and returns a human readable message appropriate to display
     * to the end user.
     */
    let errorMessage = 'An error has occurred on the page, please try again.';
    if (!error) {
        return errorMessage;
    }
    if (error.includes('template requires authority membership')) {
        const groupCodeSplit = error.split('group_code=');
        const groupCode = groupCodeSplit[1].replace(/[^a-zA-Z_ ]/g, '');
        return (errorMessage = `Error: You are not a part of ${groupCode}, cannot perform action.`);
    }
    if (error.includes('inventory_has_open_change_set')) {
        return (errorMessage = `Error: Inventory has an open Change Set and cannot move.`);
    }
    if (error.includes('item_master_initialization_needs_approval')) {
        return (errorMessage = `Error: Item Master requires Initialization before receiving into inventory.`);
    }
    if (error.includes('needs_inspection')) {
        return (errorMessage = 'Error: Inspection is required.');
    }
    if (error.includes('calibration_requires_type')) {
        return (errorMessage = 'Error: Calibration Type is required.');
    }
    if (error.includes('item master does not exist')) {
        return (errorMessage = `Error: No valid Item Master found matching this AsBuilt.`);
    }
    if (error.includes('bad_update_change_set_hardware')) {
        return (errorMessage = `Existing change sets prevent hardware from being updated at this time.`);
    }
    if (error.includes('locations_uniq_bin_area')) {
        return (errorMessage = `Location already exists. Must be unique by Contract, Bin, Stock, Room, Building.`);
    }
    if (error.includes('update or delete on table "documents" violates foreign key constraint "comments_document_id_fkey"')) {
        return (errorMessage = 'Document cannot be deleted because it has associated comments.');
    }
    if (error.includes('update or delete on table "documents" violates foreign key constraint "external_systems_document_id_fkey"')) {
        return (errorMessage = 'Document cannot be deleted because it has associated links to external systems.');
    }
    if (error.includes('update or delete on table "locations" violates foreign key constraint "inventory_location_id_fkey"')) {
        return (errorMessage = 'Location cannot be deleted because it has, or previously had, inventory.');
    }
    if (error.includes('update or delete on table "locations" violates foreign key constraint "events_location_id_fkey"')) {
        return (errorMessage = 'Location cannot be deleted because it is associated with an event.');
    }
    if (error.includes('error: inventory_installed')) {
        return (errorMessage = 'Unable to make changes, inventory is currently installed.');
    }
    if (error.includes('duplicate key value violates unique constraint "change_set_templates_sub_type_support_organization_code_gro_idx"')) {
        return (errorMessage = 'Cannot modify a Change Set Template with the same Equipment group.');
    }
    if (error.includes('update or delete on table "activities" violates foreign key constraint "comments_activity_id_fkey"')) {
        return (errorMessage = 'Activity cannot be deleted because it has associated comments.');
    }
    if (error.includes('duplicate key value violates unique constraint')) {
        return (errorMessage = `Error: Item already created, please refresh your browser.`);
    }
    /*
     * These will eventually be handled by a backend `CASCADE_DELETE` but adding this here for posterity until then
     */
    if (error.includes('update or delete on table "hardware_lists" violates foreign key constraint "hardware_list_assemblies_hardware_list_id_fkey" on table "hardware_list_assemblies"')) {
        return (errorMessage = `Hardware List cannot be deleted because it has associated parts.`);
    }
    if (error.includes('update or delete on table "hardware_lists" violates foreign key constraint "hardware_list_templates_hardware_list_id_fkey" on table "hardware_list_templates"')) {
        return (errorMessage = `Hardware List cannot be deleted because it has associated templates.`);
    }
    if (error.includes('update or delete on table "hardware_lists" violates foreign key constraint "comments_hardware_list_id_fkey"')) {
        return (errorMessage = `Hardware List cannot be deleted because it has associated comments.`);
    }
    if (error.includes('There is no inventory in the building.')) {
        return `There is no inventory in the building. Audit not created.`;
    }
    if (error.includes('An open inventory audit already exists for the building.')) {
        return `An open Inventory audit already exists for the building.`;
    }
    if (error.includes('lotted_cost_mismatch')) {
        return 'Unable to add hardware to lot: Lotted Cost Mismatch.';
    }
    if (error.includes('lotted_class_mismatch')) {
        return 'Unable to add hardware to lot: Lotted Class Mismatch.';
    }
    if (error.includes('There is no managed inventory in the building')) {
        return 'There is no managed inventory in the building.';
    }
    if (error.includes('install_on_self')) {
        return 'Attempting to install hardware already installed above.';
    }
    if (error.includes('contract_number_mismatch')) {
        return 'The Contract number of the source Location must match that of the destination Location.';
    }
    if (error.includes('Document must be reopened for edits first.')) {
        return `Document must be reopened for edits first.`;
    }
    if (error.includes('Conflicts with another open change set')) {
        return `This hardware has another open Change Set of this type.`;
    }
    if (error.includes(`Child inventory must be transferred to parent's location first`)) {
        return `The selected inventory must be transferred to parent's location before installing.`;
    }
    errorMessage = 'An error has occurred on the page, please try again.';
    return backupErrorText || errorMessage;
};
/**
 * Returns meta information that's useful for displaying a Limited Life form.
 *
 * I don't know if this is necessarily the best place for this, but I needed
 * access to it across modules. -BW
 */
export function limitedLifeFieldsMeta(isUsingOperationLifeOverrideValue, isUsingShelfLifeOverrideValue, isUsingUsageLifeOverrideValue) {
    return [
        {
            name: 'shelf',
            display: 'Shelf Life',
            textDescription: {
                default: `Calculated from: ${isUsingShelfLifeOverrideValue ? 'Expiration' : 'Birth'}`,
                serviceDefault: 'Calculated from: Birth'
            },
            hideServiceDefault: true
        },
        {
            name: 'usage',
            display: 'Usage Life',
            textDescription: {
                default: `Calculated from: ${isUsingUsageLifeOverrideValue ? 'Expiration' : 'Birth'}`,
                serviceDefault: 'Calculated from: Birth'
            }
        },
        {
            name: 'operation',
            display: 'Operational Life',
            textDescription: {
                default: `Calculated from: ${isUsingOperationLifeOverrideValue ? 'Expiration' : 'Operation Start'}`,
                serviceDefault: 'Calculated from: Operation Start'
            }
        },
        {
            name: 'pressurizedTime',
            display: 'Pressurized Time Life',
            textDescription: {
                default: 'Calculated from: Activities containing Pressurized Time',
                serviceDefault: 'Calculated from: Activities containing Pressurized Time'
            }
        },
        {
            name: 'interval',
            display: 'Interval Life'
        },
        {
            name: 'onOrbit',
            display: 'On Orbit Life'
        },
        {
            name: 'static',
            display: 'Static Life'
        }
    ];
}
