function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.isTypeFormWrapper ? _c('AppFormWrapper', _vm._b({}, 'AppFormWrapper', _objectSpread(_objectSpread({}, _vm.$attrs), _vm.$props), false), [_vm._t("default")], 2) : _vm._e(), _vm.isTypeExpansionPanel ? _c('AppExpansionPanel', _vm._b({
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (i, name) {
      return {
        key: name,
        fn: function fn() {
          return [_vm._t(name)];
        },
        proxy: true
      };
    })], null, true)
  }, 'AppExpansionPanel', _objectSpread(_objectSpread({}, _vm.$attrs), _vm.$props), false), [_vm._t("default")], 2) : _vm._e(), _vm.isTypeNone ? [_vm._t("default")] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };