var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity && !_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Hardware being installed"
    }
  }, [_c('AppFormWrapper', {
    attrs: {
      "color": "green"
    }
  }, [_vm.toBeInstallHardwareNodeId ? _c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.toBeInstallHardwareNodeId
    }
  }) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "no-wrap": ""
    }
  }, [_c('ul', {
    staticClass: "labels text-xs-right pr-2"
  }, [_c('li', [_vm._v("Sequence:")]), _c('li', [_vm._v("Qty:")]), _c('li', [_vm._v("Installed On:")])]), _c('ul', {
    staticClass: "values"
  }, [_c('li', [_c('span', {
    staticClass: "green--text"
  }, [_vm._v(_vm._s(_vm.changesForDisplay.sequence))])]), _c('li', [_c('span', {
    staticClass: "green--text"
  }, [_vm._v(_vm._s(_vm.changesForDisplay.quantity))])]), _c('li', [_c('span', {
    staticClass: "green--text"
  }, [_vm._v(_vm._s(_vm.changesForDisplay.installedOn))])])])])], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };