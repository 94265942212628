var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.hideIfNone ? _c('div', [!_vm.isReadOnly ? _c('ButtonCreate', {
    staticClass: "mb-2",
    on: {
      "click": function click($event) {
        _vm.shouldShowForm = true;
      }
    }
  }, [_vm._v("Create link")]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.isReadOnly ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTableActions', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.linksColor,
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons.link,
      "items": _vm.computedTableItems,
      "hide-pagination": "",
      "title": "Links"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ButtonMiniActionEdit', {
          on: {
            "click": function click($event) {
              return _vm.onClickOfTableRow(item);
            }
          }
        }), _vm.userCanRemoveLink ? _c('ButtonMiniActionDelete', {
          on: {
            "click": function click($event) {
              return _vm.onClickOfRemoveLinksButton(item);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 3752463421)
  })], 1) : _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.linksColor,
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons.link,
      "items": _vm.computedTableItems,
      "hide-pagination": "",
      "title": "Links"
    }
  })], 1)], 1), _vm.shouldShowForm && _vm.selectedLinkId ? _c('ModalLinkEdit', {
    attrs: {
      "document-id": _vm.documentId,
      "event-id": _vm.eventId,
      "id": _vm.selectedLinkId,
      "item-drawing": _vm.itemDrawing,
      "item-instance": _vm.itemInstance,
      "item-master": _vm.itemMaster,
      "risk-id": _vm.riskId
    },
    on: {
      "externalLinkCreated": _vm.populateTable,
      "modal-close": _vm.onModalClose,
      "reloadExternalLinks": _vm.populateTable
    }
  }) : _vm._e(), _vm.shouldShowForm && !_vm.selectedLinkId ? _c('ModalLinkCreate', {
    attrs: {
      "document-id": _vm.documentId,
      "event-id": _vm.eventId,
      "item-drawing": _vm.itemDrawing,
      "item-instance": _vm.itemInstance,
      "item-master": _vm.itemMaster,
      "risk-id": _vm.riskId
    },
    on: {
      "externalLinkCreated": _vm.populateTable,
      "modal-close": _vm.onModalClose,
      "reloadExternalLinks": _vm.populateTable
    }
  }) : _vm._e(), _vm.showAreYouSure ? _c('AreYouSureDialog', {
    on: {
      "affirmativeClicked": _vm.onAffirmativeClicked,
      "negativeClicked": _vm.onNegativeClicked
    }
  }) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };