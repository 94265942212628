var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "hide-title-section": "",
      "color": _vm.EntityType.CHANGE_SET
    },
    on: {
      "modal-close": _vm.closeDialog,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDialog.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.userHasPermission && !_vm.openInitializationChangeSet ? [_c('AppHardwareDisplay', {
          staticClass: "mb-3",
          attrs: {
            "node-id": _vm.itemMasterNodeId,
            "title": "Item Master to initialize"
          }
        }), _c('Alert', {
          attrs: {
            "type": "info"
          }
        }, [_c('p', [_vm._v("Enter the type and number of the Authority on which you open this Initialization Approval process.")])]), _c('AppAuthority', {
          attrs: {
            "required": "",
            "hide-header": "",
            "size": "0"
          },
          model: {
            value: _vm.formData,
            callback: function callback($$v) {
              _vm.formData = $$v;
            },
            expression: "formData"
          }
        }), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mt-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs8": ""
          }
        }, [!_vm.isAutoApprove ? _c('AppTable', {
          attrs: {
            "headers": _vm.approvalRoutingTableHeaders,
            "items": _vm.approvalRoutingTableItems,
            "color": _vm.EntityType.USER_GROUP
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_vm._v("Approval Routing")];
            },
            proxy: true
          }], null, false, 1908575078)
        }) : _vm._e()], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_vm.isAutoApprove ? _c('Alert', {
          attrs: {
            "type": "info"
          }
        }, [_c('p', [_vm._v(" The "), _c('strong', [_vm._v(_vm._s(_vm.currentUserActiveSupportContext))]), _vm._v(" context does not have Approval Routing set up. ")]), _c('p', [_vm._v("This change will be fast-forwarded (auto-approved).")])]) : _vm._e()], 1)], 1)] : _vm._e(), !_vm.userHasPermission ? [_c('Alert', {
          attrs: {
            "type": "error"
          }
        }, [_c('p', [_vm._v("You do not have permission "), _c('code', [_vm._v("ITEM_MASTER_ADMIN")])]), _c('p', [_vm._v("Contact a User Admin to be granted this permission.")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" Find a list of "), _c('a', {
          attrs: {
            "href": "/reporting/user_admin_listing",
            "target": "_blank"
          }
        }, [_vm._v("User Admins here")]), _vm._v(". ")])])] : _vm._e(), _vm.userHasPermission && _vm.openInitializationChangeSet ? [_c('Alert', {
          attrs: {
            "type": "warning"
          }
        }, [_c('p', [_vm._v("There exists an "), _c('strong', [_vm._v("OPEN")]), _vm._v(" Change Set for this Item's Initialization.")]), _c('p', [_vm._v(" Please Approve or Reject from the "), _vm.openInitializationChangeSetHref ? _c('a', {
          attrs: {
            "href": _vm.openInitializationChangeSetHref
          }
        }, [_vm._v("Change Set Manage page")]) : _c('span', [_vm._v("Change Set Manage page")]), _vm._v(". Brief details of the Change Set are below ")])]), _c('ReadOnlyChangeSet', {
          attrs: {
            "id": _vm.openInitializationChangeSet.id
          }
        })] : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.userHasPermission ? _c('ButtonChangeSet', {
          staticClass: "ml-0",
          attrs: {
            "disabled": _vm.shouldDisableSubmit
          },
          on: {
            "click": _vm.onSubmitOfInitialization
          }
        }, [_vm._v(" Submit Item Master Initialization ")]) : _vm._e(), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };