var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [!_vm.hasParent ? _c('Alert', {
          attrs: {
            "type": "error"
          }
        }, [_vm._v("No parent found. Please close this and try again.")]) : _vm._e(), _vm.hasParent ? _c('div', [_vm.isParentHardwareListTemplate ? _c('MiniHardwareListTemplate', {
          staticClass: "parent_mini",
          attrs: {
            "id": _vm.parentId,
            "show-manage-link": false,
            "show-slots-initially": false,
            "show-entity-type-icon": false,
            "title": "Adding to list..."
          }
        }) : _vm._e(), _vm.isParentHardwareListTemplate ? _c('hr', {
          staticClass: "my-3"
        }) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Item name",
            "c-value": _vm.formData.name,
            "clearable": ""
          },
          on: {
            "c-input": function cInput($event) {
              _vm.formData.name = $event.detail;
            }
          }
        })], 1)], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": "",
            "mr-3": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "error-messages": _vm.sequenceNumberErrorMessage,
            "messages": _vm.sequenceNumberMessage,
            "autofocus": "",
            "label": "Sequence",
            "required": ""
          },
          model: {
            value: _vm.formData.sequence,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs2": "",
            "mr-1": "",
            "offset-xs1": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Min qty",
            "max": _vm.formData.maxQuantity || 1,
            "pre-computed-rules": [_vm.minLessThanOrEqualToMax]
          },
          model: {
            value: _vm.formData.minQuantity,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "minQuantity", $$v);
            },
            expression: "formData.minQuantity"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Max qty",
            "max": _vm.formData.maxQuantity || 0,
            "pre-computed-rules": [_vm.minLessThanOrEqualToMax],
            "required": ""
          },
          model: {
            value: _vm.formData.maxQuantity,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "maxQuantity", $$v);
            },
            expression: "formData.maxQuantity"
          }
        })], 1)], 1), _c('AppFormWrapper', {
          attrs: {
            "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
            "size": "1"
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppTableActions', {
          attrs: {
            "headers": _vm.hardwareItemTemplatePartTableHeaders,
            "hide-pagination": _vm.hardwareItemTemplatePartTableItems.length < 5,
            "items": _vm.hardwareItemTemplatePartTableItems,
            "rows-per-page-items": _vm.rowsPerPageItems,
            "color": _vm.EntityType.ITEM_MASTER,
            "title": "Hardware shopping list",
            "show-count": ""
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('ButtonMiniActionRemoveFromList', {
                on: {
                  "click": function click($event) {
                    return _vm.onClickOfHardwareItemTemplatePartTableItem(item);
                  }
                }
              })];
            }
          }], null, false, 315635474)
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mt-3": ""
          }
        }, [_c('SearchItemDrawingItemMaster', {
          attrs: {
            "existing-values": _vm.existingItemDrawingNodeIds,
            "rows-per-page-items": _vm.rowsPerPageItems,
            "keep-search-results-open": "",
            "keep-search-value-after-selection": "",
            "output-mode": "object"
          },
          on: {
            "input": _vm.onSearchItemDrawingChange
          }
        })], 1)], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateAssemblyTemplate
          }
        }, [_vm._v("Add Item to List")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };