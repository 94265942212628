var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity && !_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, _vm._l(_vm.payloadValues, function (val, key) {
    return _c('div', {
      key: key
    }, [_c('AppDisplayTextDiff', {
      attrs: {
        "label": _vm.getLabel(key),
        "newVal": val,
        "oldVal": _vm.currentValues[key]
      }
    })], 1);
  }), 0) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };