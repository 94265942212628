import { DEFAULT_DASH } from '~/nasa_ui/constants';
export function formatValuesForHref(tableRowValue, href) {
    if (tableRowValue === DEFAULT_DASH) {
        return tableRowValue;
    }
    return `<a href="${href}" target="_blank">${tableRowValue}</a>`;
}
// got a naming collision with helpers
// export * from '~/utils/AssemblyComparisonUtility';
export * from '~/utils/ChangeSetCommentUtility';
export * from '~/utils/RouterLogUtility';
