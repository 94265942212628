var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity && !_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppDisplayTextDiff', {
    attrs: {
      "label": "Authority code",
      "newVal": _vm.authorityCode,
      "oldVal": null
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppDisplayTextDiff', {
    attrs: {
      "label": "Authority number",
      "newVal": _vm.authorityNumber,
      "oldVal": null
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };