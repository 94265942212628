var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity && !_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Sizes"
    }
  }, [_c('AppChip', {
    attrs: {
      "chips": _vm.finalValue
    }
  })], 1)], 1), _vm.additions && _vm.additions.length > 0 ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('span', [_vm._v("Additions")]), _c('AppChip', {
    attrs: {
      "chips": _vm.additions
    }
  })], 1) : _vm._e(), _vm.removals && _vm.removals.length > 0 ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Removals")]), _c('AppChip', {
    attrs: {
      "chips": _vm.removals
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };