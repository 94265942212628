import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Watch } from 'vue-property-decorator';
import { ItemInstanceInventoryType, Side } from '~/db_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DB_DEFAULT, DEFAULT_DASH } from '~/nasa_ui/constants';
import { GetItemInstanceById, GetItemInstanceByNodeId } from '~/nasa_ui/DAL/itemInstance';
import Hardware from '~/nasa_ui/modules/Hardware';
import { EntityType } from '~/nasa_ui/types';
let MiniItemInstance = class MiniItemInstance extends BaseMini {
    entityType = EntityType.ITEM_INSTANCE;
    showAhd = false;
    localEntity = null;
    hardware = null;
    BASE_FETCH = gql `
    ${this.nodeId ? GetItemInstanceByNodeId : GetItemInstanceById}
  `;
    get isArchived() {
        return this.localEntity?.isArchived;
    }
    get manageLink() {
        return this.localEntity ? `/hardware/${EntityType.ITEM_INSTANCE}/manage/${this.localEntity.nodeId}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity ? this.$itemNumberDisplay(this.localEntity) : DEFAULT_DASH;
    }
    get shouldDisplayComputedClassLabel() {
        return this.localEntity?.subType === ItemInstanceInventoryType.MULTIPLE;
    }
    get shouldShowSubText() {
        return Boolean(this.localEntity?.itemDrawingDescription);
    }
    get subNameText() {
        return this.hardware ? this.hardware.descriptionDisplay : DEFAULT_DASH;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity || !this.hardware) {
            return [];
        }
        const items = [];
        if (this.localEntity?.asDesignNumber) {
            items.push({
                name: 'AsDesign',
                value: `${this.localEntity?.asDesignNumber || DEFAULT_DASH}`
            });
        }
        if (this.localEntity?.serialNumber) {
            items.push({
                name: 'Serial',
                value: this.$serialNumberDisplay(this.localEntity?.serialNumber)
            });
        }
        if (this.localEntity?.lotNumber) {
            items.push({
                name: 'Lot',
                value: this.$lotNumberDisplay(this.localEntity?.lotNumber)
            });
        }
        if (this.localEntity?.side && this.localEntity?.side !== Side.NONE) {
            items.push({
                name: 'Side',
                value: this.$sideDisplay(this.localEntity?.side)
            });
        }
        if (this.localEntity?.size && this.localEntity?.size !== DB_DEFAULT) {
            items.push({
                name: 'Size',
                value: this.$sizeDisplay(this.localEntity?.size)
            });
        }
        return items;
    }
    onLocalEntityChange() {
        if (this.entity) {
            this.hardware = new Hardware(this.entity);
        }
    }
    onFetchUpdate(data) {
        // TODO: type this as `data: GetItemInstanceByNodeIdQuery | GetItemInstanceByIdQuery`
        if (data.itemInstance || data.itemInstanceById) {
            this.$set(this, 'localEntity', data.itemInstance || data.itemInstanceById);
            this.hardware = new Hardware(this.localEntity);
        }
    }
};
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniItemInstance.prototype, "onLocalEntityChange", null);
MiniItemInstance = __decorate([
    Component
], MiniItemInstance);
export default MiniItemInstance;
