var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
let ChangeSetDisplayItemInstanceConfigurationChange = class ChangeSetDisplayItemInstanceConfigurationChange extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get itemInstance() {
        return this.selectedEntity && this.selectedEntity.itemInstance ? this.selectedEntity.itemInstance : {};
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : {};
    }
    changesInclude(property) {
        return Object.keys(this.payload).includes(property);
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query SelectChangeSetForItemInstanceConfigurationChange($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              payload
              itemInstance {
                nodeId
                asBuiltNumber
                asDesignNumber
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    async syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemInstanceConfigurationChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ChangeSetDisplayItemInstanceConfigurationChange.prototype, "syncId", null);
ChangeSetDisplayItemInstanceConfigurationChange = __decorate([
    Component
], ChangeSetDisplayItemInstanceConfigurationChange);
export default ChangeSetDisplayItemInstanceConfigurationChange;
