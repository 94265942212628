var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isFetching ? _c('v-layout', [_vm.hasSequence ? _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "html": _vm.sequence,
      "title": "Sequence"
    }
  })], 1) : _vm._e(), _vm.hasQuantity ? _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "html": _vm.quantity,
      "title": "Qty"
    }
  })], 1) : _vm._e(), _vm.hasInstalledOn ? _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "html": _vm.installedOn,
      "title": "Installed On"
    }
  })], 1) : _vm._e(), _vm.hasParentQuantity ? _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "html": _vm.parentQuantity,
      "title": "Parent Qty"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isFetching && _vm.hasProjectCode ? _c('AppDisplayTextDiff', {
    staticClass: "no-margin-left",
    attrs: {
      "label": "Project Code",
      "newVal": _vm.projectCode,
      "oldVal": _vm.projectCodeOld
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };