var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isFetching ? _c('v-layout', {
    attrs: {
      "row": ""
    }
  }, [_vm.hasInventoryControl ? _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.isFetching ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Inventory Control",
      "newVal": _vm.inventoryControl,
      "oldVal": _vm.inventoryControlOld
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.hasInventoryMethod ? _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.isFetching ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Inventory Method",
      "newVal": _vm.inventoryMethod,
      "oldVal": _vm.inventoryMethodOld
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.hasInventoryUnit ? _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.isFetching ? _c('AppDisplayTextDiff', {
    attrs: {
      "label": "Inventory Unit",
      "newVal": _vm.inventoryUnit,
      "oldVal": _vm.inventoryUnitOld
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };