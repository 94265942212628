var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.availableQuantity && _vm.availableQuantityLink ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": "".concat(_vm.availableQuantity, " ").concat(_vm.inventoryMethod ? _vm.inventoryMethod : ''),
      "vhtml": _vm.availableQuantityVhtml,
      "label": "Avail Qty:",
      "tooltipLabel": "All quantities of Inventories that are not installed",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.availableQuantity && !_vm.availableQuantityLink ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": "".concat(_vm.availableQuantity, " ").concat(_vm.inventoryMethod ? _vm.inventoryMethod : ''),
      "text": _vm.availableQuantity,
      "label": "Avail Qty:",
      "tooltipLabel": "All quantities of Inventories that are not installed",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.installedQuantity && _vm.installedQuantityLink ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": "".concat(_vm.installedQuantity, " ").concat(_vm.inventoryMethod ? _vm.inventoryMethod : ''),
      "vhtml": _vm.installedQuantityVhtml,
      "label": "Inst Qty:",
      "tooltipLabel": "All quantities of Inventories that are installed",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.installedQuantity && !_vm.installedQuantityLink ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": "".concat(_vm.installedQuantity, " ").concat(_vm.inventoryMethod ? _vm.inventoryMethod : ''),
      "text": _vm.installedQuantity,
      "label": "Inst Qty:",
      "tooltipLabel": "All quantities of Inventories that are installed",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.inventoryQuantity && _vm.inventoryQuantityLink ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": "".concat(_vm.inventoryQuantity, " ").concat(_vm.inventoryMethod ? _vm.inventoryMethod : ''),
      "vhtml": _vm.inventoryQuantityVhtml,
      "label": "Qty:",
      "tooltipLabel": "All quantities",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.inventoryQuantity && !_vm.inventoryQuantityLink ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": "".concat(_vm.inventoryQuantity, " ").concat(_vm.inventoryMethod ? _vm.inventoryMethod : ''),
      "text": _vm.inventoryQuantity,
      "label": "Qty:",
      "tooltipLabel": "All quantities",
      "monospace-font": ""
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };