import { CriticalityCode } from '~/db_types';
export const CriticalityCodeDisplay = new Map([
    [CriticalityCode._1_1, '1/1: LOSS OF CREW OR VEHICLE/LOSS OF CREW OR VEHICLE'],
    [CriticalityCode._2_2, '2/2: LOSS OF MISSION/LOSS OF MISSION'],
    [CriticalityCode._3_3, '3/3: NO EFFECT/NO EFFECT'],
    [CriticalityCode._2_1R, '2/1R: LOSS OF MISSION/REDUNDANCY LOSS=LOSS OF CREW'],
    [CriticalityCode._3_1R, '3/1R: NO EFFECT/REDUNDANCY LOSS=LOSS OF CREW'],
    [CriticalityCode._3_2R, '3/2R: NO EFECT/REDUNDANCY LOSS=LOSS OF MISSION']
]);
export const CriticalityCodeNumberOnlyDisplay = new Map([
    [CriticalityCode._1_1, '1/1'],
    [CriticalityCode._2_2, '2/2'],
    [CriticalityCode._3_3, '3/3'],
    [CriticalityCode._2_1R, '2/1R'],
    [CriticalityCode._3_1R, '3/1R'],
    [CriticalityCode._3_2R, '3/2R']
]);
