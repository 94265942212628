var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { markdownToHtml } from '~/nasa_ui/utils/helpers/markdownToHtml';
let ChangeSetPayloadDisplayItemMasterInitialization = class ChangeSetPayloadDisplayItemMasterInitialization extends Vue {
    isFetching = false;
    changeSet;
    get authorityData() {
        return {
            authorityCode: this.initializationCode,
            comments: this.initializationComments,
            authorityNumber: this.initializationAuthorityNumber
        };
    }
    get hasInitializationCode() {
        return this.payload && 'initializationCode' in this.payload;
    }
    get hasInitializationAuthorityNumber() {
        return this.payload && 'initializationAuthorityNumber' in this.payload;
    }
    get hasInitializationComment() {
        return Boolean(this.initializationComments?.length);
    }
    get initializationCode() {
        // return (this.changeSet.authorityCode as AuthorityCode) || null;
        return this.payload?.initializationCode || null;
    }
    get initializationAuthorityNumber() {
        // return this.changeSet.authorityNumber || null;
        return this.payload?.initializationAuthorityNumber || null;
    }
    get initializationComments() {
        return this.changeSet?.comments?.nodes.map((comment) => markdownToHtml(comment?.comment || '')) || null;
    }
    get payload() {
        return this.changeSet?.payload || null;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemMasterInitialization.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemMasterInitialization = __decorate([
    Component
], ChangeSetPayloadDisplayItemMasterInitialization);
export default ChangeSetPayloadDisplayItemMasterInitialization;
