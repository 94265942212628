var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.HARDWARE_LIST,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder,
      "force-disable-search": _vm.disableSearch
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTableExpandable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST],
      "items": _vm.searchResults,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "disable-initial-sort": "",
      "item-key": "id",
      "sticky": ""
    },
    scopedSlots: _vm._u([_vm.selectedHardwareList ? {
      key: "expanded-row",
      fn: function fn(props) {
        return [_c('ButtonGeneric', {
          staticClass: "mb-2 ml-0",
          attrs: {
            "color": "primary",
            "icon": _vm.$icons.square_check
          },
          on: {
            "click": function click($event) {
              return _vm.onClickOfSearchResult(_vm.selectedHardwareList);
            }
          }
        }, [_vm._v(" Select this List ")]), _c('MiniHardwareList', {
          attrs: {
            "id": props.item.id,
            "show-children": "",
            "show-manage-link": false,
            "allow-edit": false
          }
        })];
      }
    } : null], null, true),
    model: {
      value: _vm.selectedHardwareList,
      callback: function callback($$v) {
        _vm.selectedHardwareList = $$v;
      },
      expression: "selectedHardwareList"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };